import React, { Component } from "react";
import AnimateHeight from "react-animate-height";

import settings from "../settings.js";

class Checkoutheader extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props.c.dates);
    this.state = {
      height: props.c.comment !== "" ? "auto" : 0,
      comment: props.c.comment,
      selectedDateTimestamp:
        this.props.c.dates.length > 0 &&
        this.props.c.dates.filter((dt) => {
          return dt.selected;
        }).length > 0
          ? this.props.c.dates.filter((dt) => {
              return dt.selected;
            })[0].value
          : null,
    };
  }
  toggle = () => {
    const { height } = this.state;

    this.setState({
      height: height === 0 ? "auto" : 0,
    });
  };

  changeDeliveryDateHandler = (e, clientID, index) => {
    const time = e.target.value;

    fetch(
      settings.server_url +
        "set_order_delivery_date/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        clientID +
        "/" +
        this.state.selectedDateTimestamp +
        "/" +
        time
    )
      .then((res) => res.text())
      .then((response) => {
        this.props.reload(true);
        //this.setState({ selectedDateTimestamp: time });
        //this.setState({ data: response, pageLoading: false });
        //this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  };

  getSelectedDate() {
    return this.props.c.dates.filter((dt) => {
      return dt.selected;
    });
  }

  commentHandler = async (e, clientID, index) => {
    this.props.loading(true);
    await fetch(
      settings.server_url +
        "set_order_comment/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        clientID +
        "/" +
        index,
      { method: "post", body: JSON.stringify({ comment: e.target.value }) }
    )
      .then((res) => res.text())
      .then((response) => {
        //this.setState({ data: response, pageLoading: false });
        console.log(response, "rep");
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  };

  commentChangeHandler = (e) => {
    e.preventDefault();
    this.setState({ comment: e.target.value });
  };

  getClientName(item) {
    if (parseInt(item.supplier_module_partner) === 1) {
      return "Partner " + item.supplier_company_name;
    } else {
      switch (parseInt(item.client_id_i)) {
        case 10:
          return "Van der Valk Koel & Vries";
        case 11:
          return "Van der Valk Centraal Magazijn";
        case 29:
          return "Van der Valk Apparaten";
        case 54:
          return "Van der Valk Dagvers";
        case 69:
          return "Van der Valk Versmarkt";
        case 61:
          return "Van der Valk Vis";
        default:
          return "Onbekend";
      }
    }
  }

  render() {
    let c = this.props.c;
    let ix2 = this.props.ix2;

    const selectedValue =
      this.getSelectedDate().length > 0
        ? this.getSelectedDate()[0].value
        : null;
    return (
      <React.Fragment>
        <div className="category_seperator clearfix">
          <select
            className="form-control custom-select mw250 text-center"
            value={selectedValue}
            onChange={(e) =>
              this.changeDeliveryDateHandler(
                e,
                c.items[0].client_id_i +
                  "_" +
                  (parseInt(c.items[0].supplier_module_partner) === 1
                    ? c.items[0].product_supplier_id
                    : "0"),
                ix2
              )
            }>
            {c.dates.map((dt, ix3) => {
              return (
                <option key={ix3} value={dt.value}>
                  {dt.name + (dt.isbulk ? " (BULK)" : "")}
                </option>
              );
            })}
          </select>
          {parseInt(c.items[0].client_id_i) !== 69 ? (
            <button
              type="button"
              onClick={this.toggle}
              className="ml-3 btn btn-primary rounded">
              <i className="fal fa-comment" />
            </button>
          ) : (
            ""
          )}

          <div className="float-right">
            <h4 className="mb-0">{this.getClientName(c.items[0])}</h4>
          </div>
        </div>
        <div className="d-flex flex-column">
          <AnimateHeight
            style={{ paddingLeft: "1px", paddingRight: "1px" }}
            duration={500}
            onAnimationEnd={() => this.refs.textarea.focus()}
            height={this.state.height} // see props documentation bellow
          >
            <textarea
              className="border-left-0 border-right-0 form-control rounded-0"
              style={{ backgroundColor: "floralwhite" }}
              ref="textarea"
              placeholder="Type hier een opmerking voor deze order"
              value={this.state.comment}
              onChange={this.commentChangeHandler}
              onBlur={(e) =>
                this.commentHandler(
                  e,
                  c.items[0].client_id_i +
                    "_" +
                    (parseInt(c.items[0].supplier_module_partner) === 1
                      ? c.items[0].product_supplier_id
                      : "0"),
                  ix2
                )
              }
            />
          </AnimateHeight>
        </div>
      </React.Fragment>
    );
  }
}

export default Checkoutheader;
