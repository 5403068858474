import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "../../assets/toekan_half.png";

class Login extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      isLoggingIn: false,
      loginType: 1,
    };
  }

  login = (e) => {
    e.preventDefault();
    const uname = this.usernameInput.value;
    const pw = this.passwordInput.value;

    this.props.loginHandler(this.props, uname, pw, this.state.loginType);
  };

  stopLoggingIn = (e) => {
    e.preventDefault();
    this.setState({ isLoggingIn: false });
  };

  setLoginType = (type) => {
    this.setState({ loginType: type });
  };

  render() {
    let errmsg;
    if (this.props.loginIncorrect) {
      errmsg = (
        <div className="mb-3 text-danger">
          Gebruikersnaam en/of wachtwoord onjuist.
        </div>
      );
    } else {
      errmsg = <div />;
    }
    return (
      <div className="row m-0">
        <div className="col text-center">
          <br />
          <br />
          <img
            src={require("../../assets/header_logo.png")}
            width={200}
            alt="Logo"
          />
          <br />
          <div className="loginmodal-container clearfix">
            <h4 className="mb-4">Valkweb Login</h4>
            {errmsg}
            <div id="login-form form-group">
              <form onSubmit={this.login}>
                <input type="hidden" name="redirect_url" value="" />
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  placeholder="Gebruikersnaam"
                  disabled={this.state.isLoggingIn}
                  ref={(usernameInput) => {
                    this.usernameInput = usernameInput;
                  }}
                />
                <br />
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Wachtwoord"
                  disabled={this.state.isLoggingIn}
                  ref={(passwordInput) => {
                    this.passwordInput = passwordInput;
                  }}
                />
                <br />
                <input
                  type="submit"
                  name="login"
                  className="btn btn-primary float-right"
                  value="Login"
                  disabled={this.state.isLoggingIn}
                />
                <br />
                <br />
                <br />
                <FormGroup className="mb-0">
                  <Label
                    className={
                      "pl-4 pr-4 text-left" +
                      (this.state.loginType === 1 ? " font-weight-bold" : "")
                    }
                  >
                    <Input
                      type="radio"
                      name="type"
                      value="1"
                      disabled={this.state.isLoggingIn}
                      onChange={() => this.setLoginType(1)}
                      checked={this.state.loginType === 1}
                    />{" "}
                    Hotel
                  </Label>
                  <Label
                    className={
                      "pl-4 pr-4 text-left" +
                      (this.state.loginType === 2 ? " font-weight-bold" : "")
                    }
                  >
                    <Input
                      type="radio"
                      name="type"
                      value="2"
                      disabled={this.state.isLoggingIn}
                      onChange={() => this.setLoginType(2)}
                      checked={this.state.loginType === 2}
                    />{" "}
                    Vertegenwoordiger
                  </Label>
                </FormGroup>
              </form>

              <div className="login-help">
                <a
                  href="/login"
                  style={{
                    display: this.state.isLoggingIn === true ? "block" : "none",
                  }}
                  onClick={this.stopLoggingIn}
                >
                  stop met inloggen
                </a>
              </div>
            </div>
            <div
              id="login-loader"
              style={{
                display: this.state.isLoggingIn === true ? "block" : "none",
              }}
            >
              <br />
              Bezig met inloggen...
            </div>
          </div>
          <br />
          <a href="https://app.ecbase.nl/backoffice">
            Inloggen voor leveranciers
          </a>
        </div>
      </div>
    );
  }
}

export default Login;
