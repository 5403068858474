import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";

import settings from "./settings.js";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, data: [] };
  }
  UNSAFE_componentWillMount() {
    this.props.authenticate("/");
  }

  componentDidMount() {
    if (this.props.isSalesRep) {
      this.props.history.push("/hotels");
    } else {
      this.loadData();
      this.props.searchHandler2("");
      this.props.setSearchClient(0);
      this.props.closeMenu();
      this.props.addBreadcrumb([{ name: "", url: "", reset: true }]);
    }
  }

  componentDidUpdate() {
    if (this.props.isSalesRep) {
      this.props.history.push("/hotels");
      return null;
    }
  }
  loadData() {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url +
        "get_newsitems/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }

  createMarkup(txt) {
    return { __html: txt };
  }

  render() {
    if (!this.props.isAuthenticated) return null;

    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        onUpdate={this.handleUpdate}
        className="row ml-0 content"
      >
        <div className="col p-md-4 px-0 maxw1200">
          <div className="col">
            {this.state.data.map((notification, index) => {
              let nieuwsDate = new Date(notification.nieuws_date);
              let edd = nieuwsDate.getDate();
              let emm = nieuwsDate.getMonth() + 1; //January is 0!

              let eyyyy = nieuwsDate.getFullYear();
              if (edd < 10) {
                edd = "0" + edd;
              }
              if (emm < 10) {
                emm = "0" + emm;
              }
              let end = edd + "-" + emm + "-" + eyyyy;
              return (
                <div
                  className={"notifications content-block mb-3 row py-1"}
                  key={notification.nieuws_id}
                >
                  <div className="col-12 d-flex justify-content-between mb-3">
                    <strong className="text-left">
                      {notification.nieuws_title}
                    </strong>{" "}
                    <span className="nowrap ml-3">{end}</span>
                  </div>
                  <div
                    className="col-12 text-left"
                    dangerouslySetInnerHTML={this.createMarkup(
                      notification.nieuws_body
                    )}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default Home;
