import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const WAIT_INTERVAL = 300;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dropdownOpen: false,
      expand: false,
      dropdownOpen2: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  filterQuantity = (item) => {
    if (!isNaN(parseInt(item.quantity)) && item.quantity > 0) {
      return true;
    }
    return false;
  };

  toggleDropdown = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  searchHandler = (e) => {
    e.preventDefault();

    clearTimeout(this.timer);

    let val = e.target.value;

    this.props.searchHandler2(val);

    this.timer = setTimeout(() => {
      this.triggerChange(val);
    }, WAIT_INTERVAL);
  };

  triggerChange = (val) => {
    if (val !== "") {
      if (this.props.location.pathname !== "/producten/zoeken/" + val) {
        this.props.history.push("/producten/zoeken/" + val);
      }
      this.props.searchHandler(val);
    }
  };

  filterClient = (clientID) => {
    this.props.setSearchClient(clientID);
  };

  shrink = (e) => {
    this.setState({ expand: false });
  };

  expand = (e) => {
    this.setState({ expand: true });
  };

  toggleDropDown2 = () => {
    this.setState({ dropdownOpen2: !this.state.dropdownOpen2 });
  };

  render() {
    return (
      <Row>
        <Col className="topbar m-0">
          <button
            tag="button"
            type="button"
            className="btn w50 d-lg-none mobileMenuToggle carthover btn-cart"
            onClick={this.props.toggleMenu}
          >
            {this.props.showMenu ? (
              <i className="fal fa-times" />
            ) : (
              <i className="fal fa-bars" />
            )}
          </button>
          <Col
            className={this.state.expand ? "col-search expand" : "col-search "}
          >
            <Row className="h-100">
              <Col className="px-3 d-flex align-items-center justify-content-end">
                {!this.props.isSalesRep ? (
                  <div className="input-group searchicon">
                    <InputGroup>
                      <Input
                        className="input-search ml-md-3"
                        name="search"
                        value={this.props.searchString2}
                        onChange={this.searchHandler}
                        placeholder="Zoek een product..."
                        autoComplete="off"
                        onClick={this.expand}
                      />
                      <InputGroupButtonDropdown
                        addonType="append"
                        className="mr-md-3 d-none d-md-flex"
                        isOpen={this.state.dropdownOpen2}
                        toggle={this.toggleDropDown2}
                      >
                        <DropdownToggle className="btn-light" caret>
                          {this.props.searchClientText}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.filterClient(0)}>
                            Zoek in alle afdelingen
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={() => this.filterClient(10)}>
                            Van der Valk Koel &amp; Vries
                          </DropdownItem>
                          <DropdownItem onClick={() => this.filterClient(54)}>
                            Van der Valk Dagvers
                          </DropdownItem>
                          <DropdownItem onClick={() => this.filterClient(11)}>
                            Van der Valk Centraal Magazijn
                          </DropdownItem>
                          <DropdownItem onClick={() => this.filterClient(69)}>
                            Van der Valk Versmarkt
                          </DropdownItem>
                          <DropdownItem onClick={() => this.filterClient(61)}>
                            Van der Valk Vis
                          </DropdownItem>
                          <DropdownItem onClick={() => this.filterClient(29)}>
                            Van der Valk Apparaten
                          </DropdownItem>
                        </DropdownMenu>
                      </InputGroupButtonDropdown>
                    </InputGroup>

                    <div className="icon" onClick={this.shrink}>
                      {this.state.expand ? (
                        <i className="far fa-times" />
                      ) : (
                        <i className="far fa-search" />
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
          <Col className="col-cart p-0">
            <button
              tag="button"
              type="button"
              disabled={this.props.isSalesRep}
              onClick={this.props.toggleOrderlists}
              className="btn btn-link btn-cart menuhover"
            >
              <i className="fal fa-list" />
            </button>

            {!this.props.showCart && !this.props.showOrderlists ? (
              <button
                tag="button"
                type="button"
                className="btn btn-link btn-cart carthover"
                onClick={this.props.toggleCart}
              >
                <i className="fal fa-shopping-cart" />
                {this.props.cart.filter(this.filterQuantity).length > 0 ? (
                  <span className="badge badge-warning">
                    {this.props.cart.filter(this.filterQuantity).length}
                  </span>
                ) : (
                  ""
                )}
              </button>
            ) : (
              ""
            )}
          </Col>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Header);
