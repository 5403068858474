import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import settings from "./../settings.js";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: false, isLoading: false, data: [] };
    this.toggle = this.toggle.bind(this);
  }
  loadNewsDetails() {
    const notification = this.props.notification;

    fetch(
      settings.server_url +
        "get_newsitem/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        notification.nieuws_id
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }
  clickRemove = (e, index) => {
    this.setState({
      modal: false,
    });
    this.props.clickRemoveHandler(e, index);
  };
  toggle() {
    if (!this.state.modal) {
      this.loadNewsDetails();
    }
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  createMarkup(txt) {
    return { __html: txt };
  }
  render() {
    const notification = this.props.notification;

    let cls = "notification";
    if (notification.removed) {
      cls = "notification removed";
    }

    return (
      <div className={cls} key={notification.nieuws_id}>
        <i className="fas fa-exclamation-triangle" onClick={this.toggle} />
        <label onClick={this.toggle}>
          <strong>{notification.nieuws_title}</strong> -{" "}
          {notification.nieuws_body}
        </label>
        <a href="/" onClick={this.props.clickRemoveHandler}>
          wis
        </a>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            {notification.nieuws_title}
          </ModalHeader>
          <ModalBody
            dangerouslySetInnerHTML={this.createMarkup(
              this.state.data.nieuws_body
            )}
          />
          <ModalFooter>
            <Button color="danger" onClick={this.clickRemove}>
              Bericht wissen
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Sluiten
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Notification;
