import React, { Component } from "react";
import { TabContent, TabPane } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import settings from "./../settings.js";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

class CurrentOrders extends Component {
  constructor(props) {
    super(props);

    const dt = new Date();

    this.state = {
      orders: [],
      weeks: [],
      weekyear: dt.getWeek().toString() + dt.getWeekYear().toString(),
      activeTab: "1",
      searchProduct: "",
      searchResults: [],
      orderdata: [],
      orderID: 0,
    };
  }

  componentDidMount() {
    this.loadOrders("");
    this.loadOrdersCountWeeks();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  loadOrders(wy) {
    this.props.loading(true);
    fetch(
      settings.server_url +
        "get_orders/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        wy
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ orders: response });
        this.props.loading(false);
        this.props.addBreadcrumb([
          { name: "Lopende orders", url: "/orders", reset: false },
        ]);
      })
      .catch((error) => console.error("Error:", error));
  }

  loadOrder = (e, orderid, clientid) => {
    e.preventDefault();
    this.props.addBreadcrumb([
      { name: "Order " + orderid, url: "/orders", reset: false },
    ]);

    this.setState({ orderdata: [], orderID: orderid });
    this.props.loading(true);
    this.toggle("2");
    fetch(
      settings.server_url +
        "get_order/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        clientid +
        "/" +
        orderid
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ orderdata: response });
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  };

  loadOrdersCountWeeks() {
    fetch(
      settings.server_url +
        "get_orders_count_weeks/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ weeks: response });
      })
      .catch((error) => console.error("Error:", error));
  }

  loadSearchOrders = (e) => {
    e.preventDefault();
    this.props.loading(true);
    this.toggle("3");
    fetch(
      settings.server_url +
        "get_search_order_products/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.state.searchProduct
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ searchResults: response });
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.loadSearchOrders(event);
    }
  };

  changeWeekHandler = (e) => {
    this.setState({ weekyear: e.target.value });
    this.loadOrders(e.target.value);
  };

  searchProduct = (e) => {
    e.preventDefault();

    this.setState({ searchProduct: e.target.value });
    /*
    if (val > "") {
      this.toggle("3", null);
    } else {
      this.toggle("1", null);
    }
*/
  };

  getDeliveryNoteLink(orderid) {
    return (
      settings.server_url +
      "get_pdf_delivery_note/" +
      window.sessionStorage.getItem("sid") +
      "/" +
      orderid
    );
  }

  client(client) {
    let clientBadge;
    switch (parseInt(client)) {
      case 10:
        clientBadge = <span>Van der Valk Koel & Vries</span>;
        break;
      case 11:
        clientBadge = <span>Van der Valk Centraal Magazijn</span>;
        break;
      case 29:
        clientBadge = <span>Van der Valk Apparaten</span>;
        break;
      case 54:
        clientBadge = <span>Van der Valk Dagvers</span>;
        break;
      case 69:
        clientBadge = <span>Van der Valk Versmarkt</span>;
        break;
      case 61:
        clientBadge = <span>Van der Valk Vis</span>;
        break;
      default:
        clientBadge = <span>?</span>;
    }
    return clientBadge;
  }

  badge(day) {
    let daybadge;
    switch (parseInt(day)) {
      case 0:
        daybadge = <span className="daybadge ma">Ma</span>;
        break;
      case 1:
        daybadge = <span className="daybadge di">Di</span>;
        break;
      case 2:
        daybadge = <span className="daybadge wo">Wo</span>;
        break;
      case 3:
        daybadge = <span className="daybadge do">Do</span>;
        break;
      case 4:
        daybadge = <span className="daybadge vr">Vr</span>;
        break;
      case 5:
        daybadge = <span className="daybadge za">Za</span>;
        break;
      case 6:
        daybadge = <span className="daybadge za">Zo</span>;
        break;
      default:
        daybadge = <span className="daybadge za">?</span>;
    }
    return daybadge;
  }

  status(status) {
    let statusbadge;

    switch (parseInt(status)) {
      case 0:
        statusbadge = (
          <div className="text-primary">
            <i className="fas fa-star mr-3" />
            Nieuw
          </div>
        );
        break;
      case 4:
        statusbadge = (
          <div className="text-muted">
            <i className="far fa-clock mr-3" />
            In behandeling
          </div>
        );
        break;
      case 5:
        statusbadge = (
          <div className="text-warning">
            <i className="fas fa-truck mr-3" />
            Onderweg
          </div>
        );
        break;
      case 1:
        statusbadge = (
          <div className="text-success">
            <i className="far fa-check-square mr-3" />
            Afgeleverd
          </div>
        );
        break;
      case 3:
        statusbadge = (
          <div className="text-success">
            <i className="far fa-check-square mr-3" />
            Afgeleverd
          </div>
        );
        break;
      case 7:
        statusbadge = (
          <div className="text-success">
            <i className="far fa-check-square mr-3" />
            Afgeleverd
          </div>
        );
        break;
      case 9:
        statusbadge = (
          <div className="text-muted">
            <i className="far fa-clock mr-3" />
            In behandeling
          </div>
        );
        break;
      case 13:
        statusbadge = (
          <div className="text-muted">
            <i className="far fa-clock mr-3" />
            In behandeling
          </div>
        );
        break;
      case 19:
        statusbadge = (
          <div className="text-muted">
            <i className="far fa-clock mr-3" />
            Klaar voor leveren
          </div>
        );
        break;
      case 20:
        statusbadge = (
          <div className="text-success">
            <i className="far fa-check-square mr-3" />
            Afgeleverd
          </div>
        );
        break;
      case 14:
        statusbadge = (
          <div className="text-info">
            <i className="far fa-angle-double-right mr-3" />
            Rechtstreeks
          </div>
        );
        break;

      default:
        statusbadge = (
          <div className="text-primary">
            <i className="fas fa-star mr-3" />?
          </div>
        );
    }
    return statusbadge;
  }

  render() {
    return (
      <TabContent activeTab={this.state.activeTab}>
        <TabPane tabId="1">
          <div className="form-inline orderheader px-3 py-4">
            <div className="w-100">
              <div className="float-left d-flex flex-wrap flex-md-nowrap">
                <div className="input-group">
                  <select
                    className="custom-select mb-3 mb-md-0"
                    value={this.state.weekyear}
                    onChange={this.changeWeekHandler}
                  >
                    {this.state.weeks.map((week, ix) => {
                      return (
                        <option key={ix} value={week.w + week.y}>
                          {"Week " +
                            week.w +
                            " " +
                            week.y +
                            " (" +
                            week.cnt +
                            ")"}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-group searchicon">
                  <input
                    type="text"
                    className="form-control input-search mx-md-3 pr-5"
                    name="search"
                    placeholder="Zoek een product..."
                    autoComplete="off"
                    value={this.state.searchProduct}
                    onChange={this.searchProduct}
                    onKeyPress={this.handleKeyPress}
                  />
                  <button
                    type="button"
                    onClick={this.loadSearchOrders}
                    className="btn btn btn-outline-primary"
                  >
                    <i className="far fa-search" /> Zoeken
                  </button>
                </div>
              </div>
              <div className="float-right mt-3 mt-md-0">
                <div className="border border-primary btn btn-link">
                  <i className="far fa-file-export mr-2" />
                  Lijst exporteren
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table table-hover mb-0 border bg-white">
              <Thead>
                <Tr>
                  <Th className="text-left" />
                  <Th className="text-left text-muted">ORDER</Th>
                  <Th className="text-left text-muted">AFDELING</Th>
                  <Th className="text-left text-muted">ORDERDATUM</Th>
                  <Th className="text-left text-muted">VERWACHT</Th>
                  <Th className="text-center text-muted">REGELS</Th>
                  <Th className="text-left text-muted">STATUS</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {this.state.orders.map((order, i) => {
                  return (
                    <Tr key={order.order_id} className="historyrow">
                      <Td>{this.badge(order.day)}</Td>
                      <Td className="text-left align-middle">
                        <a
                          href="#"
                          onClick={(e) => {
                            this.loadOrder(
                              e,
                              order.order_id,
                              order.order_vestiging_id
                            );
                          }}
                        >
                          {order.order_id}
                        </a>
                      </Td>
                      <Td className="text-left align-middle text-truncate">
                        {this.client(order.order_vestiging_id)}
                      </Td>
                      <Td className="text-left align-middle">
                        {order.order_date}
                      </Td>
                      <Td className="text-left align-middle">
                        {order.order_delivery_moment}
                      </Td>
                      <Td className="text-center align-middle">{order.cnt}</Td>
                      <Td className="text-left align-middle">
                        {parseInt(order.order_status) === 5 &&
                        parseInt(order.order_vestiging_id) === 54
                          ? this.status(1)
                          : this.status(order.order_status)}
                      </Td>
                      <Td className="align-middle">
                        <a
                          href={this.getDeliveryNoteLink(order.order_id)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fal fa-print" />
                        </a>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="align-items-center d-flex orderheader p-3 text-left">
            <div className="">
              <div
                className="btn btn-link p-2"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle("1");
                }}
              >
                <i className="fal fa-arrow-left mr-2" />
                Terug naar overzicht
              </div>
              <div className="font-weight-bold">Order {this.state.orderID}</div>
            </div>
            <div className="ml-auto">
              <div className="border border-primary  btn btn-link">
                <i className="far fa-file-export mr-2" />
                Lijst exporteren
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table table-hover table-striped mb-0 border bg-white">
              <Thead>
                <Tr>
                  <Th className="text-left text-muted">Product</Th>
                  <Th className="text-left" />
                  <Th className="text-center text-muted">Artikelnr</Th>
                  <Th className="text-center text-muted">Eenheid</Th>
                  <Th className="text-center text-muted">Aantal</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.orderdata.map((order, i) => {
                  return (
                    <Tr key={i}>
                      <Td className="align-middle hidden">
                        {order.image > "" ? (
                          <img
                            src={order.image}
                            alt=""
                            className="max50 img-fluid"
                          />
                        ) : (
                          <span className="image_placeholder" />
                        )}
                      </Td>
                      <Td className="align-middle text-left">
                        <strong>{order.orderrow_name}</strong>
                      </Td>
                      <Td className="align-middle">{order.orderrow_art_nr}</Td>
                      <Td className="align-middle">
                        <div className="unit">
                          <span>{order.orderrow_unit}</span>
                        </div>
                      </Td>
                      <Td className="align-middle">
                        {parseFloat(order.orderrow_quantity)
                          .toString()
                          .replace(".", ",")}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className="form-inline orderheader px-3 py-4">
            <div className="w-100">
              <div className="float-left d-flex flex-wrap flex-md-nowrap">
                <div className="input-group">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggle("1");
                    }}
                    className="btn btn btn-outline-primary"
                  >
                    <i className="fal fa-long-arrow-left" /> Terug
                  </button>
                </div>
                <div className="input-group searchicon">
                  <input
                    type="text"
                    className="form-control input-search mx-md-3 pr-5"
                    name="search"
                    placeholder="Zoek een product..."
                    autoComplete="off"
                    value={this.state.searchProduct}
                    onChange={this.searchProduct}
                    onKeyPress={this.handleKeyPress}
                  />
                  <button
                    type="button"
                    onClick={this.loadSearchOrders}
                    className="btn btn btn-outline-primary"
                  >
                    <i className="far fa-search" /> Zoeken
                  </button>
                </div>
              </div>
              <div className="float-right mt-3 mt-md-0">
                <div className="border border-primary  btn btn-link">
                  <i className="far fa-file-export mr-2" />
                  Lijst exporteren
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table table-hover mb-0 border bg-white">
              <Thead>
                <Tr>
                  <Th className="text-left text-muted">ARTIKEL #</Th>
                  <Th className="text-left text-muted">ARTIKEL</Th>
                  <Th className="text-left text-muted">AFDELING</Th>
                  <Th className="text-left text-muted">AANTAL</Th>
                  <Th className="text-left text-muted">LEVERDATUM</Th>
                  <Th className="text-left text-muted">STATUS</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {this.state.searchResults.map((row, i) => {
                  return (
                    <Tr key={i} className="historyrow">
                      <Td className="text-left align-middle">
                        {row.orderrow_art_nr}
                      </Td>
                      <Td className="text-left align-middle  text-truncate">
                        {row.orderrow_name}
                      </Td>
                      <Td className="text-left align-middle  text-truncate">
                        {this.client(row.order_vestiging_id)}
                      </Td>
                      <Td className="text-right align-middle">
                        {row.orderrow_quantity}
                      </Td>
                      <Td className="text-left align-middle">
                        {row.order_delivery_moment}
                      </Td>
                      <Td className="text-left align-middle">
                        {this.status(row.order_status)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        </TabPane>
      </TabContent>
    );
  }
}

export default CurrentOrders;
