import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";

import { Link } from "react-router-dom";
import settings from "./settings.js";

class Hotels extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], loading: false, top: 0 };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.authenticate("/");
  }

  componentDidMount() {
    this.props.searchHandler2("");
    this.props.setSearchClient(0);
    this.loadCustomers();
    this.props.closeMenu();
    this.props.addBreadcrumb([{ name: "Hotels", url: "/hotels", reset: true }]);
  }

  loadCustomers() {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url +
        "get_salesrep_customers/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }

  checkActiveCustomer = (e, id) => {
    if (
      this.props.cart.length > 0 &&
      parseInt(id) !== this.props.activeSalesRepCustomerID &&
      this.props.activeSalesRepCustomerID !== 0
    ) {
      if (
        !window.confirm(
          "U heeft artikelen in uw winkelwagen voor een ander hotel. Indien u verder gaat wordt deze bestelling verwijderd. Wilt u verdergaan?"
        )
      )
        e.preventDefault();
    }
  };

  renderView({ style, ...props }) {
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  handleUpdate(values) {
    // this.setState({ top });
  }

  render() {
    if (!this.props.isAuthenticated) return null;
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        onUpdate={this.handleUpdate}
        className="row ml-0 content"
      >
        <div className="col p-md-4 px-0 maxw1200 text-left">
          <div className="content-block mr-3">
            {this.state.data.map((c) => {
              return (
                <div key={c.CustomerID} className="product px-2">
                  {c.CustomerName}
                  {c.CustomerID === this.props.activeSalesRepCustomerID ? (
                    <span className="badge badge-success ml-3">Actief</span>
                  ) : (
                    ""
                  )}
                  <Link
                    to={"/hotels/producten/" + c.CustomerID}
                    className="btn btn-primary d-inline-block float-right linkmore mr-3 mt-3 text-uppercase text-white"
                    onClick={(e) => this.checkActiveCustomer(e, c.CustomerID)}
                  >
                    Bestellen
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default Hotels;
