import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = { top: 0 };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  priceMarkup(x) {
    if (x != null) {
      let parts = x
        .toFixed(2)
        .toString()
        .split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    } else {
      return "0,00";
    }
  }

  renderView({ style, ...props }) {
    const viewStyle = { paddingRight: "8px" };
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  handleUpdate(values) {
    //this.setState({ top });
  }

  checkoutHandler = e => {
    e.preventDefault();
    this.props.toggleCart(e);
    this.props.history.push("/checkout");
  };

  Total() {
    let sum = 0;
    this.props.cart.map(item => {
      if (item !== null && item.quantity !== null && item.calcprice !== null) {
        sum =
          sum +
          item.quantity *
            (item.weight != null && item.weight > 0 ? item.weight : 1) *
            item.calcprice;
      }
      return sum;
    });
    sum = this.priceMarkup(sum);
    return sum;
  }

  render() {
    //console.log(this.props.cart.length);

    var reverseCart = JSON.parse(JSON.stringify(this.props.cart));

    return (
      <div
        className={
          this.props.showCart ? "sidebar-right active" : "sidebar-right"
        }
      >
        <div className="cartheader d-flex">
          <div className="align-items-center d-flex p-3 text-left w-100">
            <h6 className="mb-0 font-weight-bold">UW BESTELLING</h6>
          </div>
          <div
            className="align-items-center d-flex py-3 px-4 border-left border-gray fakelink bg-white"
            onClick={this.props.toggleCart}
          >
            <i className="far fa-chevron-right" />
          </div>
        </div>
        <Scrollbars
          renderView={this.renderView}
          renderThumbHorizontal={this.renderThumb}
          renderThumbVertical={this.renderThumb}
          onUpdate={this.handleUpdate}
          className="row m-0 waitingOrder"
        >
          {this.props.showCart
            ? reverseCart.reverse().map(item => {
                if (item.quantity > 0) {
                  return (
                    <div
                      key={item.product_id + "_" + item.bestellijst_id}
                      className="col-12 px-2 py-2 border-bottom"
                    >
                      <div className="d-flex mr-1">
                        <div className="photo">
                          {item.image > "" ? (
                            <img src={item.image} alt="" className="mr-2" />
                          ) : (
                            <span className="image_placeholder" />
                          )}
                        </div>
                        <div className="d-flex flex-column justify-content-between media-body">
                          <span className="mt-0 d-block text-left font-weight-bold">
                            {item.product_name}
                          </span>
                          <div className="d-flex">
                            <span className="w-25 text-left">
                              {" "}
                              {item.product_unit}
                            </span>
                            <span className="text-black-50 w-75 text-left">
                              {item.quantity} x{" "}
                              {item.weight !== null && item.weight !== 0
                                ? "(" +
                                  parseFloat(item.weight)
                                    .toString()
                                    .replace(".", ",") +
                                  "kg)"
                                : ""}{" "}
                              &euro;
                              {this.priceMarkup(item.calcprice)}
                            </span>
                          </div>
                        </div>
                        <div className="align-self-end font-weight-bold">
                          &euro;{" "}
                          {this.priceMarkup(
                            item.quantity *
                              (item.weight > 0 ? item.weight : 1) *
                              item.calcprice
                          )}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            : ""}
        </Scrollbars>
        <div className="orderButtonContainer">
          <div className="d-flex justify-content-between font-weight-bold mb-3 text-uppercase">
            <span>Totaal</span>
            <div>
              <span className="mr-2">&euro;</span>
              <span>{this.Total()}</span>
            </div>
          </div>

          <Link
            to="/checkout"
            className={
              this.props.cart.length === 0
                ? "btn btn-primary disabled w-100 text-uppercase"
                : "btn btn-primary w-100 text-uppercase"
            }
            onClick={this.checkoutHandler}
          >
            {" "}
            afronden{" "}
          </Link>
        </div>
      </div>
    );
  }
}

export default Cart;
