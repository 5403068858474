import React, { Component } from "react";
import DatePicker from "react-datepicker";

import TopProductRow from "./topproductrow";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import settings from "./../settings.js";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-datepicker/dist/react-datepicker.css";

/*
import nl from "date-fns/locale/nl/index";
registerLocale("nl-NL", nl);
*/

let date = new Date();

class TopProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      newproducts: [],
      clients: [],
      filterClientID: 0,
      startDate: new Date(date.getFullYear(), 0, 1),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    };
  }

  componentDidMount() {
    this.loadClientList();
    this.loadTopProducts();
  }

  toggle(tab, order) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handleClientChange = (e) => {
    this.setState({ filterClientID: e.target.value }, () => {
      this.loadTopProducts();
    });
  };

  handleChangeStart = (e) => {
    if (new Date(e) > new Date(this.state.endDate)) {
      this.setState({ endDate: e });
    }
    this.setState(
      {
        startDate: e,
      },
      () => this.loadTopProducts()
    );
  };

  handleChangeEnd = (e) => {
    if (new Date(e) < new Date(this.state.startDate)) {
      this.setState({ startDate: e });
    }
    this.setState(
      {
        endDate: e,
      },
      () => this.loadTopProducts()
    );
  };
  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  loadTopProducts() {
    this.setState({ isLoading: true });
    this.props.loading(true);
    let startDate = new Date(this.state.startDate);
    let sdd = startDate.getDate();
    let smm = startDate.getMonth() + 1; //January is 0!

    let syyyy = startDate.getFullYear();
    if (sdd < 10) {
      sdd = "0" + sdd;
    }
    if (smm < 10) {
      smm = "0" + smm;
    }
    let start = sdd + "-" + smm + "-" + syyyy;

    let endDate = new Date(this.state.endDate);
    let edd = endDate.getDate();
    let emm = endDate.getMonth() + 1; //January is 0!

    let eyyyy = endDate.getFullYear();
    if (edd < 10) {
      edd = "0" + edd;
    }
    if (emm < 10) {
      emm = "0" + emm;
    }
    let end = edd + "-" + emm + "-" + eyyyy;
    fetch(
      settings.server_url +
        "get_top_products/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.state.filterClientID +
        "/" +
        start +
        "/" +
        end
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ newproducts: response, isLoading: false });
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }
  loadClientList() {
    fetch(
      settings.server_url +
        "get_active_clients/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ clients: response });
      })
      .catch((error) => console.error("Error:", error));
  }

  render() {
    return (
      <div className="displaycontainer">
        <form className="form-inline orderheader px-3 py-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text equalize-prepend">Van</span>
            </div>
            <DatePicker
              selected={this.state.startDate}
              onChange={this.handleChangeStart}
              ref={(el) => this.onDatepickerRef(el)}
              className="form-control mr-3 bg-white"
              dateFormat="dd-MM-yyyy"
            />
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text equalize-prepend">Tot</span>
            </div>
            <DatePicker
              selected={this.state.endDate}
              onChange={this.handleChangeEnd}
              ref={(el) => this.onDatepickerRef(el)}
              className="form-control mr-3 bg-white"
              dateFormat="dd-MM-yyyy"
            />
          </div>
          <select
            className="form-control float-right"
            onChange={this.handleClientChange}
            value={this.state.filterClientID}
          >
            <option value="0">Alle afdelingen</option>
            {this.state.clients.map((client) => {
              return (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              );
            })}
          </select>
        </form>
        <div className="table-responsive">
          <Table className="table table-hover mb-0 border">
            <Thead>
              <Tr>
                <Th className="text-left" />
                <Th className="text-left" />
                <Th className="text-center">Artikelnr</Th>
                <Th className="text-left">Artikel</Th>
                <Th className="text-center">Eenheid</Th>
                <Th className="text-center">Totaal</Th>
                <Th className="text-center">Aantal</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.newproducts.map((product) => {
                return (
                  <TopProductRow
                    key={product.product_id}
                    product={product}
                    cart={this.props.cart}
                    cartHandler={this.props.cartHandler}
                  />
                );
              })}
            </Tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default TopProducts;
