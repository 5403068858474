import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Td, Tr } from "react-super-responsive-table";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

class TopProductRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
      weight: this.props.product.product_weight,
      totalWeight: 0,
      showweight: false,
    };
  }
  inc = (e, product) => {
    e.preventDefault();
    if (this.props.product.product_is_weight === 1 && this.state.quantity === 0) {
      this.openweight();
    }
    this.props.cartHandler("INC", this.props.product);
    this.setState({ quantity: this.state.quantity + 1 });
  };

  dec = (e, product) => {
    e.preventDefault();
    this.props.cartHandler("DEC", this.props.product);

    if (this.state.quantity > 0) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  changeHandler = (e) => {
    if (!isNaN(parseInt(e.target.value))) {
      if (this.props.product.product_is_weight === 1 && this.state.quantity === 0) {
        this.openweight();
        this.setState({ quantity: parseInt(e.target.value) });
      }
      this.props.cartHandler("UPDATE", this.props.product, parseInt(e.target.value));
    } else {
      this.props.cartHandler("REMOVE", this.props.product);
    }
  };

  weightHandler = (e) => {
    e.preventDefault();
    this.setState({ weight: e.target.value });
  };

  openweight = (e) => {
    this.setState({ showweight: true });
  };
  closeweight = (e) => {
    e.preventDefault();
    this.setState({ showweight: false });
  };

  createMarkup(txt) {
    return { __html: txt };
  }

  priceMarkup(x) {
    let parts = x.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  findCartIndexByID(id) {
    for (let i = 0; i < this.props.cart.length; i++) {
      if (this.props.cart[i].product_id === id) {
        return i;
      }
    }
    return false;
  }

  render() {
    const cartix = this.findCartIndexByID(this.props.product.product_id, this.props.cart);
    const quantity = cartix !== false ? this.props.cart[cartix].quantity : "";

    const product = this.props.product;

    let clientBadge;
    switch (parseInt(product.client_id_i)) {
      case 10:
        clientBadge = <span className="badge badge-success">KV</span>;
        break;
      case 11:
        clientBadge = <span className="badge badge-primary">DC</span>;
        break;
      case 29:
        clientBadge = <span className="badge badge-warning">AP</span>;
        break;
      case 54:
        clientBadge = <span className="badge badge-info">DV</span>;
        break;
      case 69:
        clientBadge = <span className="badge badge-danger">VM</span>;
        break;
      case 61:
        clientBadge = (
          <span className="badge badge-danger" style={{ backgroundColor: "lightblue" }}>
            VIS
          </span>
        );
        break;
      default:
        clientBadge = <span className="badge">?</span>;
    }

    return (
      <Tr>
        <Td className="align-middle hidden">{clientBadge}</Td>
        <Td className="align-middle hidden">
          <Link to={"/producten/details/" + product.product_id}>
            {product.image > "" ? (
              <img src={product.image} alt="" className="max50 img-fluid" />
            ) : (
              <span className="image_placeholder" />
            )}
          </Link>
        </Td>
        <Td className="align-middle">{product.product_ext_code}</Td>
        <Td className="align-middle text-left">
          <Link to={"/producten/details/" + product.product_id}>
            <span dangerouslySetInnerHTML={this.createMarkup(product.product_name)} />
          </Link>
        </Td>
        <Td className="align-middle">
          <div className="unit">
            <span>{product.product_unit !== "" ? product.product_unit : "STUK"}</span>
          </div>
        </Td>
        <Td className="align-middle">{product.qty}</Td>
        <Td className="align-middle">
          <div className="historyproduct">
            <div className="cartprice">
              <div className="cart-buttons mx-3">
                <div className="float-left text-left">
                  <div
                    className={product.product_is_weight === 1 ? "a-weight" : "a-weight disabled"}
                    onClick={product.product_is_weight === 1 ? this.openweight : null}>
                    <i className="far fa-balance-scale" />
                  </div>
                </div>
                <a
                  href="#"
                  className={quantity > 0 ? "a-min active" : "a-min"}
                  disabled={quantity === 0}
                  onClick={(e, product) => {
                    this.dec(e, product);
                  }}>
                  -
                </a>
                <input
                  type="text"
                  className={quantity > 0 ? "input-cart active" : "input-cart"}
                  onChange={this.changeHandler}
                  placeholder="0"
                  defaultValue={quantity}
                />
                <a
                  href="#"
                  className={quantity > 0 ? "a-plus active" : "a-plus"}
                  onClick={(e, product) => {
                    this.inc(e, product);
                  }}>
                  +
                </a>
                <div className={this.state.showweight ? "productweight show" : "productweight"}>
                  <div className="weightinput">
                    <span>Gewicht</span>
                    <input
                      type="number"
                      defaultValue={this.state.weight}
                      disabled={product.product_is_weight === 1 && product.product_weight > 0}
                    />
                  </div>
                  <div className="weightinput">
                    <span>Totaal</span>
                    <input type="number" />
                  </div>
                  <div>
                    <a
                      href="#"
                      className="border-left d-block h-100 px-3"
                      onClick={(e) => {
                        this.closeweight(e);
                      }}>
                      <i className="fa-chevron-right far text-white" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Td>
      </Tr>
    );
  }
}

export default TopProductRow;
