import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

function Breadcrumbs(props) {
  //console.log(props);
  //const paths = props.match.url.split("/");

  let bc = [];
  if (props.breadcrumbPath) {
    bc = props.breadcrumbPath;
  }

  if (props.match.path === "/" && props.match.isExact) {
    return null;
  } else if (bc.length > 0) {
    return (
      <Row>
        b
        <Col className="bc-bar px-5">
          <Link to="/">
            <i className="fal fa-home" />
          </Link>
          {bc.map((bci, i) => {
            if (bc.length === i + 1) {
              return (
                <React.Fragment key={i}>
                  <i className="fal fa-arrow-right" />
                  <strong>{bci.name}</strong>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={i}>
                  <i className="fal fa-arrow-right" />
                  {bci.url !== "" ? (
                    <Link
                      to={bci.url}
                      onClick={() => {
                        props.addBreadcrumb([bci]);
                      }}
                    >
                      <strong>{bci.name}</strong>
                    </Link>
                  ) : (
                    <strong>{bci.name}</strong>
                  )}
                </React.Fragment>
              );
            }
          })}
        </Col>
      </Row>
    );
  } else {
    return null;
  }
}

export default Breadcrumbs;
