import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import settings from "./../settings.js";

class DelayedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }
  componentDidMount() {
    this.loadDelayed();
  }
  loadDelayed() {
    fetch(
      settings.server_url +
        "get_delayed_deliveries/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });
      })
      .catch((error) => console.error("Error:", error));
  }
  render() {
    if (this.state.data.length > 0) {
      return (
        <div className="table-responsive">
          <Table className="table table-striped table-hover mb-0 border bg-white">
            <Thead>
              <Tr>
                <Th className="align-middle text-left columnexpand">
                  <strong>Artikel</strong>
                </Th>
                <Th className="align-middle text-left px-5">Aantal</Th>
                <Th className="align-middle px-5">Verwacht</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.data.map((delayed, i) => {
                return (
                  <Tr key={i}>
                    <Td className="align-middle text-left columnexpand">
                      {delayed.orderrow_name}
                    </Td>
                    <Td> {delayed.orderrow_quantity}</Td>
                    <Td> {delayed.order_delivery_moment}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      );
    } else {
      return (
        <div className="py-4">
          <span>Er zijn geen uitgestelde leveringen bekend</span>
        </div>
      );
    }
  }
}

export default DelayedOrders;
