import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

import slugify from "../lib.js";

/*import logo from "../assets/header_logo.png";*/
import logo2 from "../assets/LogoValk-Exclusief-wit.jpg";

class Sidemenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubMenu: false,
      clientID: 0,
      top: 0,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  renderView({ style, ...props }) {
    const viewStyle = { paddingRight: "8px" };
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderThumbMenu({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderTrack({ style, ...props }) {
    const track = {
      position: "absolute",
      width: "6px",
      right: "0px",
      bottom: "0px",
      top: "0px",
    };
    return <div style={{ ...style, ...track }} {...props} />;
  }

  handleUpdate(values) {
    //const { top } = values;
    //this.setState({ top });
  }
  promoClick = (e) => {
    //e.preventDefault();
    this.props.searchHandler2("");
    this.props.searchHandler("");
    this.props.setSearchClient(0);
    this.props.setProductgroupHandler(0);

    //this.props.history.push("/aanbiedingen");
  };

  closeNavProducts = (e) => {
    e.preventDefault();
    this.props.closeNavProducts(e);
    this.props.closeMenu();
  };

  render() {
    const menuFix = {
      position: "relative",
      height: "calc(100% - (6em + 1px))",
    };

    return (
      <div>
        <div
          className={this.props.showMenu ? "sidebar-left show" : "sidebar-left"}
        >
          <div className="sidebar-logo">
            <div>
              <span className="float-left m-1 small text-muted" />
              <img src={logo2} className="valk-logo" alt="logo" />
            </div>
            <div
              className="align-items-center d-flex py-3 px-4 d-lg-none"
              onClick={this.props.toggleMenu}
            >
              <i className="far fa-times px-3 text-danger h3 m-0" />
            </div>
          </div>
          <div style={menuFix}>
            <Scrollbars
              renderView={this.renderViewMenu}
              renderThumbHorizontal={this.renderThumb}
              renderThumbVertical={this.renderThumbMenu}
              renderTrackVertical={this.renderTrack}
              onUpdate={this.handleUpdate}
              className=""
            >
              <div className="nav navbar-nav">
                <div className="bc-bar p-0 text-left pl-3 d-flex align-items-center text-orange">
                  Ingelogd als: {this.props.username}
                </div>
                {!this.props.isSalesRep ? (
                  <React.Fragment>
                    <NavLink
                      to="/"
                      activeClassName="active"
                      exact={true}
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                    >
                      <i className="fal fa-home" />
                      HOME
                    </NavLink>
                    <NavLink
                      to="/producten"
                      activeClassName="active"
                      className={
                        this.props.showSubMenu
                          ? "nav-item p-0 text-left pl-3 position-relative showsub d-flex align-items-center"
                          : "nav-item p-0 text-left pl-3 position-relative d-flex align-items-center"
                      }
                      onClick={(e) => {
                        e.preventDefault();

                        let path = this.props.prevIDS;
                        let last;

                        if (path.length < 2) {
                          last = { clientID: 0, id: 0, label: "PRODUCTEN" };
                        } else {
                          last = path[path.length - 2];
                        }

                        this.props.navProductClick(
                          e,
                          last.clientID,
                          last.id,
                          last.label,
                          last.level,
                          last.fieldindex
                        );
                      }}
                    >
                      <i className="fal fa-bars" />
                      PRODUCTEN
                    </NavLink>
                    <NavLink
                      to="/aanbiedingen"
                      onClick={this.promoClick}
                      activeClassName="active"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                    >
                      <i className="fal fa-fire" />
                      AANBIEDINGEN
                    </NavLink>
                    <NavLink
                      to={"/nieuwe-producten"}
                      onClick={this.promoClick}
                      activeClassName="active"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                    >
                      <i className="fal fa-star" />
                      NIEUWE PRODUCTEN
                    </NavLink>

                    <NavLink
                      to="/bestellijsten"
                      activeClassName="active"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                      onClick={this.props.toggleOrderlists}
                    >
                      <i className="fal fa-list" />
                      BESTELLIJSTEN
                    </NavLink>
                    <NavLink
                      to="/orders"
                      activeClassName="active"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                    >
                      <i className="fal fa-truck" />
                      LEVERINGEN
                    </NavLink>
                    <NavLink
                      to="/retouren"
                      activeClassName="active"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                    >
                      <i className="fal fa-undo" />
                      RETOUREN
                    </NavLink>
                    <NavLink
                      to="/partners"
                      activeClassName="active"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                    >
                      <i className="fal fa-handshake-alt" />
                      PARTNERS
                    </NavLink>
                    {this.props.hasTD ? (
                      <NavLink
                        to="/technische-dienst"
                        activeClassName="active"
                        className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                      >
                        <i className="fal fa-toolbox" />
                        TECHNISCHE DIENST
                      </NavLink>
                    ) : (
                      ""
                    )}
                    <NavLink
                      to="/contact"
                      activeClassName="active"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                    >
                      <i className="fal fa-user-headset" />
                      CONTACT
                    </NavLink>
                    <a
                      href="#/"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                      onClick={this.props.logoutHandler}
                    >
                      <i className="fal fa-sign-out" />
                      LOGOUT
                    </a>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <NavLink
                      to="/hotels"
                      activeClassName="active"
                      exact={true}
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                    >
                      <i className="fal fa-home" />
                      HOME
                    </NavLink>
                    <a
                      href="#/"
                      className="nav-item p-0 text-left pl-3 d-flex align-items-center"
                      onClick={this.props.logoutHandler}
                    >
                      <i className="fal fa-sign-out" />
                      LOGOUT
                    </a>
                  </React.Fragment>
                )}
              </div>
            </Scrollbars>
          </div>
        </div>
        <div id="prodsub" className={this.props.showSubMenu ? "showsub" : ""}>
          <div className="cartheader d-flex">
            <div
              className="align-items-center d-flex p-3 text-left w-100 fakelink"
              onClick={this.props.navProductPrevious}
            >
              <div className="mr-3">
                <i className="far fa-chevron-left" />
              </div>
              <h6 className="mb-0 font-weight-bold">{this.props.grouplabel}</h6>
            </div>
            <div
              className="align-items-center d-flex py-3 px-4 border-left border-gray fakelink bg-white"
              onClick={this.closeNavProducts}
            >
              <i className="far fa-times" />
            </div>
          </div>
          {!this.props.groupLoading ? (
            <div className="sublist">
              <Scrollbars
                renderView={this.renderView}
                renderThumbHorizontal={this.renderThumb}
                renderThumbVertical={this.renderThumb}
                onUpdate={this.handleUpdate}
                className="sublistFix"
              >
                <div>
                  {this.props.groups.length > 0 &&
                  this.props.groups[0].level > 1 ? (
                    <NavLink
                      to="/#"
                      activeClassName="active"
                      className="nav-item all-products"
                      onClick={this.closeNavProducts}
                    >
                      Toon alle producten
                    </NavLink>
                  ) : null}
                  {this.props.groups.map((group) => {
                    return (
                      <NavLink
                        key={group.vestiging_id + "_" + group.id}
                        to={
                          "/producten/lijst/" +
                          group.id +
                          "/" +
                          slugify(group.site_label)
                        }
                        activeClassName="active"
                        className="nav-item"
                        onClick={(e) => {
                          this.props.navProductClick(
                            e,
                            group.vestiging_id,
                            group.id,
                            group.site_label,
                            parseInt(group.level) + 1,
                            group.fieldindex
                          );

                          if (parseInt(group.level) !== 0) {
                            this.props.addBreadcrumb([
                              {
                                name: group.site_label,
                                url:
                                  "/producten/lijst/" +
                                  group.id +
                                  "/" +
                                  slugify(group.site_label),
                                reset: false,
                                level: parseInt(group.level),
                              },
                            ]);
                            this.props.history.push(
                              "/producten/lijst/" +
                                group.id +
                                "/" +
                                slugify(group.site_label)
                            );
                          } else {
                            this.props.addBreadcrumb([
                              { name: "Producten", url: "", reset: true },
                              { name: group.site_label, url: "", reset: false },
                            ]);
                          }
                        }}
                      >
                        {group.site_label +
                          (group.cnt ? " (" + group.cnt + ")" : "")}
                      </NavLink>
                    );
                  })}
                  {this.props.groups.length > 0 &&
                  this.props.groups[0].level === 0 &&
                  this.props.hasTD ? (
                    <NavLink to="/technische-dienst">
                      Van der Valk Technische Dienst
                    </NavLink>
                  ) : (
                    ""
                  )}
                </div>
              </Scrollbars>
            </div>
          ) : (
            <div className="sublist">
              <div className="sublistFix">
                <br />
                <br />
                Laden...
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Sidemenu;
