export const isDev =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_VERCEL_ENV === "preview" ||
  process.env.REACT_APP_VERCEL_ENV === "development" ||
  process.env.VERCEL_ENV === "preview" ||
  process.env.VERCEL_ENV === "development";

export default {
  env: process.env.NODE_ENV,
  mode: process.env.MODE,
  ecBaseEndpoint: process.env.ECBASE_API_ENDPOINT,
  siteTitle: "A. Ruyken Handelsonderneming B.V.",
  seoDefault: "Ruyken",
  server_url: isDev
    ? "https://test.ecbase.nl/backoffice/api/"
    : "https://app.ecbase.nl/backoffice/api/", //change to app.ecbase.nl when files have been put live
  wmsApi: isDev
    ? "https://test.ecbase.nl/wmsapi"
    : "https://wmsapi.ecbase.nl",
  sites: isDev
    ? "https://sites-api-sandbox.ecbase.nl"
    : "https://sites-api.ecbase.nl",
};
