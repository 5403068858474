import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  CustomInput,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import settings from "../settings.js";

class Orderlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      listName: "",
      lists: [],
    };
    this.ac = this.ac.bind(this);
  }

  loadOrderlists() {
    const product = this.props.product;

    fetch(
      settings.server_url +
        "get_selected_orderlists/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        product.client_id_i +
        "/" +
        product.product_id +
        "/" +
        (parseInt(product.supplier_module_partner) === 1
          ? product.product_supplier_id
          : 0)
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ lists: response });
      })
      .catch((error) => console.error("Error:", error));
  }

  addToOrderlist(listID) {
    const product = this.props.product;

    fetch(
      settings.server_url +
        "set_add_to_orderlist/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        product.client_id_i +
        "/" +
        listID +
        "/" +
        product.product_id
    ).catch((error) => console.error("Error:", error));
  }

  removeToOrderlist(listID) {
    const product = this.props.product;

    fetch(
      settings.server_url +
        "set_remove_from_orderlist/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        listID +
        "/" +
        product.product_id
    ).catch((error) => console.error("Error:", error));
  }

  addOrderlist = (e) => {
    e.preventDefault();

    const product = this.props.product;
    if (this.state.listName > "") {
      fetch(
        settings.server_url +
          "set_add_orderlist/" +
          window.sessionStorage.getItem("sid") +
          "/" +
          product.client_id_i +
          "/" +
          this.state.listName +
          "/" +
          (parseInt(product.supplier_module_partner) === 1
            ? product.product_supplier_id
            : 0)
      )
        .then()
        .then((response) => {
          this.loadOrderlists();
          this.setState({ listName: "" });
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  listNameHandler = (e) => {
    this.setState({ listName: e.target.value });
  };

  set_orderlist = (index) => {
    let newlists = this.state.lists;
    if (newlists[index].bestellijst_id === null) {
      this.addToOrderlist(newlists[index].bestellijstgroup_id);
      newlists[index].bestellijst_id = 1;
    } else {
      this.removeToOrderlist(newlists[index].bestellijstgroup_id);
      newlists[index].bestellijst_id = null;
    }
    this.setState({ lists: newlists });
  };

  ac() {
    if (!this.state.modal) {
      this.loadOrderlists();
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  createMarkup(txt) {
    return { __html: txt };
  }

  render() {
    const product = this.props.product;

    let clientBadge;
    switch (product.client_id_i) {
      case 10:
        clientBadge = <span className="badge badge-success">KV</span>;
        break;
      case 11:
        clientBadge = <span className="badge badge-primary">DC</span>;
        break;
      case 29:
        clientBadge = <span className="badge badge-warning">AP</span>;
        break;
      case 54:
        clientBadge = <span className="badge badge-info">DV</span>;
        break;
      case 69:
        clientBadge = <span className="badge badge-danger">VM</span>;
        break;
      case 61:
        clientBadge = (
          <span
            className="badge badge-danger"
            style={{ backgroundColor: "lightblue" }}
          >
            VIS
          </span>
        );
        break;
      default:
        clientBadge = <span className="badge">?</span>;
    }

    return (
      <div className="float-left">
        <div className={"a-list " + this.props.className} onClick={this.ac}>
          <i className="far fa-list-ul" />
        </div>
        <Modal isOpen={this.state.modal} toggle={this.ac}>
          <ModalHeader className="product">
            <div className="phototitle">
              <div className="photo">
                <Link to={"/producten/details/" + product.product_id}>
                  {product.image > "" ? (
                    <img src={product.image} alt="" />
                  ) : (
                    <i className="far fa-image" />
                  )}
                </Link>
              </div>
              <div className="title px-2">
                <Link to={"/producten/details/" + product.product_id}>
                  <strong
                    dangerouslySetInnerHTML={this.createMarkup(
                      product.product_name
                    )}
                  />
                  <br />
                  {clientBadge} {product.product_unit} art.nr.{" "}
                  {product.product_ext_code}
                </Link>
                &nbsp;
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            {this.state.lists.map((list, index) => {
              return (
                <CustomInput
                  type="checkbox"
                  id={list.bestellijstgroup_id}
                  key={list.bestellijstgroup_id}
                  label={list.bestellijstgroup_name}
                  checked={list.bestellijst_id !== null}
                  className="orderlist-item"
                  onChange={this.set_orderlist.bind(this, index)}
                />
              );
            })}
            <Form onSubmit={this.addOrderlist}>
              <FormGroup className="mt-3 mb-0">
                <Label for="orderlist-input">Nieuwe bestellijst</Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="input"
                    className="form-control"
                    id="input-input"
                    placeholder="Nieuwe bestellijst"
                    value={this.state.listName}
                    onChange={this.listNameHandler}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="success">Opslaan</Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.ac}>
              Sluiten
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Orderlist;
