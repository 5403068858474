import React, { Component } from "react";
import Orderlist from "./components/orderlistmodal.js";
import Quote from "./components/quote.js";
import settings from "./settings.js";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isPSFoodLoading: true,
      data: [],
      psfooddata: false,
      stock: 0,
      weight: "0,000",
      totalWeight: 0,
      cartLoaded: false,
      lightboxOpen: false,
      photoIndex: 0,
      unitID: 0,
      unit: 0,
    };
  }

  componentDidMount() {
    this.props.searchHandler2("");
    this.props.setSearchClient(0);
    this.props.authenticate("/");

    if (this.props.cartLoaded) {
      this.loadData();
      this.loadPSFoodData();
    }
    this.props.closeMenu();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.cartLoaded !== prevState.cartLoaded) {
      return { cartLoaded: nextProps.cartLoaded };
    } else return null;
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.cartLoaded !== this.state.cartLoaded) {
      this.loadData();
      this.loadPSFoodData();
    }
  }

  loadData() {
    this.props.loading(true);
    fetch(
      settings.server_url +
        "get_product/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id
    )
      .then((res) => res.json())
      .then((response) => {
        const cartix = this.findCartIndexByID(
          response[0].product_id,
          this.props.cart
        );
        const quantity =
          cartix !== false ? this.props.cart[cartix].quantity : 0;

        let weight = 0;

        if (cartix !== false) {
          if (this.props.cart[cartix].weight) {
            weight = this.props.cart[cartix].weight;
          } else {
            weight = 0;
          }
        } else if (response[0].product_is_weight === 1) {
          weight = response[0].product_weight;
        }

        const isWeight = response[0].product_is_weight === 1 ? true : false;

        let tw = 0;
        if (isWeight) {
          tw = quantity * weight;
        }

        this.setState({
          data: response,
          quantity: quantity,
          weight: parseFloat(weight).toFixed(3).toString().replace(".", ","),
          isFixedWeight: response[0].fixed_weight
            ? parseInt(response[0].fixed_weight) === 1
            : response[0].product_weight > 0,
          totalWeight: tw.toFixed(3),
          isLoading: false,
          stock: response[0].product_stock_f - response[0].product_stock_r,
          unitID:
            response[0].units && response[0].units.length > 0
              ? parseInt(response[0].units[0].pu_id)
              : 0,
          unit:
            response[0].units && response[0].units.length > 0
              ? parseInt(response[0].units[0].pu_quantity)
              : 0,
        });
        this.props.addBreadcrumb([
          { name: response[0].product_name, url: "/products", reset: false },
        ]);
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  loadPSFoodData() {
    fetch(
      settings.server_url +
        "get_psinfood/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id
    )
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response && response.result !== false && response.length !== 0) {
          this.setState({ psfooddata: response, isPSFoodLoading: false });
        } else {
          this.setState({ psfooddata: false, isPSFoodLoading: false });
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  inc = (e, product) => {
    e.preventDefault();

    const w = parseFloat(this.state.weight.replace(",", "."));
    if (
      this.state.data[0].product_is_weight === 1 &&
      !this.state.isFixedWeight &&
      w === 0 &&
      this.state.quantity === 0
    ) {
      //this.openweight();
    } else {
      const q = this.state.quantity + Math.max(parseInt(this.state.unit), 1);

      const currentq =
        this.state.quantity + Math.max(parseInt(this.state.unit), 1);
      if (
        (parseInt(this.state.data[0].client_id_i) === 10 ||
          parseInt(this.state.data[0].client_id_i) === 11) &&
        parseInt(this.state.data[0].product_delivery_type) !== 1 &&
        parseInt(this.state.data[0].supplier_module_partner) !== 1
      ) {
        if (currentq < this.state.stock) {
          this.props.cartHandler(
            "INC",
            this.state.data[0],
            Math.max(parseInt(this.state.unit), 1),
            w,
            "",
            Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
          );
          //console.log("quantity", q);
          this.setState({
            quantity: q,
            totalWeight: (q * w).toFixed(3).toString().replace(",", "."),
          });
        } else {
          alert(
            "Er zijn niet meer dan " + this.state.stock + " stuks beschikbaar"
          );
        }
      } else {
        this.props.cartHandler(
          "INC",
          this.state.data[0],
          Math.max(parseInt(this.state.unit), 1),
          w,
          "",
          Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
        );
        //console.log("quantity", q);
        this.setState({
          quantity: q,
          totalWeight: (q * w).toFixed(3).toString().replace(",", "."),
        });
      }
    }
  };

  dec = (e, product) => {
    e.preventDefault();
    this.props.cartHandler(
      "DEC",
      this.state.data[0],
      Math.max(parseInt(this.state.unit), 1),
      this.state.weight,
      "",
      Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
    );
    const w = parseFloat(this.state.weight.replace(",", "."));

    if (this.state.quantity + Math.max(parseInt(this.state.unit), 1) > 0) {
      const q = this.state.quantity - Math.max(parseInt(this.state.unit), 1);
      this.setState({
        quantity: q,
        totalWeight: (q * w).toFixed(3).toString().replace(",", "."),
      });
    }
  };

  changeHandler = (e) => {
    //console.log(this.state.weight);
    const w = parseFloat(this.state.weight.replace(",", "."));

    if (!isNaN(parseInt(e.target.value))) {
      if (
        (parseInt(this.state.data[0].client_id_i) === 10 ||
          parseInt(this.state.data[0].client_id_i) === 11) &&
        parseInt(this.state.data[0].product_delivery_type) !== 1 &&
        parseInt(this.state.data[0].supplier_module_partner) !== 1
      ) {
        if (parseInt(e.target.value) > this.state.stock) {
          e.target.value = this.state.stock;
          alert(
            "Er zijn niet meer dan " + this.state.stock + " stuks beschikbaar"
          );
        }
      }

      this.setState({
        quantity: parseInt(e.target.value),
      });

      this.props.cartHandler(
        "UPDATE",
        this.state.data[0],
        parseInt(e.target.value),
        this.state.weight,
        "",
        Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
      );
    } else {
      this.props.cartHandler(
        "REMOVE",
        this.state.data[0],
        1,
        this.state.weight
      );
    }
    let q = parseInt(e.target.value);
    if (isNaN(q)) {
      q = 0;
      //console.log(q);
    }
    this.setState({
      totalWeight: (q * w).toFixed(3).toString().replace(",", "."),
    });
  };

  weightHandler = (e) => {
    e.preventDefault();

    this.setState({ weight: e.target.value });
  };

  weightBlurHandler = (e) => {
    e.preventDefault();

    let w = parseFloat(e.target.value.replace(",", ".")).toFixed(3);
    if (isNaN(w)) w = 0;
    this.setState({ weight: w.toString().replace(".", ",") });

    this.recalcWeights();
  };

  weightKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.totalweightInput.focus();
      this.totalweightInput.select();
    }
  };

  totalWeightHandler = (e) => {
    e.preventDefault();

    this.setState({ totalWeight: e.target.value, quantity: 0 });
  };

  totalWeightBlurHandler = (e) => {
    e.preventDefault();

    this.recalcWeights();
  };

  totalWeightKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.recalcWeights();
      this.closeweight(e);
    }
  };

  setUnit = (e) => {
    this.setState({ unit: e.target.value });
  };
  /*
  changeHandler = e => {
    if (!isNaN(parseInt(e.target.value))) {
      if (
        (parseInt(this.state.data[0].client_id_i) === 10 ||
          parseInt(this.state.data[0].client_id_i) === 11) &&
        parseInt(this.state.data[0].product_delivery_type) !== 1
      ) {
        if (parseInt(e.target.value) > this.state.stock) {
          e.target.value = this.state.stock;
          alert(
            "Er zijn niet meer dan " + this.state.stock + " stuks beschikbaar"
          );
        }
      }
      this.props.cartHandler(
        "UPDATE",
        this.state.data[0],
        parseInt(e.target.value)
      );
    } else {
      this.props.cartHandler("REMOVE", this.state.data[0]);
    }
  };

  weightHandler = e => {
    e.preventDefault();

    this.setState({ weight: e.target.value });
  };

  weightBlurHandler = e => {
    e.preventDefault();

    let w = parseFloat(e.target.value.replace(",", ".")).toFixed(3);
    if (isNaN(w)) w = 0;
    this.setState({ weight: w.toString().replace(".", ",") });

    this.recalcWeights();
  };

  weightKeyDownHandler = e => {
    if (e.key === "Enter") {
      this.totalweightInput.focus();
      this.totalweightInput.select();
    }
  };

  totalWeightHandler = e => {
    e.preventDefault();

    this.setState({ totalWeight: e.target.value });
  };

  totalWeightBlurHandler = e => {
    e.preventDefault();

    this.recalcWeights();
  };

  totalWeightKeyDownHandler = e => {
    if (e.key === "Enter") {
      this.recalcWeights();
      //this.closeweight(e);
    }
  };
  */

  recalcWeights() {
    let w = parseFloat(this.state.weight.toString().replace(",", "."));
    let tw = parseFloat(this.state.totalWeight.toString().replace(",", "."));
    let q = this.state.quantity;
    if (w > 0) {
      q = Math.ceil(tw / w);

      tw = q * w;
    }

    this.setState({
      quantity: q,
      totalWeight: tw.toFixed(3).toString().replace(".", ","),
    });

    this.props.cartHandler("UPDATE", this.state.data[0], q, w);
  }

  //componentWillMount() {}
  priceMarkup(x) {
    var parts = x.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  findCartIndexByID(id) {
    for (let i = 0; i < this.props.cart.length; i++) {
      if (this.props.cart[i].product_id === id) {
        return i;
      }
    }
    return false;
  }
  createMarkup(txt) {
    return { __html: txt };
  }

  render() {
    let stock = this.state.stock;
    let stockstyle = "";
    switch (true) {
      case stock >= 20:
        stockstyle = "float-none stock green ml-3";
        break;
      case stock > 1:
        stockstyle = "float-none stock orange ml-3";
        break;
      default:
        stockstyle = "float-none stock red ml-3";
    }
    if (!this.props.isAuthenticated || this.state.isLoading) return null;

    //if (this.state.cartLoaded) {
    const product = this.state.data[0];

    const cartix = this.findCartIndexByID(product.product_id, this.props.cart);
    const quantity = cartix !== false ? this.props.cart[cartix].quantity : 0;

    /*
    let weight = 0;
    //console.log(props.cart);
    if (cartix !== false) {
      if (this.props.cart[cartix].weight) {
        weight = this.props.cart[cartix].weight;
      } else {
        weight = 0;
      }
    } else if (product.product_is_weight === 1) {
      weight = parseFloat(product.product_weight)
        .toFixed(3)
        .toString()
        .replace(".", ",");
    }
    */

    const isWeight = product.product_is_weight === 1 ? true : false;

    /*let tw = 0;
    if (isWeight) {
      tw = quantity * weight;
    }
    */

    let special = "";
    if (product.Valkweb_label_s && product.Valkweb_label_s.trim() !== "") {
      special = (
        <span
          className="badge badge-warning"
          style={{
            position: "absolute",
            left: "5px",
            top: "5px",
            marginLeft: "20px",
          }}>
          {product.Valkweb_label_s.trim()}
        </span>
      );
    }

    let clientBadge;
    switch (product.client_id_i) {
      case 10:
        clientBadge = <span className="badge badge-success">KV</span>;
        break;
      case 11:
        clientBadge = <span className="badge badge-primary">DC</span>;
        break;
      case 29:
        clientBadge = <span className="badge badge-warning">AP</span>;
        break;
      case 54:
        clientBadge = <span className="badge badge-info">DV</span>;
        break;
      case 69:
        clientBadge = <span className="badge badge-danger">VM</span>;
        break;
      case 61:
        clientBadge = (
          <span
            className="badge badge-danger"
            style={{ backgroundColor: "lightblue" }}>
            VIS
          </span>
        );
        break;
      default:
        clientBadge = <span className="badge">?</span>;
    }

    let desc = null;
    if (
      product.product_description.trim() !== "" ||
      product.product_ingredienten_s.trim() !== ""
    ) {
      desc = (
        <div className="row border-bottom py-4">
          <div
            className="col-12"
            dangerouslySetInnerHTML={this.createMarkup(
              product.product_description
            )}></div>
          <div className="col-12">{product.product_ingredienten_s}</div>
        </div>
      );
    }

    let psinfood = null;
    if (this.state.isPSFoodLoading) {
      psinfood = (
        <div className="row py-4">
          <div className="col-12">Bezig met laden PS in Food...</div>
        </div>
      );
    } else if (
      this.state.psfooddata !== false &&
      this.state.psfooddata.specificationinfolist
    ) {
      //console.log(this.state.psfooddata);
      const specs =
        this.state.psfooddata.specificationinfolist.specificationinfo;

      psinfood = (
        <div className="row py-4">
          <div className="col-12 col-md-6">
            {specs.ingredientset.ingredientdeclaration ? (
              <React.Fragment>
                <h4>Ingredienten</h4>
                <span className="mb-4 d-block">
                  {specs.ingredientset.ingredientdeclaration.value}
                </span>
              </React.Fragment>
            ) : (
              ""
            )}

            <h4>Allergenen</h4>
            <span className="mb-4 d-block">
              <ul>
                {specs.allergenset.allergens.allergeninfo.map((al, index) => {
                  if (
                    parseInt(al.levelofcontainmentid) === 1 ||
                    parseInt(al.levelofcontainmentid) === 3
                  ) {
                    return (
                      <li key={index}>
                        {parseInt(al.levelofcontainmentid) === 1
                          ? "Bevat " + al.name.value
                          : parseInt(al.levelofcontainmentid) === 3
                          ? "Kan bevatten " + al.name.value
                          : ""}
                      </li>
                    );
                  } else return null;
                })}
              </ul>
              <em>
                {specs.allergenset.allergencomment
                  ? specs.allergenset.allergencomment.value
                  : ""}
              </em>
            </span>
          </div>
          <div className="col-12 col-md-6">
            {false && specs.nutrientset.nutrientinfolist ? (
              <React.Fragment>
                <h4>Voedingswaarde</h4>
                <div className="table-responsive">
                  <table className="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th className="border-0" />
                        <th className="border-0">
                          Per 100{" "}
                          {
                            specs.nutrientset.nutrientinfolist.nutrientinfo
                              .perhunderduomname.value
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {specs.nutrientset.nutrientinfolist.nutrientinfo.nutrients.nutrient.map(
                        (nut, nutix) => {
                          return (
                            <tr key={nutix}>
                              <td>{nut.name.value}</td>
                              <td>
                                {nut.measurementprecisionname.value ===
                                "APPROXIMATELY"
                                  ? "\u00B1"
                                  : ""}
                                {(Math.round(nut.value * 1000) / 1000)
                                  .toString()
                                  .replace(".", ",")}{" "}
                                {nut.unitofmeasurename.value}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="row ml-0 content-bc text-left">
        <div className="col p-md-4 px-0">
          <div className="row p-2 mb-3">
            <div className="col maxw1200">
              <div className="content-block px-3 py-4">
                <div className="row border-bottom pb-4">
                  <div className="col-lg-4">
                    {special}
                    <img
                      src={product.images[0]}
                      onClick={() => this.setState({ lightboxOpen: true })}
                      alt=""
                      className="img-fluid prodimg"
                    />
                    {product.images.map((img, index) => {
                      return (
                        <img
                          key={img + index}
                          src={img}
                          width="48"
                          onClick={() =>
                            this.setState({
                              photoIndex: index,
                              lightboxOpen: true,
                            })
                          }
                          alt=""
                          className="border p-1 float-left m-2 prodimg"
                        />
                      );
                    })}
                    {this.state.lightboxOpen && (
                      <Lightbox
                        mainSrc={product.images[this.state.photoIndex]}
                        nextSrc={product.images[this.state.photoIndex + 1]}
                        prevSrc={product.images[this.state.photoIndex - 1]}
                        onCloseRequest={() =>
                          this.setState({ lightboxOpen: false })
                        }
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex:
                              (this.state.photoIndex +
                                product.images.length -
                                1) %
                              product.images.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex:
                              (this.state.photoIndex + 1) %
                              product.images.length,
                          })
                        }
                      />
                    )}
                  </div>
                  <div className="col-lg-8">
                    <h2
                      dangerouslySetInnerHTML={this.createMarkup(
                        product.product_name
                      )}
                    />
                    <div className="priceSpan">
                      <span className="d-flex align-items-center">
                        <span className="mr-3">{clientBadge}</span>
                        <small className="d-flex align-items-center mr-3">
                          <i className="stockbutton" />
                          {parseInt(product.product_delivery_type) === 1 ? (
                            <span className="font-weight-bold text-danger">
                              Bestelartikel
                            </span>
                          ) : (
                            <span className="font-weight-bold">
                              Voorraadartikel
                            </span>
                          )}
                          {(parseInt(product.client_id_i) === 10 ||
                            parseInt(product.client_id_i) === 11) &&
                          parseInt(product.product_delivery_type) !== 1 &&
                          parseInt(
                            this.state.data[0].supplier_module_partner
                          ) !== 1 ? (
                            <div className={stockstyle} />
                          ) : null}
                        </small>

                        <span className="text-muted small mr-1">€</span>

                        <span className="font-weight-bold mr-3">
                          {this.priceMarkup(product.calcprice)}
                        </span>
                      </span>
                    </div>
                    <div className="cartprice mt-2 clearfix">
                      <div className="cart-buttons d-inline-block float-right float-md-left d-inline-flex unit">
                        {product.Tijdelijk_niet_leverbaar_s &&
                        parseInt(product.Tijdelijk_niet_leverbaar_s) === 1 ? (
                          <React.Fragment>
                            {product.Tekst_Niet_leverbaar_s &&
                            product.Tekst_Niet_leverbaar_s > "" ? (
                              <span className="text-dimgrey">
                                {product.Tekst_Niet_leverbaar_s}
                              </span>
                            ) : (
                              <span className=" text-dimgrey">
                                Tijdelijk niet leverbaar
                              </span>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span className="text-muted text-uppercase mr-3">
                              <select
                                defaultValue={this.state.unit}
                                onChange={this.setUnit}>
                                {product.units &&
                                product.units.length > 0 &&
                                !isWeight ? (
                                  product.units.map((unit) => {
                                    return (
                                      <option
                                        key={unit.pu_id}
                                        value={
                                          unit.pu_quantity
                                        }>{`${unit.unit_desc} (${unit.pu_quantity})`}</option>
                                    );
                                  })
                                ) : (
                                  <option>
                                    {isWeight &&
                                    parseFloat(product.product_weight) !== 0
                                      ? parseFloat(product.product_weight)
                                          .toString()
                                          .replace(".", ",") + "KILO"
                                      : product.product_unit !== ""
                                      ? product.product_unit
                                      : "STUK"}
                                  </option>
                                )}
                              </select>
                            </span>
                            <Orderlist className="mr-2" product={product} />
                            <div className="align-items-center d-inline-flex">
                              <React.Fragment>
                                {product.Offerte_s != 1 ? (
                                  <>
                                    <a
                                      href="#"
                                      className={
                                        quantity > 0
                                          ? "a-min active mt-0"
                                          : "a-min mt-0"
                                      }
                                      disabled={quantity === 0}
                                      onClick={(e, product) => {
                                        this.dec(e, product);
                                      }}>
                                      -
                                    </a>
                                    <input
                                      type="text"
                                      className={
                                        quantity > 0
                                          ? "input-cart mt-0 active"
                                          : "input-cart mt-0"
                                      }
                                      onChange={this.changeHandler}
                                      placeholder="0"
                                      value={quantity}
                                      onClick={() => {
                                        this.mainQty.select();
                                      }}
                                      ref={(mainQty) => {
                                        this.mainQty = mainQty;
                                      }}
                                    />
                                    <a
                                      href="#"
                                      className={
                                        quantity > 0
                                          ? "a-plus active mt-0"
                                          : "a-plus mt-0"
                                      }
                                      onClick={(e, product) => {
                                        this.inc(e, product);
                                      }}>
                                      +
                                    </a>
                                  </>
                                ) : (
                                  <Quote
                                    id={product.product_id}
                                    name={product.product_name}
                                    username={this.props.username}
                                  />
                                )}
                              </React.Fragment>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    {isWeight !== false ? (
                      <div className="cartprice mt-2 ">
                        <div className="cart-buttons d-inline-block float-right float-md-left d-inline-flex">
                          <table className="weighttable">
                            <tbody>
                              <tr className="text-center">
                                <th className="">Aantal</th>
                                <th className="" />
                                <th className="">Gewicht (KG)</th>
                                <th>Totaal</th>
                              </tr>
                              <tr>
                                <td className="weightinput">
                                  <input
                                    type="text"
                                    onChange={this.changeHandler}
                                    placeholder="0"
                                    value={quantity}
                                    className="d-inline form-control text-center ml-0"
                                    onClick={(e) => {
                                      this.qtyInput.select();
                                    }}
                                    ref={(qtyInput) => {
                                      this.qtyInput = qtyInput;
                                    }}
                                  />
                                </td>
                                <td>X</td>
                                <td className="weightinput">
                                  <input
                                    type="text"
                                    className="d-inline form-control text-right"
                                    value={isWeight ? this.state.weight : 0}
                                    disabled={
                                      isWeight && this.state.isFixedWeight
                                    }
                                    onClick={(e) => {
                                      this.weightInput.select();
                                    }}
                                    onChange={(e) => {
                                      this.weightHandler(e);
                                    }}
                                    onBlur={(e) => {
                                      this.weightBlurHandler(e);
                                    }}
                                    onKeyDown={this.weightKeyDownHandler}
                                    ref={(weightInput) => {
                                      this.weightInput = weightInput;
                                    }}
                                  />
                                </td>
                                <td className="weightinput">
                                  <input
                                    type="text"
                                    className="d-inline form-control text-right"
                                    value={
                                      isWeight ? this.state.totalWeight : 0
                                    }
                                    disabled={!isWeight}
                                    onClick={(e) => {
                                      this.totalweightInput.select();
                                    }}
                                    onChange={(e) => {
                                      this.totalWeightHandler(e);
                                    }}
                                    onBlur={(e) => {
                                      this.totalWeightBlurHandler(e);
                                    }}
                                    onKeyDown={this.totalWeightKeyDownHandler}
                                    ref={(totalweightInput) => {
                                      this.totalweightInput = totalweightInput;
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {desc}
                {product.pdfs.length > 0 ? (
                  <div className="row border-bottom py-4">
                    <div className="col-12">
                      <strong>Downloads</strong>
                      <br />
                      {product.pdfs.map((pdf, i) => {
                        return (
                          <>
                            <a
                              href={pdf.url}
                              key={i}
                              rel="noopener noreferrer"
                              target="_blank">
                              {pdf.name}
                            </a>
                            <br />
                          </>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row border-bottom py-4">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-sm table-borderless text-left w-auto">
                        <tbody>
                          <tr>
                            <td>Artikelnummer:</td>
                            <td className="font-weight-bold">
                              {product.product_ext_code}
                            </td>
                          </tr>
                          <tr>
                            <td>EAN:</td>
                            <td className="font-weight-bold">
                              {product.product_barcode_s}
                            </td>
                          </tr>
                          <tr>
                            <td>Inhoud:</td>
                            <td className="font-weight-bold">
                              {product.product_unit}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {psinfood}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    //} else return null;
  }
}

export default ProductDetail;
