import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import settings from "./settings.js";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: 0,
      productID: 0,
      productName: "",
      quantity: 1,
      reason: 0,
      comments: "",
      errors: "",
      productData: [],
      showConfirmation: false,
      content: "",
    };

    //this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  postData() {
    this.props.loading(false);

    fetch(
      settings.server_url +
        "get_partners/" +
        window.sessionStorage.getItem("sid"),
      {
        method: "POST",
        body: JSON.stringify({
          code: "contactpagina-valkweb",
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  UNSAFE_componentWillMount() {
    this.props.authenticate("/");
  }

  componentDidMount() {
    this.props.searchHandler2("");
    this.props.setSearchClient(0);
    this.loadData();
    this.props.addBreadcrumb([
      { name: "Contact", url: "/contact", reset: true },
    ]);
    this.props.closeMenu();

    this.validateForm();
  }

  loadData() {
    this.props.loading(false);
    fetch(
      settings.server_url +
        "get_content_page/" +
        window.sessionStorage.getItem("sid"),
      {
        method: "POST",
        body: JSON.stringify({
          code: "contactpagina-valkweb",
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ content: response });

        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  renderView({ style, ...props }) {
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  inputChangeHandler = (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case "input-client":
        this.setState(
          { client: e.target.value, productName: "", productID: 0 },
          this.validateForm
        );
        break;
      case "input-product":
        this.setState({ productName: e.target.value }, this.searchProducts);
        break;
      case "input-quantity":
        this.setState({ quantity: e.target.value }, this.validateForm);
        break;
      case "input-reason":
        this.setState({ reason: e.target.value }, this.validateForm);
        break;
      case "input-comment":
        this.setState({ comments: e.target.value }, this.validateForm);
        break;
      default:
    }
  };

  validateForm() {
    let err = "";
    if (parseInt(this.state.client) === 0) {
      err += "Er is geen afdeling gekozen\r\n";
    }
    if (parseInt(this.state.reason) === 0) {
      err += "Er is geen reden gekozen<br>";
    }
    this.setState({ errors: err });
  }

  submitForm = (e) => {
    e.preventDefault();
    fetch(
      settings.server_url +
        "set_send_retour/" +
        window.sessionStorage.getItem("sid"),
      {
        method: "POST",
        body: JSON.stringify({
          clientID: this.state.client,
          productID: this.state.productID,
          productName: this.state.productName,
          quantity: this.state.quantity,
          reason: this.state.reason,
          comments: this.state.comments,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.result === true) {
          this.props.addBreadcrumb("Melding verstuurd", "/contact", false);
          this.setState({ showConfirmation: true });
        } else {
          alert("Er is iets fout gegaan");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  render() {
    if (!this.props.isAuthenticated) return null;
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        /*onUpdate={this.handleUpdate}*/
        className="row ml-0 content-bc text-left"
      >
        <div className="col p-md-4 px-0 maxw1200">
          <div className="p-lg-2 row">
            <div className="col mr-lg-3">
              <div className="content-block p-3 mb-4">
                <h3 className="mb-4">Contact</h3>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.content.length > 0
                        ? this.state.content[0].page_content
                        : "",
                  }}
                ></span>
              </div>
              {/*
              {!this.state.showConfirmation ? (
                <div className="content-block p-3">
                  <h3 className="mb-4">Stel direct een vraag</h3>

                  <form onSubmit={this.submitForm}>
                    <div className="form-group">
                      <label htmlFor="input-client">Kies een afdeling</label>
                      <select
                        className={
                          parseInt(this.state.client) !== 0
                            ? "form-control is-valid "
                            : "form-control"
                        }
                        id="input-client"
                        value={this.state.client}
                        onChange={this.inputChangeHandler}
                      >
                        <option value="0">Kies een afdeling</option>
                        <option value="10">Van der Valk Koel & Vries</option>
                        <option value="11">
                          Van der Valk Centraal Magazijn
                        </option>
                        <option value="54">Van der Valk Dagvers</option>
                        <option value="69">Van der Valk Versmarkt</option>
                        <option value="29">Van der Valk Apparaten</option>
                        <option value="61">Van der Valk Vis</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-reason">Reden</label>
                      <select
                        className={
                          parseInt(this.state.reason) !== 0
                            ? "form-control is-valid "
                            : "form-control  "
                        }
                        id="input-reason"
                        value={this.state.reason}
                        onChange={this.inputChangeHandler}
                      >
                        <option value="0">Kies een reden</option>
                        <option value="1">Verkeerd product geleverd</option>
                        <option value="2">Verkeerd aantal geleverd</option>
                        <option value="3">Verkeerd besteld</option>
                        <option value="4">Product over datum</option>
                        <option value="5">Beschadigd product</option>
                        <option value="6">Anders, geef aan in opmerking</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-comment">Uw vraag</label>
                      <textarea
                        className="form-control"
                        id="input-comment"
                        autoComplete="new-password"
                        rows="3"
                        value={this.state.comments}
                        onChange={this.inputChangeHandler}
                      />
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        disabled={this.state.errors !== ""}
                        className="btn btn-primary"
                      >
                        Verzenden
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="col content-block ml-4 mr-3 p-lg-3">
                  <h3 className="mb-4">Uw melding is verstuurd</h3>
                </div>
              )}
              */}
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default Contact;
