import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";

import Product from "./components/product.js";
import InfiniteScroll from "react-infinite-scroller";
import settings from "./settings.js";
import { debounce } from "lodash";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hasMoreItems: true,
      data: this.props.productsData,
      page: this.props.productsPage,
      scrollY: this.props.productsScrollY,
      pageLoading: false,
      searchString: this.props.searchString,
      productGroupID: this.props.productGroupID,
      searchClient: this.props.searchClient,
      pathname: this.props.location.pathname,
    };
    this.props.loading(true);
    this.props.authenticate("/");
    this.scrollTarget = React.createRef();

    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);

    this.scrollEl = React.createRef();

    /** revert to scroll position & setup listener for scroll */
    this.interval = setInterval(() => {
      const el = document.querySelector("#scroll-content .box");
      if (el) {
        this.scrollEl.current = el;
        if (this.state.data.length > 0) {
          clearInterval(this.interval);

          this.scrollEl.current.scroll({
            top: this.state.scrollY,
            behaviour: "smooth",
          });
          this.props.loading(false);
        }

        /** save scroll position */
        this.scrollEl.current.addEventListener(
          "scroll",
          debounce((event) => {
            this.props.updateProductsData({
              data: this.state.data,
              page: this.state.page,
              scrollY: this.scrollEl.current.scrollTop,
            });
          }, 200)
        );
      }
    }, 300);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidMount() {
    if (this.props.location.pathname === "/aanbiedingen") {
      this.props.searchHandler2("");
      this.props.searchHandler("");
      this.props.setSearchClient(0);
      this.props.addBreadcrumb([
        { name: "Aanbiedingen", url: "/aanbiedingen", reset: true },
      ]);
    } else if (this.props.location.pathname === "/nieuwe-producten") {
      this.props.searchHandler2("");
      this.props.searchHandler("");
      this.props.setSearchClient(0);
      this.props.addBreadcrumb([
        { name: "Nieuwe producten", url: "/nieuwe-producten", reset: true },
      ]);
    } else if (this.state.searchString !== "") {
      this.props.addBreadcrumb([
        { name: "Producten", url: "", reset: true },

        { name: "Zoeken", url: "", reset: false },
        {
          name: this.state.searchString,
          url: "/producten/zoeken/" + this.state.searchString,
          reset: false,
        },
      ]);
    } else if (this.props.match.path === "/producten/zoeken/:val") {
      //console.log("zoeken");
      this.props.searchHandler2(this.props.match.params.val);
      this.props.addBreadcrumb([
        { name: "Producten", url: "", reset: true },

        { name: "Zoeken", url: "", reset: false },
        {
          name: this.props.match.params.val,
          url: "/producten/zoeken/" + this.props.match.params.val,
          reset: false,
        },
        { name: "", url: "", reset: false },
      ]);
      this.props.searchHandler(this.props.match.params.val);

      //this.props.addBreadcrumb([{ name: "Producten", url: "", reset: true }]);
    }
  }
  loadData(reset) {
    let page = 0;
    if (reset) {
      this.setState({ data: [], hasMoreItems: true, page: 0 });
    } else {
      page = this.state.page;
    }

    let search = this.props.searchString2;
    if (this.props.match.path === "/producten/zoeken/:val") {
      search = this.props.match.params.val;
    }

    const loadpromo = this.props.location.pathname === "/aanbiedingen";
    const loadnew = this.props.location.pathname === "/nieuwe-producten";

    if (!this.state.pageLoading) {
      //console.log(this.state, this.props);
      this.setState({ pageLoading: true });
      fetch(
        settings.server_url +
          "get_products/" +
          window.sessionStorage.getItem("sid"),
        {
          method: "POST",
          body: JSON.stringify({
            promo: loadpromo,
            new: loadnew,
            search: search,
            searchClient: this.props.searchClient,
            groupID: this.props.match.params.id1,
            page: page,
            sessionID: 10,
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          let products = [...this.state.data, ...response];
          this.setState({ data: products, page: page + 1, pageLoading: false });
          this.props.updateProductsData({
            data: products,
            page: page + 1,
            scrollY: this.scrollEl.current.scrollTop,
          });
          this.props.loading(false);
          if (response.length === 0) {
            this.setState({ hasMoreItems: false });
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      prevState.searchString !== this.state.searchString ||
      prevState.searchClient !== this.state.searchClient ||
      prevState.productGroupID !== this.state.productGroupID ||
      prevState.pathname !== this.state.pathname
    ) {
      if (this.state.searchString !== "") {
        this.props.addBreadcrumb([
          { name: "Producten", url: "", reset: true },
          { name: "Zoeken", url: "", reset: false },
          {
            name: this.props.searchString2,
            url: "/producten/zoeken/" + this.props.searchString2,
            reset: false,
          },
        ]);
      } else if (this.state.pathname === "/aanbiedingen") {
        this.props.addBreadcrumb([
          { name: "Aanbiedingen", url: "/aanbiedingen", reset: true },
        ]);
      } else if (this.state.pathname === "/nieuwe-producten") {
        this.props.addBreadcrumb([
          { name: "Nieuwe producten", url: "/nieuwe-producten", reset: true },
        ]);
      }
      //this.props.closeMenu();

      this.loadData(true);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchString !== prevState.searchString) {
      return { searchString: nextProps.searchString };
    } else if (nextProps.searchClient !== prevState.searchClient) {
      return { searchClient: nextProps.searchClient };
    } else if (
      nextProps.productGroupID !== prevState.productGroupID &&
      nextProps.productGroupID !== 0
    ) {
      return { productGroupID: nextProps.productGroupID };
    } else if (nextProps.location.pathname !== prevState.pathname) {
      return { pathname: nextProps.location.pathname };
    } else return null;
  }

  renderView({ style, ...props }) {
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  handleUpdate(values) {}

  render() {
    if (!this.props.isAuthenticated) return null;
    let results;
    if (this.state.data.length > 0) {
      results = this.state.data.map((product) => {
        return (
          <Product
            key={product.product_id}
            history={this.props.history}
            product={product}
            cart={this.props.cart}
            cartHandler={this.props.cartHandler}
            scrollPos={window.scrollTop}
            showListButton={true}
            username={this.props.username}
          />
        );
      });
    } else if (this.state.data.length === 0 && !this.state.pageLoading) {
      results = (
        <div className="p-3">
          <strong>Er zijn geen resultaten gevonden</strong>
        </div>
      );
    } else if (this.state.data.length === 0 && this.state.pageLoading) {
      results = (
        <div className="p-3">
          <strong>Bezig met laden...</strong>
        </div>
      );
    }
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        /*onUpdate={this.handleUpdate}*/
        id="scroll-content"
        className="row content-bc ml-0"
      >
        <InfiniteScroll
          id="scroll-container"
          style={{ scrollTop: this.scrollY }}
          pageStart={0}
          threshold={65}
          initialLoad={this.props.productsData.length === 0}
          loadMore={() => {
            this.loadData(false);
          }}
          hasMore={this.state.hasMoreItems}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          useWindow={false}
          className="col p-md-4 px-0 maxw1200"
        >
          <Row className="p-lg-2">
            <Col>
              <div className="content-block">{results}</div>
            </Col>
          </Row>
        </InfiniteScroll>
      </Scrollbars>
    );
  }
}

export default Products;
