import React from "react";
import ReactDOM from "react-dom";

//import * as Sentry from "@sentry/react";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

/**
 * Sentry.init({
  dsn: "https://9f8901147c8d443e9a8ccb900bb64193@perf.ecbase.app/22",
});
 */

ReactDOM.render(<App />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
