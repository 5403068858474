import React, { Component } from "react";
import ClearCache from "react-clear-cache";
import { Container, Row, Col } from "reactstrap";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { hotjar } from "react-hotjar";
import initReactFastclick from "react-fastclick";
import { Offline } from "react-detect-offline";
import * as Sentry from "@sentry/react";
import Header from "./components/header.js";
import Breadcrumbs from "./components/breadcrumbs.js";
import Sidemenu from "./components/sidemenu.js";
import Cart from "./components/cart.js";
import Orderlists from "./components/orderlists.js";
import Home from "./home.js";
import Products from "./products.js";
import ProductDetail from "./productdetail.js";
import Login from "./components/login";
import Partners from "./partners.js";
import TD from "./td.js";
import TDDetails from "./td-details.js";
import PartnerDetails from "./partnerdetails.js";
import SupplierDetails from "./supplierdetails.js";
import Bestellijsten from "./bestellijsten";
import Checkout from "./checkout";
import orders from "./orders";
import retouren from "./retouren";
import Hotels from "./hotels";
import HotelProducts from "./hotelproducts";
import Nieuws from "./nieuws";
//import Vervangend from "./vervangend";

import Contact from "./contact";

import settings, { isDev } from "./settings.js";

import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-pro/css/all.css";
import "./App.css";
import loader from "./assets/Eclipse-1s-200px.apng";
import { RecoilRoot } from "recoil";

initReactFastclick();

class App extends Component {
  state = {
    cnt: 1,
    isLoading: false,
    isLoggedIn: false,
    isAuthenticated: false,
    hasTD: false,
    sessionID: "",
    loginIncorrect: false,
    username: "",
    showCart: false,
    showOrderlist: false,

    productsData: [],
    productsPage: 0,
    productsScrollY: 0,

    orderlists: [],
    orderListPage: 0,
    orderListData: [],
    orderListScrollY: 0,

    searchString: "",
    searchString2: "",
    searchClient: 0,
    searchClientText: "Zoek in alle afdelingen",
    cart: [],
    cartLoaded: false,

    groups: [],
    prevIDS: [],
    showSubMenu: false,
    productGroupID: 0,
    tempProductGroupID: 0,
    groupLoading: true,
    grouplabel: "PRODUCTEN",
    breadcrumbPath: [],
    currentProductGroup: {
      clientID: 0,
      id: 0,
      label: "PRODUCTEN",
      level: 0,
    },
    isSalesRep: false,
    activeSalesRepCustomerID: 0,
  };

  UNSAFE_componentWillMount() {
    this.setState({ isAuthenticated: false });
    let sid = sessionStorage.getItem("sid");
    let user = sessionStorage.getItem("username");
    if (sid != null) {
      this.setState({ isLoggedIn: true, username: user });
      this.loadCart();
    }
  }

  componentDidMount() {
    if (this.state.isLoggedIn) {
      this.loadOrderlists();
    }
  }

  /** clear out global orderlistData state if not on productpage or orderlist page */
  componentDidUpdate() {
    if (
      !window.location.pathname.includes("/producten/details") &&
      !window.location.pathname.includes("/bestellijsten") &&
      this.state.orderListData.length > 0
    ) {
      console.log("NOT ON PRODUCT/ORDERLIST PAGE -> CLEAR ORDERLIST STATE");
      this.setState({
        orderListData: [],
        orderListPage: 0,
        orderListScrollY: 0,
      });
    }

    if (
      !window.location.pathname.includes("/producten/details") &&
      !window.location.pathname.includes("/producten/lijst") &&
      !window.location.pathname.includes("/aanbiedingen") &&
      !window.location.pathname.includes("/nieuwe-producten") &&
      this.state.productsData.length > 0
    ) {
      console.log("NOT ON PRODUCT(S) PAGE -> CLEAR PRODUCTS STATE");
      this.setState({
        productsData: [],
        productsPage: 0,
        productsScrollY: 0,
      });
    }
  }

  closeMenu = () => {
    this.setState({ showMenu: false, showSubMenu: false });
  };

  loadCart = () => {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url + "get_cart/" + window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        //  console.log("cart response", response);
        if (response.result !== false) {
          this.setState({ cart: response, isLoading: false, cartLoaded: true });
        } else {
          this.setState({ cart: [], isLoading: false, cartLoaded: true });
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  loading = (setLoading) => {
    this.setState({ isLoading: setLoading });
  };

  toggleCart = (e) => {
    e.preventDefault();
    let showcart = !this.state.showCart;
    this.setState({ showCart: showcart });
  };

  toggleOrderlists = (e) => {
    e.preventDefault();
    let showOrderlist = !this.state.showOrderlist;
    this.setState({ showOrderlist: showOrderlist, showCart: false });
  };

  closeOrderlists = (e) => {
    this.setState({ showOrderlist: false });
  };

  toggleMenu = (e) => {
    e.preventDefault();
    let showmenu = !this.state.showMenu;
    this.setState({ showMenu: showmenu, showSubMenu: false });
  };

  logoutHandler = (e) => {
    e.preventDefault();
    if (window.confirm("Weet u zeker dat u wilt uitloggen?")) {
      window.sessionStorage.clear();
      this.setState({ isLoading: false, isLoggedIn: false });
      document.location = "/login";
    }
  };

  loginHandler = (props, username, password, type) => {
    this.setState({ isLoading: true });

    fetch(settings.server_url + "login", {
      method: "POST",
      body: JSON.stringify({
        clientID: 10,
        username: username,
        password: password,
        type: type,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          //console.log(response);
          window.sessionStorage.setItem("sid", response.sid);
          window.sessionStorage.setItem("username", response.name);
          if (response.salesrep) {
            this.setState(
              {
                username: response.name,
                isSalesRep: true,
                hasTD: response.hasTD,
                isLoading: false,
                isLoggedIn: true,
                supplierID: response.supplier,
                customers: response.customers,
              },
              () => {
                this.loadCart();
                this.loadOrderlists();
                props.history.push("/hotels");
              }
            );
          } else {
            this.setState(
              {
                username: response.name,
                isSalesRep: false,
                isLoading: false,
                isLoggedIn: true,
              },
              () => {
                this.loadCart();
                this.loadOrderlists();
              }
            );
            props.history.push("/");
          }
        } else {
          this.setState({
            isLoading: false,
            isLoggedIn: false,
            loginIncorrect: true,
          });
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  authenticate = (route) => {
    fetch(
      settings.server_url +
        "authenticate/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          this.setState({
            isAuthenticated: true,
            isSalesRep: response.isSalesRep,
            hasTD: response.hasTD,
          });
        } else {
          /*
          this.setState({
            isAuthenticated: false,
            isLoggedIn: false
          });
          */
          document.location = "/login";
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  searchHandler = (value) => {
    //console.log("zoek", value);
    this.setState({ searchString: this.state.searchString2 });
  };

  searchHandler2 = (value) => {
    this.setState({ searchString2: value });
  };

  setSearchClient = (value) => {
    switch (parseInt(value)) {
      case 10:
        this.setState({
          searchClientText: "Van der Valk Koel & Vries",
          searchClient: value,
        });
        break;
      case 54:
        this.setState({
          searchClientText: "Van der Valk Dagvers",
          searchClient: value,
        });
        break;
      case 11:
        this.setState({
          searchClientText: "Van der Valk Centraal Magazijn",
          searchClient: value,
        });
        break;
      case 69:
        this.setState({
          searchClientText: "Van der Valk Versmarkt",
          searchClient: value,
        });
        break;
      case 29:
        this.setState({
          searchClientText: "Van der Valk Apparaten",
          searchClient: value,
        });
        break;
      case 61:
        this.setState({
          searchClientText: "Van der Valk Vis",
          searchClient: value,
        });
        break;
      default:
        this.setState({
          searchClientText: "Zoek in alle afdelingen",
          searchClient: 0,
        });
    }
  };

  clearSearch = () => {
    this.setState({
      searchString: "",
      searchString2: "",
      searchClient: 0,
      searchClientText: "Zoek in alle afdelingen",
    });
  };

  navProductClickOpen = (e) => {
    e.preventDefault();
    let showSubMenu = true;
    this.setState({ showSubMenu: showSubMenu });
  };

  closeNavProducts = (e) => {
    let showSubMenu = false;
    this.setState({ showSubMenu: showSubMenu });
  };

  setProductgroupHandler = (id) => {
    this.setState({ productGroupID: id });
  };

  addBreadcrumb = (path) => {
    //console.log(path);
    let bc = this.state.breadcrumbPath;

    if (path.length > 0) {
      path.map((p) => {
        if (p.reset) {
          bc = [];
        } else if (p.level && p.level > 0) {
          bc.splice(p.level + 1);
        }

        if (p.name !== "") {
          bc.push({ name: p.name, url: p.url, ...p });
        }
        return true;
      });
    }

    this.setState({ breadcrumbPath: bc });
  };

  findCartIndexByID(id) {
    for (let i = 0; i < this.state.cart.length; i++) {
      if (parseInt(this.state.cart[i].product_id) === parseInt(id)) {
        return i;
      }
    }
    return false;
  }

  findCartIndexByListID(id) {
    for (let i = 0; i < this.state.cart.length; i++) {
      if (parseInt(this.state.cart[i].bestellijst_id) === parseInt(id)) {
        return i;
      }
    }
    return false;
  }

  updateServerCart(action, index) {
    // console.log(this.state.cart);

    fetch(
      settings.server_url +
        "updatecart/" +
        window.sessionStorage.getItem("sid"),
      {
        method: "POST",
        body: JSON.stringify({
          action: action,
          item: this.state.cart[index],
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          //let tempcart = this.state.cart;
          //console.log(response);
          //tempcart[index] = response.item;
          this.setState({ cart: response.cart }, () => {
            //console.log(this.state.cart);
          });
          //this.setLoading(false);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  cartHandler = (action, product, qty, weight, comment, unitid) => {
    if (weight !== undefined && weight != null) {
      weight = parseFloat(weight.toString().replace(",", "."));
    }
    let cart = this.state.cart;

    let listItemID = false;

    if (
      product &&
      typeof product.bestellijst_id !== "undefined" &&
      parseInt(product.bestellijst_id) > 0
    ) {
      listItemID = product.bestellijst_id;
    }

    let cartIndex = -1;
    if (product) {
      if (listItemID !== false) {
        cartIndex = this.findCartIndexByListID(listItemID);
      } else {
        cartIndex = this.findCartIndexByID(product.product_id);
      }
    }

    switch (action) {
      case "INC":
        if (cartIndex === false) {
          const cartItems = {
            quantity: qty,
            comment: comment,
            weight: weight,
            price: 0,
            unit: unitid,
            clientID: product.client_id_i,
            product_id: product.product_id,
            product_name:
              product.product_name_sort > ""
                ? product.product_name_sort
                : product.product_name,
            product_unit: product.product_unit,
            product_ext_code: product.product_ext_code,
            calcprice: product.calcprice,
            image: product.image,
            bestellijst_id: listItemID,
            partnerID:
              parseInt(product.supplier_module_partner) === 1
                ? parseInt(product.product_supplier_id)
                : 0,
          };

          //console.log(cartItems);
          cart.push(cartItems);
        } else {
          cart[cartIndex].quantity += qty;
        }
        this.setState({ cart: cart });
        break;
      case "DEC":
        if (cartIndex !== false && cart[cartIndex].quantity - qty >= 0) {
          cart[cartIndex].quantity -= qty;
          //if (cart[cartIndex].quantity < 1) {
          //  cart.splice(cartIndex, 1);
          //}
          //this.setState({ cart: cart });
        }
        break;
      case "REMOVE":
        if (cartIndex !== false) {
          cart[cartIndex].quantity = 0;
        }

        break;
      case "UPDATE":
        //console.log(weight);
        if (cartIndex === false) {
          const cartItems = {
            quantity: qty,
            weight: weight,
            comment: comment,
            unit: unitid,
            price: 0,
            clientID: product.client_id_i,
            product_id: product.product_id,
            product_name:
              product.product_name_sort > ""
                ? product.product_name_sort
                : product.product_name,
            product_unit: product.product_unit,
            product_ext_code: product.product_ext_code,
            calcprice: product.calcprice,
            image: product.image,
            bestellijst_id: listItemID,
            partnerID:
              parseInt(product.supplier_module_partner) === 1
                ? parseInt(product.product_supplier_id)
                : 0,
          };
          cart.push(cartItems);
        } else {
          cart[cartIndex].quantity = qty;
          cart[cartIndex].weight = weight;
          cart[cartIndex].comment = comment;
        }
        this.setState({ cart: cart });

        break;
      case "CLEAR":
        this.setState({ cart: [] });
        break;
      default:
    }
    //  console.log(cart);
    /* Update server cart */
    if (cartIndex === false) {
      if (listItemID !== false) {
        this.updateServerCart(action, this.findCartIndexByListID(listItemID));
      } else {
        this.updateServerCart(
          action,
          this.findCartIndexByID(product.product_id)
        );
      }
    } else {
      this.updateServerCart(action, cartIndex);
    }
  };

  loadGroups(clientID, id, level, fieldindex) {
    this.setState({ groupLoading: true });
    fetch(
      settings.server_url +
        "get_productgroups/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        clientID +
        "/" +
        id +
        "/" +
        level +
        "/" +
        fieldindex,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.result !== false) {
          this.setState({ groups: response, groupLoading: false });

          if (response.length === 0) {
            this.closeNavProducts(null);
            this.closeMenu();
          }
          if (id > 0) {
            this.setProductgroupHandler(this.state.tempProductGroupID);
          }
          //this.props.loading(false);
        } else {
          document.location = "/login";
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  navProductPrevious = (e) => {
    let path = this.state.prevIDS;
    let last = null;
    if (path.length === 0) {
      last = { clientID: 0, id: 0, label: "PRODUCTEN", fieldindex: 0 };
      this.closeNavProducts(null);
    } else if (path.length === 1) {
      last = { clientID: 0, id: 0, label: "PRODUCTEN", fieldindex: 0 };
      path.pop();
    } else {
      last = path[path.length - 2];
      path.pop();
    }
    this.setState({ grouplabel: last.label });
    this.loadGroups(last.clientID, last.id, last.level, last.fieldindex);
    // this.setProductgroupHandler(last.id);
  };

  navProductClick = (e, clientID, id, label, level, fieldindex) => {
    e.preventDefault();

    this.setState({
      searchString: "",
      searchString2: "",
      searchClient: 0,
      searchClientText: "Zoek in alle afdelingen",
    });
    //this.searchHandler("");
    //this.searchHandler2("");

    this.loadGroups(clientID, id, level, fieldindex);
    if (clientID === 0 && id === 0) {
      this.setState({ prevIDS: [] });
    } else {
      let pid = this.state.prevIDS;
      pid = pid.filter((r) => {
        return parseInt(r.level) < parseInt(level);
      });
      pid.push({ clientID, id, label, level });
      this.setState({ prevIDS: pid });
    }

    this.setState({ grouplabel: label, tempProductGroupID: id });
    this.navProductClickOpen(e);

    // this.setProductgroupHandler(id);
  };

  loadOrderlists = () => {
    fetch(
      settings.server_url +
        "get_all_orderlists/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ orderlists: response });
      })
      .catch((error) => console.error("Error:", error));
  };

  updateOrderListData = ({ data, page, scrollY }) => {
    this.setState({
      orderListData: data,
      orderListPage: page,
      orderListScrollY: scrollY,
    });
  };

  updateProductsData = ({ data, page, scrollY }) => {
    this.setState({
      productsData: data,
      productsPage: page,
      productsScrollY: scrollY,
    });
  };

  setActiveSalesRepCustomer = (id) => {
    this.setState({ activeSalesRepCustomerID: parseInt(id) });
  };

  render() {
    const props = {
      isLoggedIn: this.state.isLoggedIn,
      logoutHandler: this.logoutHandler,
      isAuthenticated: this.state.isAuthenticated,
      authenticate: this.authenticate,
      showCart: this.state.showCart,
      showOrderlist: this.state.showOrderlist,
      showMenu: this.state.showMenu,
      toggleCart: this.toggleCart,
      toggleMenu: this.toggleMenu,
      toggleOrderlists: this.toggleOrderlists,
      navProductClick: this.navProductClick,
      navProductPrevious: this.navProductPrevious,
      closeNavProducts: this.closeNavProducts,
      closeOrderlists: this.closeOrderlists,
      setProductgroupHandler: this.setProductgroupHandler,
      productGroupID: this.state.productGroupID,
      cart: this.state.cart,
      loadCart: this.loadCart,
      cartHandler: this.cartHandler,
      showSubMenu: this.state.showSubMenu,
      closeMenu: this.closeMenu,
      groups: this.state.groups,
      searchHandler: this.searchHandler,
      searchHandler2: this.searchHandler2,
      searchString: this.state.searchString,
      searchString2: this.state.searchString2,
      searchClient: this.state.searchClient,
      searchClientText: this.state.searchClientText,
      setSearchClient: this.setSearchClient,
      loading: this.loading,
      isLoading: this.state.isLoading,
      groupLoading: this.state.groupLoading,
      grouplabel: this.state.grouplabel,
      breadcrumbPath: this.state.breadcrumbPath,
      addBreadcrumb: this.addBreadcrumb,
      username: this.state.username,
      prevIDS: this.state.prevIDS,
      cartLoaded: this.state.cartLoaded,
      loadOrderlists: this.loadOrderlists,
      orderlists: this.state.orderlists,
      orderListPage: this.state.orderListPage,
      orderListData: this.state.orderListData,
      orderListScrollY: this.state.orderListScrollY,
      updateOrderListData: this.updateOrderListData,

      productsPage: this.state.productsPage,
      productsData: this.state.productsData,
      productsScrollY: this.state.productsScrollY,
      updateProductsData: this.updateProductsData,

      isSalesRep: this.state.isSalesRep,
      hasTD: this.state.hasTD,
      activeSalesRepCustomerID: this.state.activeSalesRepCustomerID,
      setActiveSalesRepCustomer: this.setActiveSalesRepCustomer,
    };

    return (
      <Router>
        <div className="App">
          {isDev && (
            <div
              style={{
                position: "fixed",
                bottom: 10,
                left: 10,
                zIndex: 10000000000,
              }}>
              <img
                src="https://kislayverma.com/wp-content/uploads/2020/07/React-logo.webp_.png"
                width="20"
                height="20"
              />
              <span className=""> development modus</span>
            </div>
          )}
          <ClearCache>
            {({ isLatestVersion, emptyCacheStorage }) => (
              <div>
                {!isLatestVersion && (
                  <div className="loader show">
                    <br />
                    <br />
                    Er is een nieuwe versie van Valkweb beschikbaar. <br />
                    <br />
                    <a
                      href="#"
                      className="btn btn-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        emptyCacheStorage();
                      }}>
                      Update versie nu.
                    </a>
                  </div>
                )}
              </div>
            )}
          </ClearCache>
          {/*
          <Offline>
            <div className="loader show">
              <i className="fal fa-6x fa-wifi-slash"></i>
              <br />
              <br />U heeft momenteel geen verbinding
            </div>
          </Offline>
                    */}
          <div className={this.state.isLoading ? "loader show" : "loader"}>
            <img src={loader} alt="loader" />
            <br />
            <br />
            Laden...
          </div>
          <Switch>
            <PrivateRoute path="/" exact component={Home} {...props} />
            <PrivateRoute
              path="/producten/lijst/:id1"
              {...props}
              component={Products}
            />
            <PrivateRoute
              path="/producten/zoeken/:val"
              {...props}
              exact
              component={Products}
            />
            <PrivateRoute
              path="/aanbiedingen"
              exact
              component={Products}
              {...props}
            />
            <PrivateRoute
              path="/nieuwe-producten"
              exact
              component={Products}
              {...props}
            />
            <PrivateRoute
              path="/producten/details/:id"
              component={ProductDetail}
              {...props}
            />
            <PrivateRoute
              path="/partners"
              exact
              component={Partners}
              {...props}
            />
            <PrivateRoute
              path="/partners/details/:id"
              exact
              component={PartnerDetails}
              {...props}
            />
            <PrivateRoute
              path="/suppliers/details/:id"
              exact
              component={SupplierDetails}
              {...props}
            />
            <PrivateRoute
              path="/bestellijsten/:clientid/:id/:name"
              {...props}
              component={Bestellijsten}
            />
            <PrivateRoute path="/contact" {...props} component={Contact} />
            <PrivateRoute path="/checkout" {...props} component={Checkout} />
            <PrivateRoute path="/orders" {...props} exact component={orders} />
            <PrivateRoute
              path="/retouren"
              {...props}
              exact
              component={retouren}
            />
            <PrivateRoute path="/hotels" exact component={Hotels} {...props} />
            <PrivateRoute
              path="/hotels/producten/:id"
              exact
              component={HotelProducts}
              {...props}
            />
            <PrivateRoute path="/nieuws" exact component={Nieuws} {...props} />
            <PrivateRoute
              path="/technische-dienst/:id?"
              exact
              component={TD}
              {...props}
            />
            <PrivateRoute
              path="/technische-dienst/details/:id"
              exact
              component={TDDetails}
              {...props}
            />
            <PropsRoute
              path="/login"
              component={Login}
              loginHandler={this.loginHandler}
              loginIncorrect={this.state.loginIncorrect}
              loading={this.loading}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

const PrivateRoute = ({
  component: Component,
  isLoggedIn,
  showCart,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn === true ? (
        <React.Fragment>
          <Container fluid={true} className="h-100">
            <Row className="h-100">
              <Sidemenu {...rest} {...props} />
              <Col className={showCart ? "main cart" : "main"}>
                <Header
                  searchHandler={props.searchHandler}
                  showCart={showCart}
                  {...rest}
                  {...props}
                />
                <Breadcrumbs {...rest} {...props} />
                <Component {...props} {...rest} />
              </Col>
              <Orderlists {...props} {...rest} />
              <Cart showCart={showCart} {...props} {...rest} />
            </Row>
          </Container>
        </React.Fragment>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

if (typeof window !== "undefined") {
  hotjar.initialize(939884, 6);
}

export default Sentry.withProfiler(App);
