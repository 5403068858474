import React, { Component } from "react";
import { Input, Label, Button, Modal, ModalBody, ModalFooter } from "reactstrap";

class Comment extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      modal: false,
      comment: props.comment,
    };
  }

  toggleModal = (e) => {
    e.preventDefault();
    if (!this.state.modal) {
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.props.saveHandler(this.state.comment);
    this.setState({ modal: false });
  };

  changeInput = (e) => {
    this.setState({ comment: e.target.value });
  };

  createMarkup(txt) {
    return { __html: txt };
  }

  render() {
    return (
      <React.Fragment>
        <a
          href="#"
          title="Opmerking ingeven"
          tabIndex="-1"
          className="a-comment"
          onClick={this.toggleModal}>
          <i className="fal fa-comment-dots" />
        </a>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalBody>
            <Label>Opmerking</Label>
            <Input
              type="textarea"
              name="comments"
              value={this.state.comment}
              onChange={this.changeInput}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={this.submitForm}>
              Opslaan
            </Button>
            <Button color="secondary" onClick={this.toggleModal}>
              Sluiten
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Comment;
