import React, { Component, Fragment } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import settings from "./../settings.js";

var currentWeekNumber = require("current-week-number");
class Packings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      weeks: [],
      weekNo: currentWeekNumber(),
      year: new Date().getFullYear(),
      value: null,
    };
    this.changeWeek = this.changeWeek.bind(this);
  }
  componentDidMount() {
    this.loadPacking();
    this.loadWeeks();
  }

  changeWeek(event) {
    let data = event.target.value;
    let weekNo = data.split("-")[0];
    let year = data.split("-")[1];
    this.setState(
      { value: weekNo + "-" + year, weekNo: weekNo, year: year },
      () => this.loadPacking()
    );
  }

  loadWeeks() {
    fetch(
      settings.server_url +
        "get_orders_count_weeks/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ weeks: response });
      })
      .catch((error) => console.error("Error:", error));
  }
  loadPacking() {
    fetch(
      settings.server_url +
        "get_packing/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.state.weekNo +
        "/" +
        this.state.year
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });
      })
      .catch((error) => console.error("Error:", error));
  }
  render() {
    return (
      <div className="displaycontainer">
        <form className="form-inline orderheader px-3 py-4">
          <div className="w-100">
            <div className="float-left d-flex flex-wrap flex-md-nowrap">
              <label className="mr-sm-2 mr-auto">Selecteer Week</label>
              <div className="input-group">
                <select
                  className="custom-select mb-3 mb-md-0"
                  onChange={this.changeWeek}
                  value={this.state.weekNo + "-" + this.state.year}
                >
                  {this.state.weeks.map((week, ix) => {
                    return (
                      <option key={ix} value={week.w + "-" + week.y}>
                        {"Week " + week.w + " " + week.y}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </form>
        <div className="table-responsive">
          <Table className="table packingtable mb-0 border bg-white">
            <Tbody>
              <Tr>
                <Td>
                  <Table className="table mb-0">
                    {this.state.data.map((packing, i) => {
                      return (
                        <Fragment key={i}>
                          <Thead>
                            <Tr>
                              <Th className="align-middle text-left columnexpand">
                                <strong>
                                  {packing[0].order_delivery_moment}
                                </strong>
                              </Th>
                              <Th className="align-middle text-left px-5 text-success">
                                GELEVERD
                              </Th>
                              <Th className="align-middle px-5">RETOUR</Th>
                            </Tr>
                          </Thead>
                          {packing.map((packrow, i2) => {
                            if (
                              parseInt(packrow.ntl_out) !== 0 ||
                              parseInt(packrow.ntl_in) !== 0
                            ) {
                              return (
                                <Tbody key={i + "_" + i2}>
                                  <Tr>
                                    <Td className="align-middle text-left columnexpand">
                                      {packrow.packing_name}
                                    </Td>
                                    <Td>
                                      {parseInt(packrow.ntl_out) !== 0
                                        ? packrow.ntl_out
                                        : "-"}
                                    </Td>
                                    <Td>
                                      {parseInt(packrow.ntl_in) !== 0
                                        ? packrow.ntl_in
                                        : "-"}
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Fragment>
                      );
                    })}
                  </Table>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default Packings;
