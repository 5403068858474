import React, { Component } from "react";
import { TabContent, TabPane } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";

import CurrentOrders from "./components/currentorders";
import TopProducts from "./components/topproducts";
import Packing from "./components/packing";
import DelayedOrders from "./components/delayedorders";

class orders extends Component {
  constructor(props) {
    super(props);

    this.props.loading(true);
    this.props.authenticate("/");

    this.toggle = this.toggle.bind(this);
    this.tabs = React.createRef();
    this.state = {
      activeTab: "1",
      shadowLeft: false,
      shadowRight: false,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.shadowhandler = this.shadowhandler.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    this.props.closeMenu();
    this.props.searchHandler2("");
    this.props.setSearchClient(0);
    this.props.addBreadcrumb([{ name: "Leveringen", url: "/orders", reset: true }]);

    this.shadowhandler();
    window.addEventListener("resize", this.shadowhandler);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.shadowhandler);
  }
  shadowhandler() {
    const scroller = this.tabs.current;
    if (scroller.offsetWidth + scroller.scrollLeft < scroller.scrollWidth) {
      this.setState({ shadowRight: true });
    } else if (scroller.offsetWidth + scroller.scrollLeft === scroller.scrollWidth) {
      this.setState({ shadowRight: false });
    }
    if (scroller.scrollLeft > 0) {
      this.setState({ shadowLeft: true });
    } else {
      this.setState({ shadowLeft: false });
    }
  }

  renderView({ style, ...props }) {
    const viewStyle = {};
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  handleUpdate(values) {}

  render() {
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        /*onUpdate={this.handleUpdate}*/ className="row ml-0 content-bc">
        <div className="col pt-md-0 pt-md-2 px-0 px-md-4 col">
          <div className="p-lg-2 row">
            <div className="col-12">
              {/*}  
              <button className="float-right align-items-center border btn btn-link d-flex text-uppercase m-3 m-md-0">
                <i className="far fa-download mr-2" />
                download artikelbestand
              </button>
            */}
            </div>
            <div
              className={
                "col-12 d-flex tabs " +
                (this.state.shadowLeft ? "left-shadow " : "") +
                (this.state.shadowRight ? "right-shadow " : "")
              }
              ref={this.tabs}
              onScroll={(e) => this.shadowhandler()}>
              <a
                href="#"
                className={this.state.activeTab === "1" ? "ordertab col active" : "ordertab col"}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle("1");
                }}>
                LOPENDE ORDERS
              </a>
              <a
                href="#"
                className={this.state.activeTab === "3" ? "ordertab col active" : "ordertab col"}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle("3");
                }}>
                TOP PRODUCTEN
              </a>
              <a
                href="#"
                className={this.state.activeTab === "4" ? "ordertab col active" : "ordertab col"}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle("4");
                }}>
                EMBALLAGE
              </a>
              <a
                href="#"
                className={this.state.activeTab === "5" ? "ordertab col active" : "ordertab col"}
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle("5");
                }}>
                UITGESTELDE LEVERINGEN
              </a>
            </div>
            <div className="col-12">
              <div className="rounded overflow-hidden">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {parseInt(this.state.activeTab) === 1 ? (
                      <CurrentOrders
                        addBreadcrumb={this.props.addBreadcrumb}
                        loading={this.props.loading}
                      />
                    ) : (
                      ""
                    )}
                  </TabPane>
                  <TabPane tabId="3">
                    {parseInt(this.state.activeTab) === 3 ? (
                      <TopProducts
                        cart={this.props.cart}
                        cartHandler={this.props.cartHandler}
                        loading={this.props.loading}
                      />
                    ) : (
                      ""
                    )}
                  </TabPane>
                  <TabPane tabId="4">
                    {parseInt(this.state.activeTab) === 4 ? <Packing /> : ""}
                  </TabPane>
                  <TabPane tabId="5">
                    {parseInt(this.state.activeTab) === 5 ? (
                      <DelayedOrders loading={this.props.loading} />
                    ) : (
                      ""
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default orders;
