import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import settings from "./settings.js";
//import { Link } from "react-router-dom";

class TDDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, content: [] };

    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  componentDidMount() {
    this.props.closeMenu();
    if (this.props.breadcrumbPath.length === 0) {
      this.props.addBreadcrumb([
        { name: "Technische Dienst", url: "/technische-dienst", reset: true },
      ]);
    }

    this.props.searchHandler2("");
    this.props.setSearchClient(0);

    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  loadData() {
    this.props.loading(true);
    fetch(
      settings.server_url +
        "get_content_page/" +
        window.sessionStorage.getItem("sid"),
      {
        method: "POST",
        body: JSON.stringify({
          id: this.props.match.params.id,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ content: response });
        this.props.addBreadcrumb([
          {
            name: response[0].page_html_title,
            url: "/technische-dienst/details/" + this.props.match.params.id,
          },
        ]);
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  UNSAFE_componentWillMount() {
    this.props.authenticate("/");
  }

  handleClientChange = (e) => {
    this.setState({ filterOn: e.target.value });
  };

  renderView({ style, ...props }) {
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    if (!this.props.isAuthenticated) return null;
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbVertical={this.renderThumb}
        renderTrackHorizontal={(props) => (
          <div {...props} className="track-horizontal" />
        )}
        className="row ml-0 content-bc"
      >
        <div className="col p-md-4 px-0 maxw1200">
          <div className="p-lg-2 row">
            <div className="col content-block ml-4 mr-3 p-lg-3">
              <div className="row">
                {this.state.content.length > 0 ? (
                  <>
                    <div className="col-lg-9 col-md-12 text-left">
                      <h3>{this.state.content[0].page_html_title}</h3>
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.content.length > 0
                              ? this.state.content[0].page_content
                              : "",
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <img
                        src={
                          "http://app.ecbase.nl" +
                          this.state.content[0].page_image
                        }
                        alt=""
                        className="img-fluid m-auto d-block"
                      />
                      {this.state.content[0].layouts.map((layout) => {
                        return (
                          <span
                            key={layout.page_id}
                            className="mt-3 d-block text-left"
                            dangerouslySetInnerHTML={{
                              __html: layout.page_content,
                            }}
                          ></span>
                        );
                      })}
                      {this.state.content[0].supplier_oci_url !== "" ? (
                        <span className="mt-3 d-block text-left">
                          <a
                            href={this.state.content[0].supplier_oci_url}
                            className="btn btn-primary"
                            target="_self"
                            rel="noopener noreferrer"
                          >
                            Ga naar bestelsite
                          </a>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="overflow-hidden p-2"></div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default TDDetails;
