import React, { Component } from "react";
import {
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import settings from "./../settings.js";

class Quote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      name: props.username,
      comment: "",
    };
  }

  toggleModal = (e) => {
    e.preventDefault();
    if (!this.state.modal) {
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  changeInput = (e) => {
    //console.log(e.target.name);
    if (e.target.name === "name") {
      this.setState({ name: e.target.value });
    } else if (e.target.name === "comments") {
      this.setState({ comment: e.target.value });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    fetch(
      settings.server_url +
        "set_send_quote/" +
        window.sessionStorage.getItem("sid"),
      {
        method: "POST",
        body: JSON.stringify({
          productID: this.props.id,
          productName: this.props.name,
          name: this.state.name,
          comments: this.state.comment,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          modal: false,
        });
      })
      .catch((error) => console.error("Error:", error));
  };

  createMarkup(txt) {
    return { __html: txt };
  }

  render() {
    return (
      <React.Fragment>
        <a
          href="#"
          tabIndex="-1"
          className="a-quote"
          onClick={this.toggleModal}
        >
          OFFERTE
        </a>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <Form onSubmit={this.submitForm}>
            <ModalHeader>Offerte aanvragen</ModalHeader>
            <ModalBody>
              <Label>Product</Label>
              <Input value={this.props.name} disabled={true} />
              <br />
              <Label>Uw naam</Label>
              <Input
                name="name"
                value={this.state.name}
                onChange={this.changeInput}
              />
              <br />
              <Label>Opmerkingen, vragen, opties</Label>
              <Input
                type="textarea"
                name="comments"
                value={this.state.comment}
                onChange={this.changeInput}
              />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary">
                Aanvragen
              </Button>
              <Button color="secondary" onClick={this.toggleModal}>
                Sluiten
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Quote;
