import React, { Component } from "react";
import { Link } from "react-router-dom";

class TDBlock extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }
  render() {
    const item = this.props.data;
    const imgstyle = {
      width: "100%",
      height: "140px",
      backgroundImage:
        "url('" +
        (item.page_image !== null && item.page_image.trim() !== ""
          ? "https://cm.valkweb.nl" + item.page_image
          : "/td.jpeg") +
        "')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };

    let link = "#";
    if (item.page_item_link !== null && item.page_item_link.trim() !== "") {
      // HTML Link
      link = item.page_item_link;
    } else if (
      item.page_content_type !== null &&
      parseInt(item.page_content_type) === 0
    ) {
      // Direct CMS PAGE Link
      link = "/technische-dienst/details/" + item.page_id;
    } else if (
      item.page_content_type !== null &&
      parseInt(item.page_content_type) === 1
    ) {
      // Menu items link
      link = "/technische-dienst/" + item.page_id;
    }

    return (
      <div className="col-12 col-md-3 mb-md-3 partner pr-0">
        <Link to={link} style={{ textDecoration: "none" }}>
          <div className="d-flex flex-wrap border-bottom p-2 bg-white flex-column flex-md-row mh100 h-100">
            <div
              className="mx-auto mb-3 mb-md-0 align-self-start py-3"
              style={imgstyle}
            ></div>
            <div className="w-100">
              <span
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflowX: "hidden",
                }}
                className="bg-dark d-block font-weight-bold mt-0 p-2 text-left text-light"
              >
                {item.page_html_title !== null &&
                item.page_html_title.trim() !== ""
                  ? item.page_html_title
                  : item.page_content_code}
              </span>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default TDBlock;
