import React, { Component } from "react";

import settings from "./../settings.js";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: false, isLoading: false, data: [] };
    this.toggle = this.toggle.bind(this);
  }
  loadNewsDetails() {
    const notification = this.props.notification;

    fetch(
      settings.server_url +
        "get_newsitem/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        notification.nieuws_id
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }
  clickRemove = (e, index) => {
    this.setState({
      modal: false,
    });
    this.props.clickRemoveHandler(e, index);
  };
  toggle() {
    if (!this.state.modal) {
      this.loadNewsDetails();
    }
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  createMarkup(txt) {
    return { __html: txt };
  }
  render() {
    const notification = this.props.notification;

    let cls = "delay-notification";
    if (notification.removed) {
      cls = "delay-notification removed";
    }

    return (
      <div className={cls} key={notification.nieuws_id}>
        <i className="fas fa-history" />
        <label>
          <strong>{notification.orderrow_quantity}</strong> x{" "}
          <strong>{notification.orderrow_name}</strong> verwacht op{" "}
          <strong>{notification.order_delivery_moment}</strong>
        </label>
      </div>
    );
  }
}

export default Notification;
