import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import settings from "./settings.js";
import { Link } from "react-router-dom";
import TDBlock from "./components/tdblock";

class TD extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, data: [], news: [] };

    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  componentDidMount() {
    this.props.closeMenu();
    this.props.addBreadcrumb([
      { name: "Technische Dienst", url: "/technische-dienst", reset: true },
    ]);

    this.props.searchHandler2("");
    this.props.setSearchClient(0);

    this.loadData(this.props.match.params.id);
    this.loadNewsData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData(this.props.match.params.id);
      this.loadNewsData();
    }
  }

  loadData(id) {
    this.props.loading(true);

    const cmsid = typeof id != "undefined" ? id : 911;

    fetch(
      settings.server_url +
        "get_content_items_by_menu_id/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        cmsid
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });

        if (cmsid !== 911) {
          this.props.addBreadcrumb([
            {
              name: response[0].current.page_content_code,
              url: "/technische-dienst/" + response[0].current.page_id,
            },
          ]);
        }

        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  loadNewsData() {
    this.props.loading(true);

    const cmsid = 935;

    fetch(
      settings.server_url +
        "get_content_items_by_menu_id/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        cmsid
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ news: response });

        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  UNSAFE_componentWillMount() {
    this.props.authenticate("/");
  }

  handleClientChange = (e) => {
    this.setState({ filterOn: e.target.value });
  };

  renderView({ style, ...props }) {
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    if (!this.props.isAuthenticated) return null;
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        /*onUpdate={this.handleUpdate}*/
        className="row ml-0 text-left content-bc"
      >
        <div className="col p-md-4 px-0 maxw1200">
          <div className="row p-2">
            <div className="col p-0">
              <div className="row ml-0 mr-4">
                {this.state.data.map((item) => {
                  return <TDBlock key={item.page_id} data={item} />;
                })}
              </div>
            </div>
          </div>
          {this.state.news.length > 0 ? (
            <div className="row px-0 px-md-2 pb-2 pt-0 pt-md-2 mr-0">
              <div className="col">
                <h5 className="text-left mb-4">Mededelingen</h5>
                <div className="content-block notifications">
                  {this.state.news.map((item) => {
                    return (
                      <div className={"notification"} key={item.page_id}>
                        <Link
                          to={"/technische-dienst/details/" + item.page_id}
                          style={{ textDecoration: "none" }}
                        >
                          <i
                            className="fas fa-exclamation-triangle"
                            onClick={null}
                          />
                          <label onClick={null}>
                            <strong>{item.page_html_title}</strong>
                          </label>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Scrollbars>
    );
  }
}

export default TD;
