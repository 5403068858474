import React, { Component } from "react";
import { Link } from "react-router-dom";

class PartnerRow extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }
  render() {
    const partner = this.props.partner;
    const imgstyle = {
      //maxWidth: "200px",
      //maxHeight: "140px",
      //width: "200px"
    };

    return (
      <div className="col-12 col-md-3 mb-md-3 partner">
        <div className="d-flex flex-wrap border-bottom p-2 bg-white flex-column flex-md-row mh100 h-100">
          <div
            className="mx-auto mb-3 mb-md-0 align-self-start mw120 py-3"
            style={imgstyle}
          >
            {!isNaN(parseInt(partner.supplier_id)) ? (
              <Link
                to={"/partners/details/" + partner.supplier_id}
                className="imglink"
              >
                <img
                  className="img-fluid"
                  src={"https://app.ecbase.nl/" + partner.page_image}
                  alt=""
                />
              </Link>
            ) : (
              <Link
                to={"/suppliers/details/" + partner.page_id}
                className="imglink"
              >
                <img
                  className="img-fluid"
                  src={"https://app.ecbase.nl/" + partner.page_image}
                  alt=""
                />
              </Link>
            )}
          </div>
          <div className="w-100">
            <span className="mt-0 d-block text-left font-weight-bold">
              {partner.supplier_company_name}
            </span>
            <div className="partnertextholder">
              <div className="d-flex mb-3 mb-md-0">
                <span className="text-left partnertext">
                  {partner.page_content}
                </span>
              </div>
            </div>
          </div>
          {!isNaN(
            parseInt(partner.supplier_id) &&
              parseInt(partner.supplier_cms_page_id) === 0
          ) ? (
            <div className="d-inline-flex justify-content-between w-100">
              {(parseInt(partner.supplier_oci_enabled) === 1 &&
                partner.supplier_oci_url !== "") ||
              parseInt(partner.supplier_has_own_prodgroups) === 1 ? (
                <React.Fragment>
                  {parseInt(partner.supplier_oci_enabled) === 1 &&
                  partner.supplier_oci_url !== "" ? (
                    <a
                      href={partner.supplier_oci_url}
                      className="align-self-center btn btn-dark btn-primary d-flex linkmore mt-md-3 text-uppercase text-white"
                      target="_self"
                      rel="noopener noreferrer"
                    >
                      Bestellen
                    </a>
                  ) : (
                    <Link
                      to={"/partners/details/" + partner.supplier_id}
                      className="align-self-center btn btn-dark btn-primary d-flex linkmore mt-md-3 text-uppercase text-white"
                    >
                      Bestellen
                    </Link>
                  )}
                  <Link
                    to={"/partners/details/" + partner.supplier_id}
                    className="align-self-center btn btn-dark d-flex linkmore mt-md-3 text-uppercase text-white"
                  >
                    Info
                  </Link>
                </React.Fragment>
              ) : (
                <Link
                  to={"/partners/details/" + partner.supplier_id}
                  className="align-self-center btn btn-dark d-flex linkmore mt-md-3 text-uppercase text-white ml-auto"
                >
                  info
                </Link>
              )}
            </div>
          ) : (
            <Link
              to={"/suppliers/details/" + partner.page_id}
              className="align-self-center btn btn-dark d-flex linkmore mt-md-3 text-uppercase text-white ml-auto"
            >
              info
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default PartnerRow;
