import React, { Component } from "react";
import settings from "./../settings.js";

class Retouren extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: 0,
      productID: 0,
      productName: "",
      retourEmail: "",
      retourTel: "",
      retourTHT: "",
      quantity: 1,
      reason: 0,
      comments: "",
      errors: "",
      productData: [],
      showConfirmation: false,
    };
  }

  postData() {
    this.props.loading(false);

    fetch(
      settings.server_url +
        "get_partners/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  UNSAFE_componentWillMount() {
    //    this.props.authenticate("/");
  }

  componentDidMount() {
    //    this.props.closeMenu();
    //    this.props.searchHandler2("");
    this.props.addBreadcrumb([
      { name: "Retouren", url: "/retouren", reset: false },
    ]);

    this.validateForm();
  }

  selectProduct = (e, product) => {
    e.preventDefault();
    this.setState(
      {
        productID: product.product_id,
        productName: product.product_name,
        productData: [],
      },
      this.validateForm
    );
  };

  searchProducts() {
    fetch(
      settings.server_url +
        "get_retour_products/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.state.client +
        "/" +
        this.state.productName
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ productData: response });
        //this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  inputChangeHandler = (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case "input-client":
        this.setState(
          { client: e.target.value, productName: "", productID: 0 },
          this.validateForm
        );
        break;
      case "input-product":
        this.setState({ productName: e.target.value }, this.searchProducts);
        break;
      case "input-quantity":
        this.setState({ quantity: e.target.value }, this.validateForm);
        break;
      case "input-reason":
        this.setState({ reason: e.target.value }, this.validateForm);
        break;
      case "input-comment":
        this.setState({ comments: e.target.value }, this.validateForm);
        break;
      case "input-mail":
        this.setState({ retourEmail: e.target.value }, this.validateForm);
        break;
      case "input-tel":
        this.setState({ retourTel: e.target.value }, this.validateForm);
        break;
      case "input-tht":
        this.setState({ retourTHT: e.target.value }, this.validateForm);
        break;
      default:
    }
  };

  validateForm() {
    let err = "";
    if (parseInt(this.state.client) === 0) {
      err += "Er is geen afdeling gekozen\r\n";
    }
    //if (parseInt(this.state.productID) === 0) {
    //  err += "Er is geen product gekozen\r\n";
    //}
    if (parseInt(this.state.quantity) <= 0) {
      err += "Er is geen aantal gekozen\r\n";
    }
    if (parseInt(this.state.reason) === 0) {
      err += "Er is geen reden gekozen<br>";
    }
    if (this.state.retourEmail === "") {
      err += "Er is geen e-mailadres ingegeven<br>";
    }
    if (this.state.retourTel === "") {
      err += "Er is geen telefoonnr. ingegeven<br>";
    }
    this.setState({ errors: err });
  }

  submitForm = (e) => {
    e.preventDefault();
    fetch(
      settings.server_url +
        "set_send_retour/" +
        window.sessionStorage.getItem("sid"),
      {
        method: "POST",
        body: JSON.stringify({
          clientID: this.state.client,
          productID: this.state.productID,
          productName: this.state.productName,
          quantity: this.state.quantity,
          reason: this.state.reason,
          comments: this.state.comments,
          retourEmail: this.state.retourEmail,
          retourTel: this.state.retourTel,
          retourTHT: this.state.retourTHT,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.result === true) {
          this.props.addBreadcrumb([
            { name: "Retouren", url: "/retouren", reset: true },
            { name: "Melding verstuurd", url: "/retouren", reset: false },
          ]);

          this.setState({
            showConfirmation: true,
            client: 0,
            productID: 0,
            productName: "",
            quantity: 1,
            reason: 0,
            comments: "",
            retourTHT: "",
          });
        } else {
          alert("Er is iets fout gegaan");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  newRetourClick = (e) => {
    e.preventDefault();
    this.setState({ showConfirmation: false });
  };

  render() {
    //    if (!this.props.isAuthenticated) return null;
    return (
      <div className="maxw1200">
        {!this.state.showConfirmation ? (
          <div className="col content-block text-left p-lg-3">
            <h3 className="mb-4">Retouren aanmelden</h3>

            <form onSubmit={this.submitForm}>
              <div className="form-group">
                <label htmlFor="input-name">Uw e-mailadres</label>
                <input
                  type="email"
                  className={
                    this.state.retourEmail.trim() > ""
                      ? "form-control is-valid "
                      : "form-control "
                  }
                  id="input-mail"
                  value={this.state.retourEmail}
                  onChange={this.inputChangeHandler}
                  placeholder="Uw e-mailadres"
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label htmlFor="input-tel">Uw telefoonnr.</label>
                <input
                  type="text"
                  className={
                    this.state.retourTel.trim() > ""
                      ? "form-control is-valid "
                      : "form-control "
                  }
                  id="input-tel"
                  value={this.state.retourTel}
                  onChange={this.inputChangeHandler}
                  placeholder="Uw telefoon"
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label htmlFor="input-client">Kies een afdeling</label>
                <select
                  className={
                    parseInt(this.state.client) !== 0
                      ? "form-control is-valid "
                      : "form-control"
                  }
                  id="input-client"
                  defaultValue={this.state.client}
                  onChange={this.inputChangeHandler}
                >
                  <option value="0">Kies een afdeling</option>
                  <option value="10">Van der Valk Koel & Vries</option>
                  <option value="11">Van der Valk Centraal Magazijn</option>
                  <option value="54">Van der Valk Dagvers</option>
                  <option value="69">Van der Valk Versmarkt</option>
                  <option value="29">Van der Valk Apparaten</option>
                  <option value="61">Van der Valk Vis</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="input-product">Selecteer een product</label>
                <input
                  type="text"
                  className={
                    this.state.productName.trim() > ""
                      ? "form-control is-valid "
                      : "form-control "
                  }
                  id="input-product"
                  value={this.state.productName}
                  placeholder="Zoek een product"
                  onChange={this.inputChangeHandler}
                  disabled={parseInt(this.state.client) === 0}
                  autoComplete="off"
                />
              </div>
              <div
                className={
                  this.state.productData.length > ""
                    ? "list-group my-4"
                    : "list-group"
                }
              >
                {this.state.productData.map((product) => {
                  return (
                    <a
                      key={product.product_id}
                      href="#"
                      onClick={(e) => {
                        this.selectProduct(e, product);
                      }}
                      className="list-group-item list-group-item-action"
                    >
                      {product.product_ext_code} {product.product_name}{" "}
                      {product.product_unit}
                    </a>
                  );
                })}
              </div>
              <div className="form-group">
                <label htmlFor="input-product">Aantal retour</label>
                <input
                  type="number"
                  className={
                    parseInt(this.state.quantity) > 0
                      ? "form-control is-valid "
                      : "form-control "
                  }
                  id="input-quantity"
                  value={this.state.quantity}
                  onChange={this.inputChangeHandler}
                />
              </div>
              <div className="form-group">
                <label htmlFor="input-reason">Reden van retour</label>
                <select
                  className={
                    parseInt(this.state.reason) !== 0
                      ? "form-control is-valid "
                      : "form-control  "
                  }
                  id="input-reason"
                  value={this.state.reason}
                  onChange={this.inputChangeHandler}
                >
                  <option value="0">Kies een reden</option>
                  <option value="1">Verkeerd product geleverd</option>
                  <option value="2">Verkeerd aantal geleverd</option>
                  <option value="3">Verkeerd besteld</option>
                  <option value="4">Product over datum</option>
                  <option value="5">Beschadigd product</option>
                  <option value="6">Anders, geef aan in opmerking</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="input-tht">
                  THT van retour indien van toepassing
                </label>
                <input
                  type="date"
                  className={
                    this.state.retourTHT.trim() > ""
                      ? "form-control is-valid "
                      : "form-control "
                  }
                  id="input-tht"
                  value={this.state.retourTHT}
                  onChange={this.inputChangeHandler}
                  placeholder="dd-mm-jjjj"
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label htmlFor="input-comment">Opmerking</label>
                <textarea
                  className="form-control"
                  id="input-comment"
                  autoComplete="new-password"
                  rows="3"
                  value={this.state.comments}
                  onChange={this.inputChangeHandler}
                />
              </div>
              {this.state.errors !== "" ? (
                <div className="alert-warning p-2 mb-2">
                  Niet alle benodigde velden zijn nog ingevuld.
                </div>
              ) : (
                ""
              )}
              <div className="form-group">
                <button
                  type="submit"
                  disabled={this.state.errors !== ""}
                  className="btn btn-primary"
                >
                  Verzenden
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="col content-block text-left p-lg-3">
            <h3 className="mb-4">Uw melding is verstuurd</h3>
            <p>Wij proberen uw melding zo snel mogelijk te verwerken.</p>
            <p>
              <a href="#" onClick={this.newRetourClick}>
                Klik hier als u nog een melding wilt maken.
              </a>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default Retouren;
