import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import settings from "./settings.js";
import Product from "./components/product.js";
import { Input } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";

const WAIT_INTERVAL = 300;

class PartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
      promos: [],
      groups: [],
      news: [],
      productData: [],
      hasMoreItems: true,
      page: 0,
      pageLoading: false,
      dropdownOpen: false,
      searchInputValue: "",
      hasProducts: false,
      groupID: 0,
      tab: 0,
    };
    this.props.loading(true);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.loadPromotions();
    this.loadProductGroups();
    this.loadNews();
    this.loadProducts(true);
    this.props.closeMenu();
  }

  loadData() {
    this.props.loading(false);
    fetch(
      settings.server_url +
        "get_partner_details/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });

        this.props.addBreadcrumb([
          { name: "Partners", url: "/partners", reset: true },
          {
            name: response[0].supplier_company_name,
            url: "/partners/details/" + response[0].supplier_id,
            reset: false,
          },
        ]);
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  loadProductGroups() {
    fetch(
      settings.server_url +
        "get_partner_productgroups/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ groups: response });
        //this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  loadPromotions() {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url +
        "get_promotions/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ promos: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }

  loadProducts(reset) {
    let page = 0;
    if (reset) {
      this.setState({ productData: [], hasMoreItems: true, page: 0 });
    } else {
      page = this.state.page;
    }

    if (!this.state.pageLoading) {
      //console.log(this.state, this.props);
      this.setState({ pageLoading: true });
      fetch(
        settings.server_url +
          "get_products/" +
          window.sessionStorage.getItem("sid"),
        {
          method: "POST",
          body: JSON.stringify({
            partner: this.props.match.params.id,
            search: this.state.searchInputValue,
            groupID: this.state.groupID,
            page: page,
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          let products = [...this.state.productData, ...response];

          if (!this.state.hasProducts && response.length > 0) {
            this.setState({ hasProducts: true });
          }
          this.setState({
            productData: products,
            page: page + 1,
            pageLoading: false,
          });
          this.props.loading(false);
          if (response.length === 0) {
            this.setState({ hasMoreItems: false });
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }

  loadNews() {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url +
        "get_newsitems/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ news: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
    this.props.authenticate("/");
  }

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  setTab = (e, selectTab) => {
    this.setState({ tab: selectTab });
  };

  changeValue(key) {
    //console.log(key);
    this.setState({ groupID: key }, function () {
      this.loadProducts(true);
    });
  }

  onSearchChange = (e) => {
    e.preventDefault();

    clearTimeout(this.timer);

    let val = e.target.value;

    this.setState({ searchInputValue: val });

    this.timer = setTimeout(() => {
      this.triggerChange(val);
    }, WAIT_INTERVAL);
  };

  triggerChange = (val) => {
    this.loadProducts(true);
    //console.log(val);
    //this.props.searchHandler(val);
  };

  renderView({ style, ...props }) {
    //const { top } = this.state;
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  createMarkup(txt) {
    return { __html: txt };
  }

  render() {
    if (!this.props.isAuthenticated) return null;

    let results;
    let promotions;

    if (this.state.productData.length > 0) {
      results = this.state.productData.map((product) => {
        return (
          <Product
            key={product.product_id}
            history={this.props.history}
            product={product}
            cart={this.props.cart}
            cartHandler={this.props.cartHandler}
            scrollPos={window.scrollTop}
            showListButton={true}
          />
        );
      });
    } else if (this.state.productData.length === 0 && !this.state.pageLoading) {
      results = (
        <div className="p-3">
          <strong>Er zijn geen resultaten gevonden</strong>
        </div>
      );
    } else if (this.state.productData.length === 0 && this.state.pageLoading) {
      results = (
        <div className="p-3">
          <strong>Bezig met laden...</strong>
        </div>
      );
    }

    if (this.state.promos.length > 0) {
      promotions = this.state.promos.map((product) => {
        return (
          <Product
            key={product.product_id}
            history={this.props.history}
            product={product}
            cart={this.props.cart}
            cartHandler={this.props.cartHandler}
            scrollPos={window.scrollTop}
            showListButton={true}
          />
        );
      });
    } else if (this.state.promos.length === 0 && !this.state.pageLoading) {
      promotions = (
        <div className="p-3">
          <strong>Er zijn geen resultaten gevonden</strong>
        </div>
      );
    } else if (this.state.promos.length === 0 && this.state.pageLoading) {
      promotions = (
        <div className="p-3">
          <strong>Bezig met laden...</strong>
        </div>
      );
    }

    if (this.state.data.length > 0) {
      const partner = this.state.data[0];
      return (
        <Scrollbars
          renderView={this.renderView}
          renderThumbVertical={this.renderThumb}
          renderTrackHorizontal={(props) => (
            <div {...props} className="track-horizontal" />
          )}
          /*onUpdate={this.handleUpdate}*/ className="row ml-0 content-bc"
        >
          <InfiniteScroll
            pageStart={0}
            threshold={65}
            initialLoad={true}
            loadMore={() => {
              this.loadProducts(false);
            }}
            hasMore={this.state.hasMoreItems}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
            useWindow={false}
            className="col p-md-4 px-0 maxw1200"
          >
            <div className="p-lg-2 row">
              <div className="col content-block ml-4 mr-3 p-lg-3">
                <div className="row">
                  <div className="col-lg-3 col-sm-12">
                    <img
                      src={"http://app.ecbase.nl" + partner.main_image}
                      alt=""
                      className="d-block img-fluid m-1 m-2 p-3"
                    />

                    <span
                      className="mt-3 d-block text-left"
                      dangerouslySetInnerHTML={{
                        __html: partner.side_content,
                      }}
                    ></span>
                    <br />
                    {parseInt(partner.supplier_oci_enabled) === 1 &&
                    partner.supplier_oci_url !== "" ? (
                      <span className="mt-3 d-block text-left">
                        <a
                          href={partner.supplier_oci_url}
                          className="btn btn-primary"
                          target="_self"
                          rel="noopener noreferrer"
                        >
                          Ga naar bestelsite
                        </a>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-lg-9 col-md-12 text-left">
                    <h3>{partner.name}</h3>
                    <br />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: partner.main_content,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.hasProducts ? (
              <div className="p-lg-2 btn-group">
                <button
                  className={
                    this.state.tab === 0
                      ? "btn btn-dark active"
                      : "btn btn-dark"
                  }
                  onClick={(e) => this.setTab(e, 0)}
                >
                  Producten
                </button>
                <button
                  className={
                    this.state.tab === 2
                      ? "btn btn-dark active"
                      : "btn btn-dark"
                  }
                  onClick={(e) => this.setTab(e, 2)}
                >
                  Aanbiedingen
                </button>
                <button
                  className={
                    this.state.tab === 3
                      ? "btn btn-dark active"
                      : "btn btn-dark"
                  }
                  onClick={(e) => this.setTab(e, 3)}
                >
                  Nieuws
                </button>
              </div>
            ) : (
              ""
            )}
            <div className="p-lg-2 row">
              <div className="col">
                <div
                  className={
                    this.state.tab === 3
                      ? "d-flex text-left flex-wrap justify-content-between align-items-center mb-3"
                      : "d-none"
                  }
                >
                  {this.state.news.map((notification, index) => {
                    let nieuwsDate = new Date(notification.nieuws_date);
                    let edd = nieuwsDate.getDate();
                    let emm = nieuwsDate.getMonth() + 1; //January is 0!

                    let eyyyy = nieuwsDate.getFullYear();
                    if (edd < 10) {
                      edd = "0" + edd;
                    }
                    if (emm < 10) {
                      emm = "0" + emm;
                    }
                    let end = edd + "-" + emm + "-" + eyyyy;
                    return (
                      <div
                        className={
                          "notifications content-block mx-2 col-12 col-md-auto w-100 mb-1"
                        }
                        key={notification.nieuws_id}
                      >
                        <div className="col-12 d-flex justify-content-between mb-3">
                          <strong className="text-left">
                            {notification.nieuws_title}
                          </strong>{" "}
                          <span className="nowrap ml-3">{end}</span>
                        </div>
                        <div
                          className="col-12 text-left"
                          dangerouslySetInnerHTML={this.createMarkup(
                            notification.nieuws_body
                          )}
                        ></div>
                      </div>
                    );
                  })}
                </div>
                {promotions.length > 0 && this.state.tab === 2 ? (
                  <React.Fragment>
                    <div className="d-flex text-left flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                      <h2 className="mx-2 mb-md-0 col-12 col-md-auto">
                        Aanbiedingen
                      </h2>
                    </div>
                    <div className="content-block ml-2 mb-3">{promotions}</div>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <div
                  className={
                    this.state.tab === 0
                      ? "d-flex text-left flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3"
                      : "d-none"
                  }
                >
                  {this.state.hasProducts ? (
                    <React.Fragment>
                      <h2 className="mx-2 mb-md-0 col-12 col-md-auto">
                        Producten
                      </h2>
                      <Input
                        placeholder="Zoek in producten"
                        value={this.state.searchInputValue}
                        onChange={this.onSearchChange}
                        className="mb-2 mb-md-0 col-12 col-md-8"
                      />
                      <Input
                        type="select"
                        onChange={(e) => this.changeValue(e.target.value)}
                        className="form-control ml-lg-3 col-12 col-md"
                      >
                        <option key={0} value={0}>
                          {"Alle categorieën"}
                        </option>
                        {this.state.groups.map((g) => {
                          return (
                            <option key={g.id} value={g.id}>
                              {g.site_label}
                            </option>
                          );
                        })}
                      </Input>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>

                {results.length > 0 ? (
                  <div
                    className={
                      this.state.tab === 0 ? "content-block ml-2" : "d-none"
                    }
                  >
                    {results}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </InfiniteScroll>
        </Scrollbars>
      );
    } else {
      return "";
    }
  }
}

export default PartnerDetails;
