import React, { Component } from "react";
import { Link } from "react-router-dom";

import Notification from "./notification.js";
import settings from "./../settings.js";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, data: [] };
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url +
        "get_newsitems/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }
  removeItem(newsID) {
    fetch(
      settings.server_url +
        "set_newsitem_read/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        newsID
    )
      .then((res) => res.json())
      .then((response) => {})
      .catch((error) => console.error("Error:", error));
  }

  clickRemoveHandler = (e, index) => {
    e.preventDefault();

    let newData = this.state.data;
    newData[index].removed = true;

    this.setState({ data: newData });

    this.removeItem(newData[index].nieuws_id);

    setTimeout(() => {
      let newData = this.state.data;
      newData.splice(index, 1);

      this.setState({ data: newData });
    }, 300);
  };

  openNotification = (e, index) => {
    let newData = this.state.data;

    newData[index].opened = true;

    this.setState({ data: newData });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="row px-0 px-md-2 pb-2 pt-0 pt-md-2 mb-3">
          <div className="col">
            <div>Notificaties laden...</div>
          </div>
        </div>
      );
    } else if (this.state.data.length > 0) {
      return (
        <React.Fragment>
          <div className="row px-0 px-md-2 pb-2 pt-0 pt-md-2">
            <div className="col">
              <div className="content-block notifications">
                {this.state.data.map((notification, index) => {
                  return (
                    <Notification
                      clickRemoveHandler={(e) =>
                        this.clickRemoveHandler(e, index)
                      }
                      openNotification={(e) => this.openNotification(e, index)}
                      notification={notification}
                      key={notification.nieuws_id}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row px-0 px-md-2 pb-2 pt-0 pt-md-2 mb-3">
            <div className="col mt-3 text-right">
              <Link to={"/nieuws"} className="linkshome">
                Alle nieuws
              </Link>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default Notifications;
