import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Product from "./components/product.js";
import settings from "./settings.js";
import slugify from "./lib.js";
import { debounce } from "lodash";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class Bestellijsten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.orderListData.length === 0,
      data: this.props.orderListData,
      searchString: this.props.searchString,
      hasMoreItems: true,
      page: this.props.orderListPage,
      scrollY: this.props.orderListScrollY,
      pageLoading: false,
      productGroupID: this.props.productGroupID,
      top: 0,
      listID: 0,
      modal: false,
      uploadFiles: [],
      filename: "",
      uploadError: "",
      uploadSuccess: "",
      uploadClient: 10,
      editName: false,
      name: "",
      manual: false,
    };
    this.props.loading(true);
    this.props.authenticate("/");
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.ac = this.ac.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);

    this.scrollEl = React.createRef();

    this.interval = setInterval(() => {
      const el = document.querySelector("#scroll-content .box");
      if (el) {
        this.scrollEl.current = el;
        if (this.state.data.length > 0) {
          clearInterval(this.interval);

          this.scrollEl.current.scroll({
            top: this.state.scrollY,
            behaviour: "smooth",
          });
          this.props.loading(false);
        }

        this.scrollEl.current.addEventListener(
          "scroll",
          debounce((event) => {
            this.props.updateOrderListData({
              data: this.state.data,
              page: this.state.page,
              scrollY: this.scrollEl.current.scrollTop,
            });
          }, 200)
        );
      }
    }, 300);
  }

  ac() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidMount() {
    //this.loadData();
    this.props.searchHandler2("");
    this.props.setSearchClient(0);
    this.props.closeMenu();

    //console.log(this.props.orderlists);
  }

  loadData(reset) {
    //    if (this.props.location.pathname !== "/producten") {
    let page = 0;
    if (reset) {
      this.setState({ data: [], hasMoreItems: true, page: 0 });
    } else {
      console.log(this.props);
      page = this.state.page;
    }

    //this.setState({ data: [] });

    if (!this.state.pageLoading) {
      this.setState({ pageLoading: true });
      fetch(
        settings.server_url +
          "get_orderlist/" +
          window.sessionStorage.getItem("sid") +
          "/" +
          this.props.match.params.clientid +
          "/" +
          this.props.match.params.id,
        {
          method: "POST",
          body: JSON.stringify({
            page: page,
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          let products = [...this.state.data, ...response];
          this.setState({ data: products, page: page + 1, pageLoading: false });

          const el = document.querySelector(".box");
          this.props.updateOrderListData({
            data: products,
            page: page + 1,
            scrollY: el.scrollTop,
          });

          if (page === 0) {
            if (parseInt(response[0].bestellijstgroup_default_sort) === 2) {
              this.setState({ manual: true });
            } else {
              this.setState({ manual: false });
            }

            this.props.addBreadcrumb([
              { name: "Bestellijsten", url: "", reset: true },
              {
                name: response[0].bestellijstgroup_name,
                url:
                  response[0].bestellijstgroup_id +
                  "/" +
                  slugify(response[0].bestellijstgroup_name),
                reset: false,
                level: 2,
              },
            ]);
          }
          this.props.closeOrderlists();
          this.props.loading(false);
          if (response.length === 0) {
            this.setState({ hasMoreItems: false });
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.listID !== this.state.listID) {
      // Debounced function
      this.loadData(true);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.listID) {
      return { listID: nextProps.match.params.id };
    } else return null;
  }

  renderView({ style, ...props }) {
    //const { top } = this.state;
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  onFileChange = (e, file) => {
    //console.log(e.target.files);
    if (e.target.files[0] !== undefined) {
      this.setState({
        uploadFiles: e.target.files,
        filename: e.target.files[0].name,
      });
    } else {
      this.setState({ uploadFiles: [], filename: "" });
    }
  };

  onClientChange = (e) => {
    this.setState({ uploadClient: e.target.value });
  };

  uploadFiles = () => {
    if (this.state.uploadFiles.length > 0) {
      this.props.loading(true);

      const formData = new FormData();
      //console.log(this.state.uploadFiles[0]);
      formData.append("file", this.state.uploadFiles[0]);

      fetch(
        settings.server_url +
          "import_orderlist/" +
          window.sessionStorage.getItem("sid") +
          "/" +
          this.state.uploadClient,
        {
          method: "POST",
          body: formData,
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.result) {
            this.props.loadCart();
            this.setState({
              uploadSuccess:
                response.added +
                " van " +
                response.count +
                " regels toegevoegd.",
              uploadError: "",
            });
          } else {
            this.setState({
              uploadError: "Fout bij het uploaden van het bestand.",
              uploadSuccess: "",
            });
          }
          this.props.loading(false);
        })

        .catch((error) => console.error("Error:", error));
    }
  };

  editName = (e) => {
    e.preventDefault();
    if (this.state.editName) {
      this.setState({
        editName: false,
      });
    } else {
      this.setState({
        editName: true,
        name:
          this.state.data.length > 0
            ? this.state.data[0].bestellijstgroup_name
            : "",
      });
    }
  };

  changeName = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  };

  saveName = (e) => {
    let newdata = this.state.data;
    newdata[0].bestellijstgroup_name = this.state.name;
    this.setState({ data: newdata, editName: false });
    fetch(
      settings.server_url +
        "set_orderlist_name/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id +
        "/" +
        this.state.name
    )
      .then(() => {
        this.props.loadOrderlists();
        //this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  };

  saveSortType = () => {
    fetch(
      settings.server_url +
        "set_orderlist_sorttype/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id +
        "/" +
        (this.state.manual ? 2 : 0)
    )
      .then(() => {
        this.loadData(true);
        //this.props.loadOrderlists();
        //this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  };

  saveSortItems = (sortarray) => {
    fetch(
      settings.server_url +
        "set_orderlist_custom_sort/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id,
      {
        method: "POST",
        body: JSON.stringify({
          sortdata: sortarray,
        }),
      }
    )
      .then(() => {
        // this.loadData(true);
        //this.props.loadOrderlists();
        //this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  };

  handleCommentItem = (product, comment) => {
    //console.log(product.product_id, comment);
    this.props.loading(true);
    fetch(
      settings.server_url +
        "set_orderlist_item_comment/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        product.bestellijst_id,
      {
        method: "POST",
        body: JSON.stringify({
          comment: comment,
        }),
      }
    )
      .then(() => {
        this.loadData(true);
        //this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  };

  handleCopyItem = (e, product) => {
    e.preventDefault();
    if (window.confirm("Weet u zeker dat u deze regel wilt kopieëren?")) {
      this.props.loading(true);
      fetch(
        settings.server_url +
          "set_copy_orderlist_item/" +
          window.sessionStorage.getItem("sid") +
          "/" +
          product.bestellijst_id
      )
        .then(() => {
          this.loadData(true);
          //this.props.loading(false);
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  handleRemoveItem = (e, product) => {
    e.preventDefault();

    if (
      window.confirm(
        "Weet u zeker dat u dit artikel van de lijst wilt verwijderen?"
      )
    ) {
      this.props.loading(true);
      fetch(
        settings.server_url +
          "set_remove_from_orderlist/" +
          window.sessionStorage.getItem("sid") +
          "/" +
          this.props.match.params.id +
          "/" +
          product.product_id
      )
        .then((res) => res.json())
        .then((response) => {
          //this.setState({ data: response, pageLoading: false });
          let list = this.state.data;
          list.splice(product.index, 1);
          this.setState({ data: list });

          this.props.loading(false);
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  handleReplaceItem = (e, product) => {
    e.preventDefault();

    if (
      window.confirm(
        "Weet u zeker dat u dit artikel\n\nArtikelnummer: " +
          product.product_ext_code +
          "\nProduct: " +
          product.product_name +
          "\n\nvan de lijst wilt vervangen voor\n\nArtikelnummer: " +
          product.replaced_ext_code +
          "\nProduct: " +
          product.replaced_name +
          "?"
      )
    ) {
      this.props.loading(true);
      fetch(
        settings.server_url +
          "set_replace_product/" +
          window.sessionStorage.getItem("sid") +
          "/" +
          product.bestellijst_id +
          "/" +
          product.product_id +
          "/" +
          product.replaced_id
      )
        .then((response) => {
          this.loadData(true);
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  handlePrintOrderlist = (e) => {
    e.preventDefault();
    window.open(
      settings.server_url +
        "get_export_orderlist/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.clientid +
        "/" +
        this.props.match.params.id +
        "/pdf",
      "_blank"
    );
  };

  handleExportOrderlist = (e) => {
    e.preventDefault();
    window.open(
      settings.server_url +
        "get_export_orderlist/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.clientid +
        "/" +
        this.props.match.params.id +
        "/csv",
      "_blank"
    );
  };

  handleRemoveList = (e) => {
    e.preventDefault();

    if (window.confirm("Weet u zeker dat u deze lijst wilt verwijderen?")) {
      this.props.loading(true);
      fetch(
        settings.server_url +
          "set_remove_orderlist/" +
          window.sessionStorage.getItem("sid") +
          "/" +
          this.props.match.params.id
      )
        .then((response) => {
          this.setState({ data: [], name: "verwijderd" });
          this.props.loadOrderlists();
          this.props.loading(false);
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  toggleSort = (e) => {
    e.preventDefault();
    this.setState({ manual: !this.state.manual }, () => {
      this.saveSortType();
    });
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    //console.log(result);

    const items = reorder(
      this.state.data,
      result.source.index,
      result.destination.index
    );

    this.setState({
      data: items,
    });

    let sortData = [];
    items.map((item) => {
      sortData.push(item.bestellijst_id);
      return true;
    });
    // Save sort
    this.saveSortItems(sortData);
  }

  render() {
    if (!this.props.isAuthenticated) return null;
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbVertical={this.renderThumb}
        renderTrackHorizontal={(props) => (
          <div {...props} className="track-horizontal" />
        )}
        id="scroll-content"
        /*onUpdate={this.handleUpdate}*/ className="row content-bc ml-0">
        <InfiniteScroll
          id="test"
          pageStart={0}
          threshold={65}
          initialLoad={this.props.orderListData.length === 0}
          loadMore={() => {
            this.loadData(false);
          }}
          hasMore={this.state.hasMoreItems}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          useWindow={false}
          className="col pt-md-0 pt-md-2 px-0 px-md-4 maxw1200">
          <Row className="p-lg-2">
            <Col className="col-12">
              <div className="">
                <div className="fixedmenu">
                  <div className="bestellijstacties pb-3">
                    <div className="justify-content-between row">
                      <div className="col-md-7 col-12 px-3 mb-2">
                        <div className="align-items-center d-md-flex flex-column flex-md-row w-100">
                          <label className="col-form-label mr-3">
                            <b>BESTELLIJST:</b>
                          </label>
                          <div className="btn-group btn-group-sm col p-0">
                            <button
                              className="align-items-center px-3 py-2  btn btn-outline-primary border-gray d-flex justify-content-center text-uppercase"
                              type="button"
                              onClick={this.ac}>
                              <i className="fal fa-file-import mr-2" />
                              <span className="d-none d-md-block font-weight-bold">
                                Importeren
                              </span>
                            </button>
                            <Modal isOpen={this.state.modal} toggle={this.ac}>
                              <ModalHeader>Importeren</ModalHeader>
                              <ModalBody>
                                {this.state.uploadError !== "" ? (
                                  <div className="alert alert-danger">
                                    {this.state.uploadError}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.uploadSuccess !== "" ? (
                                  <div className="alert alert-success">
                                    {this.state.uploadSuccess}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="input-group">
                                  <Input
                                    type="select"
                                    name="select"
                                    className="custom-select input-group-append input-group-prepend rounded-0"
                                    value={this.state.uploadClient}
                                    onChange={this.onClientChange}>
                                    <option value="10">Koel &amp; Vries</option>
                                    <option value="11">
                                      Centraal magazijn
                                    </option>
                                    <option value="54">Dagvers</option>
                                    <option value="69">Versmarkt</option>
                                    <option value="61">Vis</option>
                                  </Input>
                                  <div className="custom-file w-50">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="inputGroupFile"
                                      onChange={this.onFileChange}
                                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      ref="input"
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="inputGroupFile">
                                      {this.state.filename > ""
                                        ? this.state.filename
                                        : "Selecteer een bestand"}
                                    </label>
                                  </div>
                                  <div className="input-group-append">
                                    <span
                                      className="btn-success btn"
                                      onClick={this.uploadFiles}>
                                      Upload
                                    </span>
                                  </div>
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <Button color="secondary" onClick={this.ac}>
                                  Sluiten
                                </Button>
                              </ModalFooter>
                            </Modal>
                            <button
                              className="align-items-center px-3 py-2 btn btn-outline-primary border-gray d-flex justify-content-center text-uppercase"
                              type="button"
                              onClick={this.handleExportOrderlist}>
                              <i className="fal fa-file-export mr-2" />
                              <span className="d-none d-md-block font-weight-bold">
                                Exporteren
                              </span>
                            </button>
                            <button
                              className="align-items-center px-3 py-2 btn btn-outline-primary border-gray d-flex justify-content-center text-uppercase"
                              type="button"
                              onClick={this.handlePrintOrderlist}>
                              <i className="fal fa-print mr-2" />
                              <span className="d-none d-md-block font-weight-bold">
                                Afdrukken
                              </span>
                            </button>
                            <button
                              className="align-items-center px-3 py-2 btn btn-outline-primary border-gray d-flex justify-content-center text-uppercase"
                              type="button"
                              onClick={this.toggleSort}>
                              <i className="fal fa-sort mr-2" />
                              <span className="d-none d-md-block font-weight-bold">
                                {this.state.manual
                                  ? "automatische sortering"
                                  : "handmatige sortering"}
                              </span>
                            </button>
                            <button
                              className="align-items-center px-3 py-2 btn btn-outline-danger border-gray d-flex justify-content-center text-uppercase"
                              type="button"
                              onClick={this.handleRemoveList}>
                              <i className="fal fa-trash-alt mr-2" />
                              <span className="d-none d-md-block font-weight-bold">
                                Verwijderen
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {this.state.data.length > 0 ? (
              <Col className="col-12">
                {this.state.editName ? (
                  <div className="orderlist_head form-inline">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.name}
                      onChange={this.changeName}
                    />
                    <button
                      className="form-control btn btn-success"
                      onClick={this.saveName}>
                      <i className="far fa-check" />
                    </button>
                    <button
                      className="form-control btn btn-dark"
                      onClick={this.editName}>
                      <i className="far fa-times" />
                    </button>
                  </div>
                ) : (
                  <div className="orderlist_head">
                    <span className="mr-3">
                      {this.state.data.length > 0
                        ? this.state.data[0].bestellijstgroup_name
                        : ""}
                    </span>
                    <a href="#" onClick={this.editName}>
                      <i className="fal fa-edit" />
                    </a>
                  </div>
                )}

                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="content-block dragable">
                        {this.state.data.map((product, index) => {
                          product.index = index;
                          let prod1;

                          prod1 =
                            index - 1 >= 0
                              ? this.state.data[index - 1]
                              : this.state.data[index];

                          let prod2 = this.state.data[index];
                          if (
                            (prod1.category_1_1 !== prod2.category_1_1 ||
                              index === 0) &&
                            !this.state.manual
                          ) {
                            return (
                              <React.Fragment key={index}>
                                <div className="category_seperator">
                                  <i className="fas fa-folder-open mr-3" />
                                  {product.category_1_1}
                                </div>
                                <Draggable
                                  key={product.product_id}
                                  draggableId={product.product_id}
                                  index={index}>
                                  {(provided, snapshot) => (
                                    <div ref={provided.innerRef} key={index}>
                                      <Product
                                        history={this.props.history}
                                        product={product}
                                        scrollPos={window.scrollTop}
                                        cart={this.props.cart}
                                        cartHandler={this.props.cartHandler}
                                        showListButton={false}
                                        deleteHandler={this.handleRemoveItem}
                                        replaceHandler={this.handleReplaceItem}
                                        copyHandler={this.handleCopyItem}
                                        commentHandler={this.handleCommentItem}
                                        username={this.props.username}
                                        provided={
                                          this.state.manual ? provided : null
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <Draggable
                                key={product.product_id}
                                draggableId={product.product_id}
                                index={index}>
                                {(provided, snapshot) => (
                                  <div ref={provided.innerRef} key={index}>
                                    <Product
                                      history={this.props.history}
                                      product={product}
                                      scrollPos={window.scrollTop}
                                      cart={this.props.cart}
                                      cartHandler={this.props.cartHandler}
                                      showListButton={false}
                                      deleteHandler={this.handleRemoveItem}
                                      replaceHandler={this.handleReplaceItem}
                                      copyHandler={this.handleCopyItem}
                                      commentHandler={this.handleCommentItem}
                                      provided={
                                        this.state.manual ? provided : null
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Col>
            ) : (
              <Col>Deze bestelijst is leeg of bestaat niet meer.</Col>
            )}
          </Row>
        </InfiniteScroll>
      </Scrollbars>
    );
  }
}

export default Bestellijsten;
