import React, { Component } from "react";
import { Link } from "react-router-dom";
import AnimateHeight from "react-animate-height";

import Orderlist from "../components/orderlistmodal.js";
import Comment from "../components/comment.js";

import slugify from "../lib.js";
import Quote from "./quote.js";

class Product extends Component {
  constructor(props) {
    super(props);

    let cartix = this.findCartIndexByID(props.product.product_id, props.cart);
    //console.log(props.product.product_id, props.cart);
    //console.log("cartix", cartix);
    if (
      props.product.bestellijst_id &&
      parseInt(props.product.bestellijst_id) > 0
    ) {
      cartix = this.findCartIndexByListID(props.product.bestellijst_id);
    }
    const quantity = cartix !== false ? props.cart[cartix].quantity : 0;

    const comment =
      cartix !== false && props.checkoutCommentHandler
        ? props.cart[cartix].comment
        : props.product.bestellijst_cust_desc;

    let weight = 0;
    //console.log(props.cart);
    if (cartix !== false) {
      if (props.cart[cartix].weight) {
        weight = props.cart[cartix].weight;
      } else {
        weight = 0;
      }
    } else if (props.product.product_is_weight === 1) {
      weight = this.props.product.product_weight;
    }

    const isWeight = props.product.product_is_weight === 1 ? true : false;

    let tw = 0;
    if (isWeight) {
      tw = quantity * weight;
    }

    this.state = {
      quantity: quantity,
      weight: parseFloat(weight).toFixed(3).toString().replace(".", ","),
      isFixedWeight: this.props.product.fixed_weight
        ? parseInt(this.props.product.fixed_weight) === 1
        : this.props.product.product_weight > 0,
      totalWeight: tw.toFixed(3),
      showweight: 0,
      stock:
        parseInt(this.props.product.product_stock_f) -
        parseInt(this.props.product.product_stock_r),
      comment: comment,
      copiedWidth: "0px",
      unitID:
        this.props.product.units && this.props.product.units.length > 0
          ? parseInt(this.props.product.units[0].pu_id)
          : 0,
      unit:
        this.props.product.units && this.props.product.units.length > 0
          ? parseInt(this.props.product.units[0].pu_quantity)
          : 0,
    };

    this.copyWidth = React.createRef();

    this.timer = null;
  }

  componentDidMount = () => {
    let copy = this.copyWidth.current.offsetWidth;
    this.setState({ copiedWidth: copy + "px" });
    window.addEventListener("resize", this.resizeReplace);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeReplace);
  }

  resizeReplace = () => {
    let copy = this.copyWidth.current.offsetWidth;
    this.setState({ copiedWidth: copy + "px" });
  };

  opOfferte = () => {
    let r = false;
    const product = this.props.product;
    if (product.attributen !== undefined && product.attributen !== null) {
      product.attributen.map((a) => {
        if (a["Offerte"] && parseInt(a["Offerte"]) === 1) {
          r = true;
          return r;
        } else return false;
      });
    } else if (product.Offerte_s && parseInt(product.Offerte_s) === 1) {
      r = true;
      return r;
    }
    return r;
  };

  nietLeverbaar = () => {
    let r = false;
    const product = this.props.product;
    if (product.attributen !== undefined && product.attributen !== null) {
      product.attributen.map((a) => {
        if (
          a["Tijdelijk niet leverbaar"] &&
          parseInt(a["Tijdelijk niet leverbaar"]) === 1
        ) {
          r = true;
          return r;
        } else return false;
      });
    } else if (
      product.Tijdelijk_niet_leverbaar_s &&
      parseInt(product.Tijdelijk_niet_leverbaar_s) === 1
    ) {
      r = true;
      return r;
    }
    return r;
  };

  nietLeverbaarTekst = () => {
    let r = "";
    const product = this.props.product;
    if (product.attributen !== undefined && product.attributen !== null) {
      product.attributen.map((a) => {
        if (a["Tekst Niet leverbaar"] && a["Tekst Niet leverbaar"] !== "") {
          r = a["Tekst Niet leverbaar"];
          return r;
        } else {
          return false;
        }
      });
    } else if (
      product.Tekst_Niet_leverbaar_s &&
      product.Tekst_Niet_leverbaar_s > ""
    ) {
      r = product.Tekst_Niet_leverbaar_s;
    }
    return r;
  };

  setRef = (ref) => {
    // keep a reference to the dom ref as an instance property
    this.ref = ref;
    // give the dom ref to react-beautiful-dnd
    if (this.props.provided !== null) {
      this.props.provided.innerRef(ref);
    }
  };

  inc = (e, product) => {
    e.preventDefault();

    if (this.props.product.product_is_weight === 1) {
      this.toggleweight("open");
    }

    const w = parseFloat(this.state.weight.replace(",", "."));
    if (
      this.props.product.product_is_weight === 1 &&
      !this.state.isFixedWeight &&
      w === 0 &&
      this.state.quantity === 0
    ) {
      setTimeout(() => {
        alert("U dient eerst een (portie)gewicht in te geven.");
        this.weightInput.focus();
        this.weightInput.select();
      }, 400);
    } else {
      if (w === 0 && this.props.product.product_is_weight === 1) {
        // this.toggleweight("open");
      } else {
        const q = this.state.quantity + Math.max(parseInt(this.state.unit), 1);
        // console.log(Math.max(parseInt(this.state.unit), 1));

        const currentq =
          this.state.quantity + Math.max(parseInt(this.state.unit), 1);
        if (
          parseInt(this.props.product.client_id_i) === 11 &&
          parseInt(this.props.product.product_delivery_type) !== 1 &&
          parseInt(this.props.product.supplier_module_partner) !== 1
        ) {
          if (currentq <= this.state.stock) {
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              this.props.cartHandler(
                "UPDATE",
                this.props.product,
                q,
                w,
                this.state.comment,
                Math.max(parseInt(this.state.unit), 1) > 1
                  ? 0
                  : this.state.unitID
              );
            }, 500);

            this.setState({
              quantity: q,
              totalWeight: (q * w).toFixed(3).toString().replace(",", "."),
            });
          } else if (this.state.stock > 0) {
            alert(
              "Er zijn niet meer dan " + this.state.stock + " stuks beschikbaar"
            );
          } else {
            alert("Er is helaas geen voorraad van dit artikel");
          }
        } else {
          //console.log("inc", Math.max(parseInt(this.state.unit), 1));
          if (this.timer) clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.props.cartHandler(
              "UPDATE",
              this.props.product,
              q,
              w,
              this.state.comment,
              Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
            );
          }, 500);

          //console.log("quantity", q);
          this.setState({
            quantity: q,
            totalWeight: (q * w).toFixed(3).toString().replace(",", "."),
          });
        }
      }
    }
  };

  dec = (e, product) => {
    e.preventDefault();
    const w = parseFloat(this.state.weight.replace(",", "."));
    //    console.log(this.state.quantity, "unit", this.state.unit);
    if (
      this.state.quantity +
        (parseInt(this.state.unit) > 0
          ? Math.max(parseInt(this.state.unit), 1)
          : 0) >
      0
    ) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.cartHandler(
          "UPDATE",
          this.props.product,
          q,
          w,
          this.state.comment,
          Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
        );
      }, 500);

      const q = this.state.quantity - Math.max(parseInt(this.state.unit), 1);

      this.setState({
        quantity: q < 0 ? 0 : q,
        totalWeight: (q * w).toFixed(3).toString().replace(",", "."),
      });
    }
  };

  /*
  updateCartComment = (e) => {
    e.preventDefault();
    const w = parseFloat(this.state.weight.replace(",", "."));
    if (this.state.quantity > 0) {
      this.props.cartHandler(
        "DEC",
        this.props.product,
        0,
        this.state.weight,
        this.state.comment
      );
    }
  };
  */

  removeHandler = (e) => {
    e.preventDefault();
    this.props.deleteHandler(e, this.props.product);
  };

  replaceHandler = (e) => {
    e.preventDefault();
    this.props.replaceHandler(e, this.props.product);
  };

  copy = (e) => {
    e.preventDefault();
    this.props.copyHandler(e, this.props.product);
  };

  getQuote = (e) => {
    e.preventDefault();
  };

  changeHandler = (e) => {
    //console.log(this.state.weight);
    const w = parseFloat(this.state.weight.replace(",", "."));
    if (
      this.props.product.product_is_weight === 1 &&
      !this.state.isFixedWeight &&
      w === 0 &&
      this.state.quantity === 0
    ) {
      alert("U dient eerst een (portie)gewicht in te geven.");
      this.weightInput.focus();
      this.weightInput.select();
    } else {
      if (!isNaN(parseInt(e.target.value))) {
        if (
          parseInt(this.props.product.client_id_i) === 11 &&
          parseInt(this.props.product.product_delivery_type) !== 1 &&
          parseInt(this.props.product.supplier_module_partner) !== 1
        ) {
          if (parseInt(e.target.value) > this.state.stock) {
            e.target.value = this.state.stock;
            alert(
              "Er zijn niet meer dan " + this.state.stock + " stuks beschikbaar"
            );
          }
        }
        if (
          this.props.product.product_is_weight === 1 &&
          this.state.quantity === 0
        ) {
          this.toggleweight("open");
        }
        const tempq = parseInt(e.target.value);
        this.setState({
          quantity: tempq,
        });
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.props.cartHandler(
            "UPDATE",
            this.props.product,
            tempq,
            w,
            this.state.comment,
            Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
          );
        }, 500);
      } else {
        this.setState({
          quantity: 0,
        });
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.props.cartHandler(
            "REMOVE",
            this.props.product,
            1,
            w,
            this.state.comment,
            Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
          );
        }, 500);
      }
      let q = parseInt(e.target.value);
      if (isNaN(q)) {
        q = 0;
        //console.log(q);
      }
      this.setState({
        totalWeight: (q * w).toFixed(3).toString().replace(",", "."),
      });
    }
  };

  weightHandler = (e) => {
    e.preventDefault();

    this.setState({ weight: e.target.value });
  };

  weightBlurHandler = (e) => {
    e.preventDefault();

    let w = parseFloat(e.target.value.replace(",", ".")).toFixed(3);
    if (isNaN(w)) w = 0;
    this.setState({ weight: w.toString().replace(".", ",") });

    this.recalcWeights();
  };

  weightKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.totalweightInput.focus();
      this.totalweightInput.select();
    }
  };

  totalWeightHandler = (e) => {
    e.preventDefault();

    this.setState({ totalWeight: e.target.value, quantity: 0 });
  };

  totalWeightBlurHandler = (e) => {
    e.preventDefault();

    this.recalcWeights();
  };

  totalWeightKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.recalcWeights();
      this.closeweight(e);
    }
  };

  recalcWeights() {
    let w = parseFloat(this.state.weight.toString().replace(",", "."));
    let tw = parseFloat(this.state.totalWeight.toString().replace(",", "."));
    let q = this.state.quantity;

    tw = Math.abs(tw);
    if (w > 0 && q === 0) {
      q = Math.ceil(tw / w);
    } else if (w > 0 && q > 0) {
      tw = q * w;
    }

    this.setState(
      {
        quantity: q,
        totalWeight: tw.toFixed(3).toString().replace(".", ","),
      },
      () => {
        //this.totalweightInput.focus();
        //this.totalweightInput.select();
      }
    );

    this.props.cartHandler(
      "UPDATE",
      this.props.product,
      q,
      w,
      this.state.comment,
      Math.max(parseInt(this.state.unit), 1) > 1 ? 0 : this.state.unitID
    );
  }

  toggleweight = (e) => {
    if (e === "open") {
      this.setState({ showweight: "auto" }, () => {
        if (this.state.isFixedWeight) {
          //this.totalweightInput.select();
        } else {
          //this.weightInput.select();
        }
      });
    } else {
      if (this.state.showweight === 0) {
        this.setState({ showweight: "auto" }, () => {
          if (this.state.isFixedWeight) {
            //  this.totalweightInput.select();
          } else {
            //            this.weightInput.select();
          }
        });
      } else {
        this.setState({ showweight: 0 });
      }
    }
  };

  closeweight = (e) => {
    e.preventDefault();
    this.setState({ showweight: 0 });
  };

  saveComment = (comment) => {
    this.props.commentHandler(this.props.product, comment);
  };

  saveCheckoutComment = (comment) => {
    const w = parseFloat(this.state.weight.replace(",", "."));

    this.props.checkoutCommentHandler(
      this.props.product,
      this.state.quantity,
      w,
      comment
    );
  };

  setUnit = (e) => {
    this.setState({ unit: e.target.value });
  };

  createMarkup(txt) {
    return { __html: txt };
  }

  priceMarkup(x) {
    let parts = x.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  findCartIndexByID(id) {
    if (this.props.cart !== null) {
      for (let i = 0; i < this.props.cart.length; i++) {
        if (
          this.props.cart[i] !== null &&
          parseInt(this.props.cart[i].product_id) === parseInt(id)
        ) {
          return i;
        }
      }
    }

    return false;
  }

  findCartIndexByListID(id) {
    if (this.props.cart !== null) {
      for (let i = 0; i < this.props.cart.length; i++) {
        if (
          this.props.cart[i] !== null &&
          parseInt(this.props.cart[i].bestellijst_id) === parseInt(id)
        ) {
          return i;
        }
      }
    }

    return false;
  }

  render() {
    const { provided } = this.props;
    const product = this.props.product;
    let stock = this.state.stock;
    let stockstyle = "";
    switch (true) {
      case stock >= 20:
        stockstyle = "stock green";
        break;
      case stock > 0:
        stockstyle = "stock orange";
        break;
      default:
        stockstyle = "stock red";
    }

    let special = "";
    if (product.Valkweb_label_s && product.Valkweb_label_s.trim() !== "") {
      special = (
        <span
          className="badge badge-warning"
          style={{ position: "absolute", left: "5px", top: "5px" }}>
          {product.Valkweb_label_s.trim()}
        </span>
      );
    }
    let clientBadge;
    switch (parseInt(product.client_id_i)) {
      case 10:
        clientBadge = <span className="badge badge-success">KV</span>;
        break;
      case 11:
        clientBadge = <span className="badge badge-primary">DC</span>;
        break;
      case 29:
        clientBadge = <span className="badge badge-warning">AP</span>;
        break;
      case 54:
        clientBadge = <span className="badge badge-info">DV</span>;
        break;
      case 69:
        clientBadge = <span className="badge badge-danger">VM</span>;
        break;
      case 61:
        clientBadge = (
          <span
            className="badge badge-danger"
            style={{ backgroundColor: "lightblue" }}>
            VIS
          </span>
        );
        break;
      default:
        clientBadge = <span className="badge">?</span>;
    }

    let cartix = false;
    if (product.bestellijst_id > 0) {
      cartix = this.findCartIndexByListID(
        product.bestellijst_id,
        this.props.cart
      );
    } else {
      cartix = this.findCartIndexByID(product.product_id, this.props.cart);
    }

    const c_comment =
      cartix !== false && this.props.checkoutCommentHandler
        ? this.props.cart[cartix].comment
        : this.state.comment;
    //const quantity = cartix !== false ? this.props.cart[cartix].quantity : 0;
    const quantity = this.state.quantity;
    //console.log("this.state.quantity", quantity);
    //console.log("this.props.cart[cartix].quantity", this.props.cart[cartix].quantity)
    //const weight = cartix !== false ? this.props.cart[cartix].weight : 0;
    const isWeight = product.product_is_weight === 1 ? true : false;

    return (
      <div
        className="product px-2"
        key={product.product_id}
        {...(provided && provided.draggableProps !== undefined
          ? provided.draggableProps
          : null)}
        {...(provided && provided.dragHandleProps !== undefined
          ? provided.dragHandleProps
          : null)}
        ref={provided ? this.setRef : null}>
        <div
          className={
            parseInt(product.product_active) !== 1 ||
            parseInt(product.product_no_webshop) === 0
              ? "float-left w-100"
              : ""
          }>
          <div className="phototitle">
            <div className="photo">
              {parseInt(product.product_active) === 1 &&
              parseInt(product.product_no_webshop) !== 1 ? (
                <Link
                  to={
                    "/producten/details/" +
                    product.product_id +
                    "/" +
                    slugify(product.product_name)
                  }
                  tabIndex="-1">
                  {product.image > "" ? (
                    <img src={product.image} alt="" />
                  ) : (
                    <span className="image_placeholder" />
                  )}
                  {special}
                </Link>
              ) : product.image > "" ? (
                <img src={product.image} alt="" />
              ) : (
                <span className="image_placeholder" />
              )}
            </div>
            <div className="title px-2">
              {parseInt(product.product_active) === 1 &&
              parseInt(product.product_no_webshop) !== 1 ? (
                <Link
                  to={
                    "/producten/details/" +
                    product.product_id +
                    "/" +
                    slugify(product.product_name)
                  }
                  tabIndex="-1">
                  <strong
                    className={
                      product.replaced_id && product.replaced_id !== null
                        ? "strikethrough"
                        : ""
                    }
                    dangerouslySetInnerHTML={this.createMarkup(
                      product.product_name
                    )}
                  />
                </Link>
              ) : (
                <strong
                  className={
                    product.replaced_id && product.replaced_id !== null
                      ? "strikethrough"
                      : ""
                  }
                  dangerouslySetInnerHTML={this.createMarkup(
                    product.product_name
                  )}
                />
              )}
              <br />
              {clientBadge} art.nr. {product.product_ext_code}
              &nbsp;
              {c_comment > "" ? (
                <span className="text-primary">{c_comment}</span>
              ) : (
                ""
              )}
              &nbsp;
              {parseInt(product.product_delivery_type) === 1 ? (
                <span className="text-danger">bestel</span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="cartprice" ref={this.copyWidth}>
            <div
              className={
                (product.product_active &&
                  parseInt(product.product_active) !== 1) ||
                parseInt(product.product_no_webshop) === 1 ||
                this.nietLeverbaar()
                  ? !this.props.isCart
                    ? "cart-buttons mx-md-3 mx-2 invisible"
                    : "cart-buttons mx-md-3 mx-2"
                  : "cart-buttons mx-md-3 mx-2"
              }>
              {this.props.commentHandler ? (
                <Comment
                  comment={this.state.comment}
                  saveHandler={this.saveComment}
                />
              ) : (
                ""
              )}
              {this.props.checkoutCommentHandler ? (
                <Comment
                  comment={this.state.comment}
                  saveHandler={this.saveCheckoutComment}
                />
              ) : (
                ""
              )}
              {this.props.copyHandler ? (
                <a
                  href="#"
                  tabIndex="-1"
                  title="Kopieer deze regel"
                  className="a-copy"
                  onClick={this.copy}>
                  <i className="fal fa-copy" />
                </a>
              ) : (
                ""
              )}
              {this.props.deleteHandler ? (
                <a
                  href="#"
                  title="Verwijder deze regel"
                  tabIndex="-1"
                  className="a-remove"
                  onClick={this.removeHandler}>
                  <i className="fal fa-trash-alt" />
                </a>
              ) : (
                ""
              )}{" "}
              {this.props.showListButton ? (
                <Orderlist product={product} />
              ) : null}
              <div className="actionButtons">
                {!this.opOfferte() ? (
                  <>
                    <div className="float-left ">
                      <div
                        className={
                          product.product_is_weight === 1
                            ? "a-weight"
                            : "a-weight disabled"
                        }
                        onClick={
                          product.product_is_weight === 1
                            ? this.toggleweight
                            : null
                        }>
                        <i className="far fa-balance-scale" />
                      </div>
                    </div>
                    <a
                      href="#"
                      tabIndex="-1"
                      className={quantity > 0 ? "a-min active" : "a-min"}
                      onClick={(e, product) => {
                        this.dec(e, product);
                      }}>
                      -
                    </a>
                    <input
                      type="text"
                      className={
                        quantity > 0 ? "input-cart active" : "input-cart"
                      }
                      onChange={this.changeHandler}
                      onClick={() => {
                        this.mainQty.select();
                      }}
                      ref={(mainQty) => {
                        this.mainQty = mainQty;
                      }}
                      placeholder="0"
                      value={quantity}
                    />
                    <a
                      href="#"
                      tabIndex="-1"
                      className={quantity > 0 ? "a-plus active" : "a-plus"}
                      onClick={(e, product) => {
                        this.inc(e, product);
                      }}>
                      +
                    </a>
                  </>
                ) : (
                  <div className="float-right">
                    <Quote
                      id={product.product_id}
                      name={product.product_name}
                      username={this.props.username}
                    />
                  </div>
                )}
              </div>
            </div>

            {this.props.isCart !== true &&
            (parseInt(product.product_active) !== 1 ||
              (product.product_no_webshop &&
                parseInt(product.product_no_webshop) === 1) ||
              (product.product_no_webshop_i &&
                parseInt(product.product_no_webshop_i) === 1) ||
              this.nietLeverbaar()) ? (
              <div className="align-items-center cart-buttons d-flex mx-2 mx-md-3 position-absolute">
                {this.nietLeverbaar() &&
                (parseInt(product.product_active) === 1 ||
                  (product.product_no_webshop &&
                    parseInt(product.product_no_webshop) === 0) ||
                  (product.product_no_webshop_i &&
                    parseInt(product.product_no_webshop_i) === 0)) ? (
                  <React.Fragment>
                    {this.nietLeverbaarTekst() !== "" ? (
                      <span className="float-left mr-3 text-dimgrey">
                        {this.nietLeverbaarTekst()}
                      </span>
                    ) : (
                      <span className="float-left mr-3 text-dimgrey">
                        Tijdelijk niet leverbaar
                      </span>
                    )}
                  </React.Fragment>
                ) : (
                  <span className="float-left mr-3 text-crimson">
                    Niet meer in assortiment
                  </span>
                )}
                {this.props.deleteHandler ? (
                  <a
                    href="#"
                    title="Verwijder deze regel"
                    tabIndex="-1"
                    className="a-remove"
                    onClick={this.removeHandler}>
                    <i className="fal fa-trash-alt" />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <div className="float-right mx-3 mx-md-0 d-flex align-items-center">
              {(parseInt(product.client_id_i) === 10 ||
                parseInt(product.client_id_i) === 11) &&
              parseInt(this.props.product.product_delivery_type) !== 1 &&
              parseInt(this.props.product.supplier_module_partner) !== 1 ? (
                <div className={stockstyle} />
              ) : null}
              <div className="unit">
                <select defaultValue={this.state.unit} onChange={this.setUnit}>
                  {product.units && product.units.length > 0 && !isWeight ? (
                    product.units.map((unit) => {
                      return (
                        <option
                          key={unit.pu_id}
                          value={
                            unit.pu_quantity
                          }>{`${unit.unit_desc} (${unit.pu_quantity})`}</option>
                      );
                    })
                  ) : (
                    <option>
                      {isWeight && parseFloat(product.product_weight) !== 0
                        ? parseFloat(product.product_weight)
                            .toString()
                            .replace(".", ",") + "KILO"
                        : product.product_unit !== ""
                        ? product.product_unit
                        : "STUK"}
                    </option>
                  )}
                </select>
              </div>
              <div className="price px-2">
                <strong>&euro; {this.priceMarkup(product.calcprice)}</strong>
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix" />
        <AnimateHeight duration={500} height={this.state.showweight}>
          <div className="float-right heightfix">
            <table className="weighttable">
              <tbody>
                <tr className="text-center">
                  <th className="">Aantal</th>
                  <th className="" />
                  <th className="">Gewicht (KG)</th>
                  <th>Totaal</th>
                </tr>
                <tr>
                  <td className="weightinput">
                    <input
                      type="text"
                      onChange={this.changeHandler}
                      placeholder="0"
                      value={quantity}
                      className="d-inline form-control text-center"
                      onClick={(e) => {
                        this.qtyInput.select();
                      }}
                      ref={(qtyInput) => {
                        this.qtyInput = qtyInput;
                      }}
                    />
                  </td>
                  <td>X</td>
                  <td className="weightinput">
                    <input
                      type="text"
                      tabIndex={this.state.showweight ? "" : "-1"}
                      className="d-inline form-control text-right"
                      value={isWeight ? this.state.weight : 0}
                      disabled={isWeight && this.state.isFixedWeight}
                      onClick={(e) => {
                        this.weightInput.select();
                      }}
                      onChange={(e) => {
                        this.weightHandler(e);
                      }}
                      onBlur={(e) => {
                        this.weightBlurHandler(e);
                      }}
                      onKeyDown={this.weightKeyDownHandler}
                      ref={(weightInput) => {
                        this.weightInput = weightInput;
                      }}
                    />
                  </td>
                  <td className="weightinput">
                    <input
                      type="text"
                      tabIndex={this.state.showweight ? "" : "-1"}
                      className="d-inline form-control text-right"
                      value={isWeight ? this.state.totalWeight : 0}
                      disabled={!isWeight}
                      onClick={(e) => {
                        this.totalweightInput.select();
                      }}
                      onChange={(e) => {
                        this.totalWeightHandler(e);
                      }}
                      onBlur={(e) => {
                        this.totalWeightBlurHandler(e);
                      }}
                      onKeyDown={this.totalWeightKeyDownHandler}
                      ref={(totalweightInput) => {
                        this.totalweightInput = totalweightInput;
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </AnimateHeight>
        {parseInt(product.replaced_id) > 0 ? (
          <React.Fragment>
            <div className="clearfix" />
            <div
              className={
                parseInt(product.product_active) !== 1 ||
                parseInt(product.product_no_webshop) === 0
                  ? "float-left w-100"
                  : ""
              }>
              <div className="phototitle">
                <div className="photo">
                  {product.replaced_image > "" ? (
                    <img src={product.replaced_image} alt="" />
                  ) : (
                    <span className="image_placeholder" />
                  )}
                </div>
                <div className="title px-2">
                  <strong
                    dangerouslySetInnerHTML={this.createMarkup(
                      product.replaced_name
                    )}
                  />
                  <br />
                  {clientBadge} art.nr. {product.replaced_ext_code}
                </div>
              </div>
              <div
                className="cartprice justify-content-between"
                style={{
                  width: this.state.copiedWidth,
                }}>
                <div className="cart-buttons mx-md-3 mx-2">
                  <a
                    href="#"
                    title="Vervang deze regel"
                    tabIndex="-1"
                    className="a-remove px-4 w-auto text-decoration-none text-white bg-danger"
                    onClick={this.replaceHandler}>
                    <i className="fal fa-retweet text-white" />{" "}
                    <span className="ml-2 text-white">Vervangen</span>
                  </a>
                </div>
                <div className="float-right mx-3 mx-md-0">
                  <div className="price px-2">
                    <strong>
                      &euro;{" "}
                      {this.priceMarkup(parseInt(product.replaced_price))}
                    </strong>
                  </div>
                  <div className="unit">
                    <span>
                      {product.replaced_unit !== ""
                        ? product.replaced_unit
                        : "STUK"}
                    </span>
                  </div>
                  {(parseInt(product.client_id_i) === 10 ||
                    parseInt(product.client_id_i) === 11) &&
                  parseInt(this.props.product.product_delivery_type) !== 1 &&
                  parseInt(this.props.product.supplier_module_partner) !== 1 ? (
                    <div className="stock" />
                  ) : null}
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Product;
