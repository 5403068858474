import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Retouren from "./components/retouren";

class retouren extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  componentDidMount() {
    this.props.addBreadcrumb([
      { name: "Retouren", url: "/retouren", reset: true }
    ]);
  }

  renderView({ style, ...props }) {
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        /*onUpdate={this.handleUpdate}*/ className="row ml-0 content-bc"
      >
        <div className="col p-md-4 px-0">
          <div className="p-lg-2 row">
            <div className="col mr-lg-3">
              <div className="mb-4">
                <Retouren
                  addBreadcrumb={this.props.addBreadcrumb}
                  loading={this.props.loading}
                />
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default retouren;
