import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import "../lib/weeks.js";
import settings from "./../settings.js";

class Orderlists extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      top: 0,
      listName: "",
      division: 10,
      isHidden: true,
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  componentWillUnmount() {
    this.timer = null;
  }

  componentDidMount() {
    //this.loadOrderlists();
  }
  priceMarkup(x) {
    if (x != null) {
      let parts = x.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    } else {
      return "0,00";
    }
  }

  renderView({ style, ...props }) {
    const viewStyle = { paddingRight: "8px" };
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  handleUpdate(values) {
    //this.setState({ top });
  }

  makeURLFriendly(value) {
    return value === undefined
      ? ""
      : value
          .replace(/[^a-z0-9_]+/gi, "-")
          .replace(/^-|-$/g, "")
          .toLowerCase();
  }

  getBatchStyle(clientID, supplierID) {
    if (parseInt(supplierID) > 0) {
      return "badge badge-dark mr-2";
    } else {
      switch (parseInt(clientID)) {
        case 10:
          return "badge badge-success mr-2";
        case 11:
          return "badge badge-primary mr-2";
        case 29:
          return "badge badge-warning mr-2";
        case 54:
          return "badge badge-info mr-2";
        case 69:
          return "badge badge-danger mr-2";
        case 61:
          return "badge badge-danger mr-2";
        default:
          return "badge mr-2";
      }
    }
  }
  getBatchTitle(clientID, supplierID) {
    if (parseInt(supplierID) > 0) {
      return "P";
    } else {
      switch (parseInt(clientID)) {
        case 10:
          return "KV";
        case 11:
          return "DC";
        case 29:
          return "AP";
        case 54:
          return "DV";
        case 69:
          return "VM";
        case 61:
          return "VIS";
        default:
          return "?";
      }
    }
  }

  addOrderlist = (e) => {
    e.preventDefault();
    if (this.state.listName > "") {
      fetch(
        settings.server_url +
          "set_add_orderlist/" +
          window.sessionStorage.getItem("sid") +
          "/" +
          this.state.division +
          "/" +
          this.state.listName
      )
        .then()
        .then((response) => {
          this.props.loadOrderlists();
          this.setState({ listName: "" });
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  listNameHandler = (e) => {
    this.setState({ listName: e.target.value });
  };

  divisionHandler = (e) => {
    this.setState({ division: e.target.value });
  };

  toggleHidden = () =>
    this.setState((prevState) => ({ isHidden: !prevState.isHidden }));

  render() {
    let formelement;

    if (!this.state.isHidden) {
      formelement = (
        <Form onSubmit={this.addOrderlist} className="">
          <FormGroup className="mt-3 mb-0">
            <InputGroup>
              <Input
                type="text"
                name="input"
                className="form-control"
                id="input-input"
                placeholder="Nieuwe bestellijst"
                value={this.state.listName}
                onChange={this.listNameHandler}
              />
              <InputGroupAddon addonType="append">
                <Input
                  type="select"
                  name="select"
                  className="custom-select input-group-append input-group-prepend rounded-0"
                  value={this.state.division}
                  onChange={this.divisionHandler}
                >
                  <option value="10">KV</option>
                  <option value="11">DC</option>
                  <option value="29">AP</option>
                  <option value="54">DV</option>
                  <option value="69">VM</option>
                  <option value="61">VIS</option>
                </Input>
                <Button color="success">Opslaan</Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Form>
      );
    } else {
      formelement = null;
    }

    return (
      <div>
        <div
          className={
            this.props.showOrderlist ? "sidebar-right active" : "sidebar-right"
          }
        >
          <div className="cartheader d-flex">
            <div className="align-items-center d-flex p-3 text-left w-100">
              <h6 className="mb-0 font-weight-bold text-uppercase">
                Bestellijsten
              </h6>
            </div>
            <div
              className="align-items-center d-flex py-3 px-4 border-left border-gray fakelink bg-white"
              onClick={this.props.toggleOrderlists}
            >
              <i className="far fa-chevron-right" />
            </div>
          </div>
          <Scrollbars
            renderView={this.renderView}
            renderThumbHorizontal={this.renderThumb}
            renderThumbVertical={this.renderThumb}
            onUpdate={this.handleUpdate}
            className="row m-0 sidelist"
          >
            <div className="p-3">
              <span
                onClick={this.toggleHidden}
                className="w-100 btn btn-primary"
              >
                <strong>
                  {this.state.isHidden ? "Nieuwe bestellijst" : "Sluiten"}
                </strong>
              </span>
              {formelement}
            </div>
            <div className="d-flex flex-column">
              {this.props.orderlists.map((list) => {
                return (
                  <Link
                    key={list.bestellijstgroup_id}
                    className="align-items-center border-bottom d-flex px-3 py-2 text-left"
                    to={
                      "/bestellijsten/" +
                      list.customer_vestiging_id +
                      "/" +
                      list.bestellijstgroup_id +
                      "/" +
                      this.makeURLFriendly(list.bestellijstgroup_name)
                    }
                  >
                    <span
                      className={this.getBatchStyle(
                        list.customer_vestiging_id,
                        list.bestellijstgroup_supplier_id
                      )}
                    >
                      {this.getBatchTitle(
                        list.customer_vestiging_id,
                        list.bestellijstgroup_supplier_id
                      )}
                    </span>

                    {list.bestellijstgroup_name}
                  </Link>
                );
              })}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default Orderlists;
