import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
//import { Link } from "react-router-dom";
import settings from "./settings.js";
import PartnerRow from "./components/partnerrow";

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, data: [], filterOn: "" };

    //this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  componentDidMount() {
    this.props.closeMenu();
    this.props.addBreadcrumb([
      { name: "Partners", url: "/partners", reset: true },
    ]);
    this.props.searchHandler2("");
    this.props.setSearchClient(0);

    this.loadData();
  }

  loadData() {
    this.props.loading(false);

    fetch(
      settings.server_url +
        "get_partners/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  UNSAFE_componentWillMount() {
    this.props.authenticate("/");
  }

  groupByArray = (xs, key) => {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  };

  handleClientChange = (e) => {
    this.setState({ filterOn: e.target.value });
  };

  renderView({ style, ...props }) {
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    const grouped = this.groupByArray(this.state.data, "vestiging_name");

    if (!this.props.isAuthenticated) return null;
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        /*onUpdate={this.handleUpdate}*/
        className="row ml-0 text-left content-bc"
      >
        <div className="col p-md-4 px-0 maxw1200">
          <div className="row p-2 mb-3">
            <div className="col ">
              <select
                className="form-control w-auto"
                onChange={this.handleClientChange}
              >
                <option value="">Alle afdelingen</option>
                {grouped.map((group) => {
                  return (
                    <option key={group.key} value={group.key}>
                      {group.key + " (" + group.values.length + ")"}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row p-2 mb-3">
            <div className="col p-0">
              <div className="row mb-3 ml-0 mr-0">
                {this.state.data.map((partner, index) => {
                  if (
                    this.state.filterOn === "" ||
                    this.state.filterOn === partner.vestiging_name
                  ) {
                    return <PartnerRow partner={partner} key={index} />;
                  } else return "";
                })}
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default Partners;
