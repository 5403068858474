import React, { Component, Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars";

/*import _ from "lodash";*/

import Product from "./components/product.js";
import settings from "./settings.js";
import Checkoutheader from "./components/checkoutheader";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showConfirmation: false,
    };
    this.props.loading(true);
    this.props.authenticate("/");
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }
  componentDidMount() {
    this.props.closeMenu();
    this.props.searchHandler2("");
    this.props.setSearchClient(0);
    this.props.addBreadcrumb([
      { name: "Uw bestelling", url: "/checkout", reset: true },
    ]);
    this.loadData();
    // this.props.cartHandler("CLEAR");
  }

  loadData(reset) {
    //    if (this.props.location.pathname !== "/producten") {
    this.props.loading(true);
    if (reset) {
      this.setState({ data: [], hasMoreItems: true, page: 0 });
    }

    if (!this.state.pageLoading) {
      this.setState({ pageLoading: true });
      fetch(
        settings.server_url +
          "get_checkout_cart/" +
          window.sessionStorage.getItem("sid")
      )
        .then((res) => res.json())
        .then((response) => {
          this.setState({ data: response, pageLoading: false });
          this.props.loading(false);
        })
        .catch((error) => console.error("Error:", error));
    }
  }

  renderView({ style, ...props }) {
    //const { top } = this.state;
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }
  /*
  changeDeliveryDateHandler = (e, clientID, index) => {
    fetch(
      settings.server_url +
        "set_order_delivery_date/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        clientID +
        "/" +
        index +
        "/" +
        e.target.value
    )
      .then(res => res.text())
      .then(response => {
        //this.setState({ data: response, pageLoading: false });
        //this.props.loading(false);
      })
      .catch(error => console.error("Error:", error));
  };
*/

  handleCommentItem = (product, q, w, comment) => {
    this.props.loading(true);

    this.props.cartHandler("UPDATE", product, q, w, comment);
    setTimeout(() => {
      this.loadData(true);
    }, 500);
  };

  handleRemoveItem = (e, clientID, product) => {
    e.preventDefault();

    this.props.loading(true);
    this.props.cartHandler("REMOVE", product);

    setTimeout(() => {
      this.loadData(false);
    }, 500);
  };

  handleSubmit = () => {
    this.sendButton.focus();
    if (
      window.confirm("Weet u zeker dat u de bestelling(en) wilt verzenden?")
    ) {
      this.props.loading(true);
      fetch(
        settings.server_url +
          "set_send_order/" +
          window.sessionStorage.getItem("sid")
      )
        .then((res) => res.json())
        .then((response) => {
          this.props.cartHandler("CLEAR");
          this.setState({ data: [], showConfirmation: true });
          this.props.loading(false);
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  findCartIndexByID(id) {
    if (this.props.cart !== null) {
      for (let i = 0; i < this.props.cart.length; i++) {
        if (
          this.props.cart[i] !== null &&
          parseInt(this.props.cart[i].product_id) === parseInt(id)
        ) {
          return i;
        }
      }
    }

    return false;
  }

  findCartIndexByListID(id) {
    if (this.props.cart !== null) {
      for (let i = 0; i < this.props.cart.length; i++) {
        if (
          this.props.cart[i] !== null &&
          parseInt(this.props.cart[i].bestellijst_id) === parseInt(id)
        ) {
          return i;
        }
      }
    }

    return false;
  }

  priceMarkup(x) {
    let parts = x.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  render() {
    let total = 0;
    let clientcarts = this.state.data;
    if (clientcarts === null) clientcarts = [];
    if (!this.state.showConfirmation) {
      return (
        <Fragment>
          <Scrollbars
            renderView={this.renderView}
            renderThumbVertical={this.renderThumb}
            renderTrackHorizontal={(props) => (
              <div {...props} className="track-horizontal" />
            )}
            className="row ml-0 content-checkout">
            <div className="col py-md-4 px-0 px-md-3 maxw1200">
              <div className="col-12 col">
                <form onSubmit={this.handleSubmit}>
                  {clientcarts.map((cart, index) => {
                    return cart.map((c, ix2) => {
                      let subtotal = 0;
                      return (
                        <div key={ix2} className="content-block">
                          <Checkoutheader
                            reload={(reset) => {
                              this.loadData(reset);
                            }}
                            c={c}
                            ix2={ix2}
                            {...this.props}
                          />
                          {c.items.map((product, nr) => {
                            let cartix = this.findCartIndexByID(
                              product.product_id,
                              this.props.cart
                            );
                            if (
                              product.bestellijst_id &&
                              parseInt(product.bestellijst_id) > 0
                            ) {
                              cartix = this.findCartIndexByListID(
                                product.bestellijst_id
                              );
                            }
                            const quantity = product.quantity;

                            const weight =
                              cartix !== false &&
                              this.props.cart[cartix].weight !== 0
                                ? this.props.cart[cartix].weight
                                : 1;

                            subtotal += quantity * weight * product.calcprice;
                            total += quantity * weight * product.calcprice;
                            return (
                              <Product
                                isCart={true}
                                key={product.product_id + "_" + nr}
                                history={this.props.history}
                                product={product}
                                scrollPos={window.scrollTop}
                                cart={this.props.cart}
                                cartHandler={this.props.cartHandler}
                                showListButton={false}
                                deleteHandler={(e) =>
                                  this.handleRemoveItem(
                                    e,
                                    c.items[0].client_id_i,
                                    product
                                  )
                                }
                                checkoutCommentHandler={this.handleCommentItem}
                              />
                            );
                          })}
                          <div className="d-flex justify-content-end px-2">
                            <strong className="my-3">
                              Subtotaal:{" \u20AC "}
                              {this.priceMarkup(subtotal)}
                            </strong>
                          </div>
                        </div>
                      );
                    });
                  })}
                  <div className="d-flex justify-content-end px-2 content-block">
                    <strong className="my-3">
                      Totaal:{" \u20AC "}
                      {this.priceMarkup(total)}
                    </strong>
                  </div>
                </form>
              </div>
            </div>
          </Scrollbars>
          <div className="row checkout-footer">
            <div className="col-12 col text-right">
              <button
                ref={(sendButton) => {
                  this.sendButton = sendButton;
                }}
                disabled={this.props.isLoading}
                className="btn btn-success m-3"
                onClick={this.handleSubmit}>
                Bestelling verzenden
              </button>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <div className="row m-4">
          <div className="col content-block text-left p-lg-3 maxw1200">
            <h3 className="mb-4">Uw bestelling is verstuurd.</h3>
            <p>
              We hebben uw bestelling in goede orde ontvangen. Mocht u nu nog
              wijzigingen hebben of aanvullingen, neem dan contact op met ons.
              <br />
              <br />
            </p>
          </div>
        </div>
      );
    }
  }
}

export default Checkout;
