import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";

import Notifications from "./components/notifications.js";
import DelayNotifications from "./components/delaynotifications.js";
import Product from "./components/product.js";
import { Link } from "react-router-dom";
import settings from "./settings.js";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { ads: [], data: [], newproducts: [], loading: false, top: 0 };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.authenticate("/");
  }

  componentDidMount() {
    if (this.props.isSalesRep) {
      this.props.history.push("/hotels");
    } else {
      this.props.searchHandler2("");
      this.props.setSearchClient(0);
      this.loadAds();
      this.loadPromotions();
      this.loadNewProducts();
      this.props.closeMenu();
      this.props.addBreadcrumb([{ name: "", url: "", reset: true }]);
    }
  }

  componentDidUpdate() {
    if (this.props.isSalesRep) {
      this.props.history.push("/hotels");
      return null;
    }
  }

  loadAds() {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url + "get_ads/" + window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ ads: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }
  loadPromotions() {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url +
        "get_promotions/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }
  loadNewProducts() {
    this.setState({ isLoading: true });
    fetch(
      settings.server_url +
        "get_newproducts/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ newproducts: response, isLoading: false });
      })
      .catch((error) => console.error("Error:", error));
  }

  renderView({ style, ...props }) {
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  handleUpdate(values) {
    // this.setState({ top });
  }

  clickAd(e, ad) {
    e.preventDefault();
    fetch(
      settings.server_url +
        "set_ad_click/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        ad.padv_id
    )
      .then((res) => res.json())
      .then((response) => {
        window.location = ad.padv_url;
      })
      .catch((error) => console.error("Error:", error));
  }

  render() {
    if (!this.props.isAuthenticated) return null;

    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        onUpdate={this.handleUpdate}
        className="row ml-0 content"
      >
        {!this.props.isSalesRep ? (
          <div className="col p-md-4 px-0 maxw1200">
            <Notifications />
            <DelayNotifications />
            <hr className="d-md-none" />
            <div className="row p-2 mb-3">
              {this.state.ads.map((ad, index) => {
                return (
                  <div key={index} className="col-md-6 mb-3 mb-md-0">
                    <div className="content-block h-100">
                      <a href={"/#"} onClick={(e) => this.clickAd(e, ad)}>
                        <img
                          src={"https://kv.valkweb.nl" + ad.padv_image}
                          alt=""
                          width="100%"
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr className="d-md-none" />
            <div className="row p-2 mb-3">
              <div className="col-md-6 mb-3 mb-md-0">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mx-2 mb-0">Aanbiedingen</h2>
                  <Link to={"/aanbiedingen"} className="linkshome">
                    Alle aanbiedingen
                  </Link>
                </div>
                <div className="content-block">
                  {this.state.data.map((product) => {
                    return (
                      <Product
                        key={product.product_id}
                        product={product}
                        cart={this.props.cart}
                        cartHandler={this.props.cartHandler}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="col-md-6 mb-3 mb-md-0">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mx-2 mb-0">Nieuwe producten</h2>
                  <Link to={"/nieuwe-producten"} className="linkshome">
                    Alle nieuwe producten
                  </Link>
                </div>
                <div className="content-block">
                  {this.state.newproducts.map((product) => {
                    return (
                      <Product
                        key={product.product_id}
                        product={product}
                        cart={this.props.cart}
                        cartHandler={this.props.cartHandler}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col p-md-4 px-0 maxw1200"></div>
        )}
      </Scrollbars>
    );
  }
}

export default Home;
