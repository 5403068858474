import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import settings from "./settings.js";
import Product from "./components/product.js";
import { Input } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";

const WAIT_INTERVAL = 300;

class HotelProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
      groups: [],
      productData: [],
      hasMoreItems: true,
      page: 0,
      pageLoading: false,
      dropdownOpen: false,
      searchInputValue: "",
      hasProducts: false,
      groupID: 0,
    };
    this.props.loading(true);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {
    this.props.setActiveSalesRepCustomer(this.props.match.params.id);

    if (
      this.props.activeSalesRepCustomerID !==
      parseInt(this.props.match.params.id)
    ) {
      this.props.cartHandler("CLEAR");
    }

    this.loadData();
    this.loadProductGroups();
    this.loadProducts(true);
    this.props.closeMenu();
  }

  loadData() {
    this.props.loading(true);
    fetch(
      settings.server_url +
        "get_customer_details/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });

        this.props.addBreadcrumb([
          { name: "Hotels", url: "/hotels", reset: true },
          {
            name: response[0].customer_company_name,
            url: "/hotels/producten/" + response[0].customer_id,
            reset: false,
          },
        ]);
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  loadProductGroups() {
    fetch(
      settings.server_url +
        "get_salesrep_productgroups/" +
        window.sessionStorage.getItem("sid")
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ groups: response });
        //this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  loadProducts(reset) {
    let page = 0;
    if (reset) {
      this.setState({ productData: [], hasMoreItems: true, page: 0 });
    } else {
      page = this.state.page;
    }

    if (!this.state.pageLoading) {
      //console.log(this.state, this.props);
      this.setState({ pageLoading: true });
      fetch(
        settings.server_url +
          "get_products/" +
          window.sessionStorage.getItem("sid"),
        {
          method: "POST",
          body: JSON.stringify({
            search: this.state.searchInputValue,
            groupID: this.state.groupID,
            page: page,
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          let products = [...this.state.productData, ...response];

          if (!this.state.hasProducts && response.length > 0) {
            this.setState({ hasProducts: true });
          }
          this.setState({
            productData: products,
            page: page + 1,
            pageLoading: false,
          });
          this.props.loading(false);
          if (response.length === 0) {
            this.setState({ hasMoreItems: false });
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
    this.props.authenticate("/");
  }

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  changeValue(key) {
    this.setState({ groupID: key }, function () {
      this.loadProducts(true);
    });
  }

  onSearchChange = (e) => {
    e.preventDefault();

    clearTimeout(this.timer);

    let val = e.target.value;

    this.setState({ searchInputValue: val });

    this.timer = setTimeout(() => {
      this.triggerChange(val);
    }, WAIT_INTERVAL);
  };

  triggerChange = (val) => {
    this.loadProducts(true);
    //console.log(val);
    //this.props.searchHandler(val);
  };

  renderView({ style, ...props }) {
    //const { top } = this.state;
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    if (!this.props.isAuthenticated) return null;

    let results;
    if (this.state.productData.length > 0) {
      results = this.state.productData.map((product) => {
        return (
          <Product
            key={product.product_id}
            history={this.props.history}
            product={product}
            cart={this.props.cart}
            cartHandler={this.props.cartHandler}
            scrollPos={window.scrollTop}
            showListButton={true}
          />
        );
      });
    } else if (this.state.productData.length === 0 && !this.state.pageLoading) {
      results = (
        <div className="p-3">
          <strong>Er zijn geen resultaten gevonden</strong>
        </div>
      );
    } else if (this.state.productData.length === 0 && this.state.pageLoading) {
      results = (
        <div className="p-3">
          <strong>Bezig met laden...</strong>
        </div>
      );
    }

    if (this.state.data.length > 0) {
      const customer = this.state.data[0];
      return (
        <Scrollbars
          renderView={this.renderView}
          renderThumbVertical={this.renderThumb}
          renderTrackHorizontal={(props) => (
            <div {...props} className="track-horizontal" />
          )}
          /*onUpdate={this.handleUpdate}*/ className="row ml-0 content-bc"
        >
          <InfiniteScroll
            pageStart={0}
            threshold={65}
            initialLoad={true}
            loadMore={() => {
              this.loadProducts(false);
            }}
            hasMore={this.state.hasMoreItems}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
            useWindow={false}
            className="col p-md-4 px-0 maxw1200"
          >
            <div className="p-lg-2 row">
              <div className="col content-block ml-3 mr-3 p-lg-3">
                <div className="overflow-hidden p-2">
                  <span className="mt-0 d-block text-left">
                    <h3>{customer.customer_company_name}</h3>
                  </span>
                </div>
              </div>
            </div>
            <div className="p-lg-2 row">
              <div className="col">
                <div className="d-flex text-left flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                  {this.state.hasProducts ? (
                    <React.Fragment>
                      <Input
                        placeholder="Zoek in producten"
                        value={this.state.searchInputValue}
                        onChange={this.onSearchChange}
                        className="mb-2 mb-md-0 col-12 col-md-8"
                      />
                      <Input
                        type="select"
                        onChange={(e) => this.changeValue(e.target.value)}
                        className="form-control ml-lg-3 col-12 col-md"
                      >
                        <option key={0} value={0}>
                          {"Alle categorieën"}
                        </option>
                        {this.state.groups.map((g) => {
                          return (
                            <option key={g.id} value={g.id}>
                              {g.site_label}
                            </option>
                          );
                        })}
                      </Input>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>

                {results.length > 0 ? (
                  <div className="content-block">{results}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </InfiniteScroll>
        </Scrollbars>
      );
    } else {
      return "";
    }
  }
}

export default HotelProducts;
