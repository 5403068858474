import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import settings from "./settings.js";
//import Product from "./components/product.js";
//import { Input } from "reactstrap";
//import InfiniteScroll from "react-infinite-scroller";

//const imgstyle = {
//maxWidth: "200px",
//maxHeight: "140px",
//  width: "200px",
//};

//const WAIT_INTERVAL = 300;

class SupplierDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
      promos: [],
      groups: [],
      news: [],
      productData: [],
      hasMoreItems: true,
      page: 0,
      pageLoading: false,
      dropdownOpen: false,
      searchInputValue: "",
      hasProducts: false,
      groupID: 0,
      tab: 0,
    };
    this.props.loading(true);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.props.closeMenu();
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
    this.props.authenticate("/");
  }

  loadData() {
    this.props.loading(false);
    fetch(
      settings.server_url +
        "get_supplier_details/" +
        window.sessionStorage.getItem("sid") +
        "/" +
        this.props.match.params.id
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response });

        this.props.addBreadcrumb([
          { name: "Partners", url: "/partners", reset: true },
          {
            name: response.name,
            url: "/suppliers/details/" + response.id,
            reset: false,
          },
        ]);
        this.props.loading(false);
      })
      .catch((error) => console.error("Error:", error));
  }

  renderView({ style, ...props }) {
    //const { top } = this.state;
    const viewStyle = {};
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  renderThumb({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = { borderRadius: "50px", backgroundColor: "#ff6d0c" };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  createMarkup(txt) {
    return { __html: txt };
  }

  render() {
    if (!this.props.isAuthenticated) return null;

    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbVertical={this.renderThumb}
        renderTrackHorizontal={(props) => (
          <div {...props} className="track-horizontal" />
        )}
        className="row ml-0 content-bc"
      >
        <div className="col p-md-4 px-0 maxw1200">
          <div className="p-lg-2 row">
            <div className="col content-block ml-4 mr-3 p-lg-3">
              <div className="row">
                <div className="col-lg-3 col-sm-12">
                  <img
                    src={"http://app.ecbase.nl" + this.state.data.main_image}
                    alt=""
                    className="img-fluid m-auto d-block"
                  />

                  <span
                    className="mt-3 d-block text-left"
                    dangerouslySetInnerHTML={{
                      __html: this.state.data.side_content,
                    }}
                  ></span>
                </div>
                <div className="col-lg-9 col-md-12 text-left">
                  <h3>{this.state.data.name}</h3>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.data.main_content,
                    }}
                  />
                </div>
              </div>
              <div className="overflow-hidden p-2"></div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default SupplierDetails;
